<script>
import {reactive, toRefs} from 'vue'
import {base64ToFile, imageUrl} from './td.js'

export default {
  name: 'TestDownload',
  setup () {
    const state = reactive({
      base64: imageUrl,
      imgFIle: null
    })
    const download = async () => {
      const fileName = 'image.png'
      const file = await base64ToFile(state.base64, fileName, 'image/png')
      state.imgFIle = await URL.createObjectURL(file)
      console.log('图片file===》', state.imgFIle)
    }
    return {
      ...toRefs(state),
      download
    }
  }
}
</script>

<template>
<div>
  <img :src="base64">
  <a-button @click="download">下载</a-button>
  <img :src="imgFIle">
</div>
</template>

<style scoped>

</style>
