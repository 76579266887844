<template>
  <div class="underwrit">
    <IOSbackBar :title="'智能核保'" ></IOSbackBar>
    <div class="example" v-if="loadingShow">
      <a-spin size="large"/>
    </div>
    <div class="flex_class" v-if="!questionList.length">
      <a-empty description="暂无问题数据, 可以直接进行核保提交"/>
    </div>
    <div class="content">
      <div class="content_details" v-for="(item, index) in questionList" :key="item.id">
        <div class="header" v-if="item.questionValue">
          你选择的疾病是:
        </div>
        <p v-if="item.questionValue">{{ item.questionValue }}</p>
        <div class="content_footer">
          <div>
            <p>{{ item.questionContent }}</p>
            <div class="content_but">
              <a-radio-group :disabled="item.selectRadio !== undefined" v-model:value="item.selectRadio" shape="round"
                             button-style="solid">
                <a-radio-button :class="{active_but: item.selectRadio === ans.id, disBlock: item.answerList.length > 2}"
                                v-for="(ans, childIndex) in item.answerList" :key="ans.id"
                                @click="onClickQuestion(ans, item, index, childIndex)" :value="ans.id" size="small">
                  {{ ans.answerContent }}
                </a-radio-button>
              </a-radio-group>
            </div>
          </div>

        </div>
      </div>
      <footer class="footer" v-if="isShowFooter">
        <a-button @click="onSubmit">提交核保问答</a-button>
      </footer>
    </div>

    <!--    提交结果弹窗-->
    <div class="underwrit_drawer">
      <a-drawer
          :visible="typeShow"
          placement="bottom"
          height="100%"
          @close="onCloseDrawer"
          destroyOnClose
          :closable="false"
      >
        <div class="drawer">
          <div class="drawer_top">
            <div>
              <ExclamationCircleOutlined v-if="showType === '0'" style="color: #ff914b; font-size: 50px"/>
              <CheckCircleOutlined v-else style="color: #1890ff; font-size: 50px"/>
            </div>
            <div class="drawer_content">
              <p v-if="showType === '0'">无法投保该产品</p>
              <p class="c1" v-if="showType === '0'">非常遗憾, 根据被保险人的健康状况暂时无法投保该产品,
                您可返回首页尝试选择其他保险进行投保</p>
              <p v-if="showType === '1'">智能核保通过</p>
            </div>
          </div>
          <div class="drawer_but">
            <a-button type="primary" v-if="showType === '0'" @click="handelAbandon">返回首页</a-button>
            <a-button type="primary" v-if="showType === '1'" @click="handelSave">接受, 立即投保</a-button>
            <a-button v-if="showType === '1'" @click="handelAbandon">放弃投保</a-button>
            <a-button type="primary" ghost @click="handelReinsurance">重新核保</a-button>
          </div>
        </div>
      </a-drawer>
    </div>

    <!--    外接承保-->
    <a-modal style="width: 84%;" centered :closable="false" v-model:visible="isIdentical">
      <div class="modal_title">温馨提示</div>
      <p style="font-size: 1.01rem;color: #222;text-align: left;margin: 0.45rem 0.25rem;">{{ detailModal }}</p>
      <template #footer>
        <a-button type="link"
                  style="width: 40%;font-size: 20px;text-align: center !important; color: #333;"
                  @click="onClose">取消
        </a-button>
        <a-button type="link"
                  style="width: 50%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="handelSubmit">确认
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent, onBeforeMount, reactive, toRefs, onUnmounted, watch, ref} from 'vue'
import {CheckCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons-vue'
import {request} from '@/utils/request'
import router from '@/router'
import store from '@/store'
import {getExtendUri} from '@/utils/request_extend'
import {routerJump} from '@/utils/product_process'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'

export default defineComponent({
  components: {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    IOSbackBar
  },
  setup() {
    const state = reactive({
      loadingShow: false,
      isShowFooter: false,
      typeShow: false,
      isIdentical: false,
      detailModal: '',
      tradeNo: router.currentRoute.value.query['tradeNo'],
      supperCode: JSON.parse(store.getters['insured/getProductDetailData']).productInfo.supplierCode,
      selectList: [],
      index: 0,
      questionList: [],
      questionValue: '',
      value: ''
    })
    let showType = ref()

    onBeforeMount(() => {
      state.index = 0
      state.questionList = []
      document.title = '智能核保'
      getCacheParameters()

    })
    // 这个是保存数据
    const handelSaveLogs = () => {
      let data = {
        tradeNo: state.tradeNo,
        supperCode: state.supperCode,
        associationType: router.currentRoute.value?.query?.associationType,
        idNo: '',
        answerData: []
      }
      if (!state.questionList.length) {
        state.selectList.forEach(item => {
          data.answerData.push({
            diseaseCode: item.diseaseCode,
            answerId: ''
          })
        })
      } else {
        state.questionList.forEach(quest => {
          data.answerData.push({
            diseaseCode: quest.diseaseCode,
            answerId: quest.selectRadio
          })
        })
      }
      request(getExtendUri('saveLogs'), data)
    }
    const getCacheParameters = () => {
      request(getExtendUri('getCacheParameters'), {tradeNo: state.tradeNo + 'underwritNotification'}).then(res => {
        console.log('缓存数据', res)
        if (res.code === 1 && res.result) {
          state.selectList = res.result.saveData.select
          if (router.currentRoute.value.query.isShowType === '99') {
            getQuestion()
          }
        }
      })
    }
    // 获取疾病
    const getQuestion = (answer, index = null, childIndex) => {
      // 判断
      if (state.index >= state.selectList.length) {
        state.isShowFooter = true
        return
      }
      let data = {
        tradeNo: state.tradeNo,
        supperCode: state.supperCode,
        diseaseCode: state.selectList[state.index].diseaseCode,
        answerId: answer?.id,
        associationType: router.currentRoute.value?.query?.associationType,
        idNo: ''
      }
      request(getExtendUri('question'), data).then(res => {
        if (res.code === 1) {
          processingResults(res, answer, index, childIndex)
        }
      })
    }
    // 处理接口返回结果
    const processingResults = (res, answer, index = null, childIndex) => {
      // 0:拒保，1:正常承保；2:除外承保
      showType.value = res.result.conclusion
      if (res.result.conclusion === '0') {
        state.isShowFooter = true
        state.detailModal = res.result.conclusion === '2' ? answer.exclusionsContent : ''
      } else if (res.result.conclusion === '1') {
        state.index++
        getQuestion()
      } else if (res.result.conclusion === '2') {
        // 直接展示弹窗, 确定的时候判断是否提交
        state.isIdentical = true
        if (answer && answer.exclusionsContent) {
          state.detailModal = answer.exclusionsContent
        } else {
          state.detailModal = state.selectList[state.index].exclusionsContent
        }
      } else {
        showType.value = ''
        res.result.question.selectRadio = undefined
        if (res.result.question.parentCode === '0') {
          res.result.question.questionValue = state.selectList[state.index].diseaseContent
        }
        if (answer && index + 1 < state.questionList.length) {
          state.questionList.splice(index + 1)
          state.questionList.forEach(item => {
            state.isShowFooter = item.selectRadio && item.answerList[childIndex]['conclusion'] !== ''
          })
        }
        state.questionList.push(res.result.question)
      }
    }
    // 选择时
    const onClickQuestion = (data, item, index, childIndex) => {
      if (data.conclusion !== '' && data.relatedQuestionCode === '' && state.questionList.length > 1) {
        state.questionList.splice(index + 1, state.questionList.length - index)
      }
      getQuestion(data, index, childIndex)
    }
    // 提交
    const onSubmit = () => {
      if (showType.value === '0' || showType.value === '1') {
        state.typeShow = true
      } else if (showType.value === '2') {
        state.isIdentical = true
      }
    }

    // 接受立即投保
    const handelSave = () => {
      onCloseDrawer()
      handelSaveLogs()
      routerJump(state.tradeNo, 'health')
    }
    // 返回首页/ 重新核保
    const handelReinsurance = () => {
      onCloseDrawer()
      handelSaveLogs()
      router.push({
        path: '/app/healthTold',
        query: router.currentRoute.value.query
      })
    }

    // 放弃核保
    const handelAbandon = () => {
      onCloseDrawer()
      handelSaveLogs()
      router.push({
        path: '/app/detail',
        query: {
          shareId: router.currentRoute.value.query.shareId,
          typeCode: router.currentRoute.value.query && router.currentRoute.value.query.typeCode ? router.currentRoute.value.query.typeCode : ''
        }
      })
    }
    // 除外承保的2
    const handelSubmit = () => {
      state.isIdentical = false
      if (state.index >= state.selectList.length - 1 || state.selectList.length === 1) {
        showType.value = '1'
        state.typeShow = true
      } else {
        state.index++
        getQuestion()
      }
    }
    const onClose = () => {
      state.isIdentical = false
      router.go(-1)
    }

    const onCloseDrawer = () => {
      state.typeShow = false
    }
    // 添加历史记录
    watch(() => router.currentRoute.value.query.isShowType, () => {
      if (router.currentRoute.value.query.isShowType !== '2') {
        state.typeShow = router.currentRoute.value.query.isShowType !== '99'
        showType.value = router.currentRoute.value.query.isShowType
      } else {
        request(getExtendUri('getCacheParameters'), {tradeNo: state.tradeNo + 'underwritNotification'}).then(res => {
          if (res.code === 1 && res.result) {
            state.detailModal = res.result.saveData.select[0].exclusionsContent
            state.isIdentical = true
          }
        })
      }
    }, {immediate: true})
    // 添加历史记录
    watch(() => state.typeShow, () => {
      if (state.typeShow) {
        const url = new URL(window.location)
        window.history.pushState({}, '', url)
      }
    })
    // 添加监听事件
    window.addEventListener('popstate', onCloseDrawer, false)
    onUnmounted(() => {
      const url = new URL(window.location)
      url.searchParams.delete('isShowType')
      window.removeEventListener('popstate', onCloseDrawer, false)
    })

    return {
      showType,
      ...toRefs(state),
      onClickQuestion,
      onSubmit,
      handelSubmit,
      onCloseDrawer,
      getQuestion,
      getCacheParameters,
      handelReinsurance,
      handelSave,
      handelAbandon,
      onClose,
      handelSaveLogs
    }
  },
})
</script>
<style scoped>
.underwrit {
  min-height: 100vh;
  padding: 2px 10px 10px;
  text-align: left;
  background-color: #f3f3f3 !important;
}

.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}

.header {
  background: #fff;
  padding-top: 5px;
  color: #222;
  font-weight: bold;
}

.content {
  margin-bottom: 50px;
}

.content_details {
  margin-top: 10px;
}

.content_details p {
  background: #fff;
  padding: 8px 2px;
}

.content_footer {
  background: #fff;
  padding: 8px 2px;
}

.content_but {
  text-align: right;
  padding: 0 5px;
}

:deep(.ant-radio-group) {
  line-height: 45px;
}

:deep(.ant-radio-button-wrapper) {
  border-radius: 20px !important;
  background: #f1f1f1;
  min-width: 6rem;
  min-height: 2rem;
  text-align: center;
  line-height: 2rem;
  height: auto !important;
  border: 0;
}

:deep(.ant-radio-button-wrapper:first-child) {
  margin-right: 6px;
}

/deep/ .ant-radio-button-wrapper::before {
  display: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 0 !important;
  background: #7ab9ff !important;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  background: #f1f1f1;
}

.footer /deep/ .ant-btn {
  width: 96%;
  height: 50px;
  color: #fff;
  background: #169bd5;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
}

.drawer {
  text-align: center;
  height: 100%;
  background-color: #f3f3f3;
  margin: 0 -24px -24px;
}

.drawer_top {
  min-height: 30%;
  background: #fff;
  padding: 24px;
  margin-bottom: 15px;
}

.drawer_content {
  font-size: 22px;
  padding: 20px 0;
}

.drawer_content .c1 {
  font-size: 18px;
  color: #bcb9b9;
  text-align: left;
  margin-top: 10px;
}

.drawer_but :deep(.ant-btn) {
  width: 92%;
  height: 56px;
  font-size: 18px;
  border-radius: 8px;
  margin: 10px 0;
}

.modal_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #222;
}

.active_but {
  background: #1677ff;
  color: #fff;
}

.disBlock {
  display: block;
  margin-bottom: 10px;
  margin-right: 6px;
}

.flex_class {
  margin: 200px auto 0;
  color: #cecece;
}
</style>
