import {shallowRef} from 'vue'

const getVuePathComponent = (path) => {
  let uri = path
  if (path.startsWith('/')) {
    uri = path.substring(1)
  }
  if (uri.indexOf('.vue') < 0) {
    uri += '.vue'
  }
  return () => import('../../pages/' + uri).catch(() => {
    return import('../../pages/404.vue')
  })
}

const user = {
  namespaced: true,
  state: () => ({
    userId: '',
    userName: '',
    loginId: '',
    loginName: '',
    corpId: '',
    corpName: '',
    corpList: [],
    isInitPassword: '',
    beeId: '',
    isLogin: false,
    roleTree: [],
    roleMap: {},
    rolePathMap: {},
    roleComponents: {},
    actionMap: {}
  }),
  mutations: {
    init(state, payload) {
      if (payload) {
        console.log(payload)
        state.userId = payload.userId
        state.userName = payload.loginName
        state.loginId = payload.userId
        state.loginName = payload.loginName
        state.corpId = payload.corpId
        state.corpName  = payload.corpName
        state.corpList = payload.corpList
        state.isInitPassword = payload.isInitPassword
        state.beeId = payload.beeId
        state.isLogin = true
        state.roleTree = payload.roleTree
        state.roleMap= {}
        state.rolePathMap= {}
        state.roleComponents= {}
        state.actionMap= {}
        roleTreeToMap(state.roleMap, state.rolePathMap, state.roleComponents, state.actionMap, JSON.parse(JSON.stringify(payload.roleTree)))
      } else if (state.roleTree.length > 0) {
        roleTreeToMap(state.roleMap, state.rolePathMap, state.roleComponents, state.actionMap, JSON.parse(JSON.stringify(state.roleTree)))
      }

      function roleTreeToMap(map, pathMap, components, actions, roleTree) {
        if (roleTree && roleTree.length > 0) {
          roleTree.forEach((node) => {
            map[node.id] = node
            if (typeof node.uri === 'string' && node.uri.length > 0) {
              components['frame_' + node.id] = shallowRef(getVuePathComponent(node.uri))
              pathMap[node.uri] = node
            }
            if (Array.isArray(node.actions)) {
              node.actions.forEach(item => actions[item.code] = item.uri)
            }
            roleTreeToMap(map, pathMap, components, actions, node['submenus'])
          })
        }
      }
    },
    logout(state) {
      state.userId = ''
      state.userName = ''
      state.loginId = ''
      state.loginName = ''
      state.corpId = ''
      state.corpName = ''
      state.corpList = []
      state.isInitPassword = ''
      state.beeId = ''
      state.isLogin = false
      state.roleTree = []
      state.roleMap = {}
      state.rolePathMap = {}
      state.roleComponents = {}
      state.actionMap = {}
    },
    resetPasswordStatus(state) {
      state.isInitPassword = 'N'
    }
  },
  actions: {
    logout({commit}) {
      commit('logout')
    },
    resetPasswordStatus({commit}) {
      commit('resetPasswordStatus')
    }
  },
  getters: {
    getBeeId(state) {
      return state.beeId
    },
    getUserId(state) {
      return state.userId
    },
    getLoginId(state) {
      return state.loginId
    },
    getLoginName(state) {
      return state.loginName
    },
    getIsInitPassword(state) {
      return state.isInitPassword
    },
    getCorpId(state) {
      return state.corpId
    },
    getMenuTree(state) {
      return state.roleTree
    },
    getMenuMap(state) {
      return state.roleMap
    },
    getMenuPathMap(state) {
      return state.rolePathMap
    },
    isLogin(state) {
      return state.isLogin
    },
    getMenuComponents(state) {
      if (state.roleComponents['frame_home']) {
        return state.roleComponents
      }
      return {}
    },
    getActionMap: (state) => (code) => {
      if (code) {
        return state.actionMap[code]
      }
      return state.actionMap
    }
  }
}

export default user
