<template>
  <div>
<!--    <sign-part-in-confirmation></sign-part-in-confirmation>-->
    <JsonXmlEditor  class="script_editor"  ref="mEditor" :language="'json'"  :inputContent="scriptText"
                   @scriptChange="scriptChange" @clearRunStr="clearRunStr"></JsonXmlEditor>
    <div :style="{ background: 'rgb(190, 200, 200)', padding: '26px 16px 16px' }">
      <p>vue调用原生事件</p>
      <a-button type="primary" ghost @click="getRunStr">提交</a-button>
      <a-button type="primary" ghost @click="readMiniPdf">下载</a-button>
      <a-button ghost @click="readBigPdf">访问pdf（大）</a-button>
      <a-button type="dashed" ghost @click="shareEvent">分享</a-button>
      <p>响应信息： {{ info }}</p>

    </div>
    <div>
      <p>原生调用vue事件</p>
      <p>{{ name }}</p>
      <p>{{ name2 }}</p>
      <p>{{ scriptText }}</p>
      <p>{{ runStr }}</p>
    </div>
  </div>

</template>

<script>
import {reactive, toRefs, ref} from 'vue'
import Bridge from './JSbridge.js'
// import SignPartInConfirmation from '../transaction/elSign/componts/signPartInConfirmation'
import JsonXmlEditor from '../../components/extended/JsonXmlEditor'

export default {
  name: 'TestJSbridge',
  components: {JsonXmlEditor},
  setup() {

    // const {proxy} = getCurrentInstance()

    const state = reactive({
      name: '',
      name2: '',
      info: '',
      minPdfName: 'Node.js开发指南',
      miniPdf: 'https://public-1251502530.cos.ap-beijing.myqcloud.com/file/25-Node.js%E5%BC%80%E5%8F%91%E6%8C%87%E5%8D%97.pdf',
      bigPdf: 'https://public-1251502530.cos.ap-beijing.myqcloud.com/file/11-HTML5%E4%B8%8ECSS3%E5%9F%BA%E7%A1%80%E6%95%99%E7%A8%8B%EF%BC%88%E7%AC%AC8%E7%89%88%EF%BC%89.pdf',
      bigPdfName: 'HTML5与CSS3基础教程（第8版）',
      imgUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg2.niutuku.com%2Fdesk%2F1208%2F1300%2Fntk-1300-31979.jpg&refer=http%3A%2F%2Fimg2.niutuku.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1668152068&t=9e03ae670904ce499c779bbb629d89ed',
      shareObj: {}
    })
    const readMiniPdf = () => {
      // jsToAndroid('readMinPdf',{url:state.miniPdf,title:state.minPdfName})
      // let param = {url:state.miniPdf,title:state.minPdfName}
      let param = state.imgUrl
      Bridge.sendMsgToApp('JSToDownImg',param)
    }

    const readBigPdf = () => {
      // jsToAndroid('readBigPdf',{url:state.bigPdf,title:state.bigPdfName})
      let param = {url:state.bigPdf,title:state.bigPdfName}
      Bridge.sendMsgToApp('readBigPdf',param)
    }
    const shareEvent = () => {
      // let param = {
      //   url: state.miniPdf,
      //   title: state.minPdfName+'ffsa',
      //   value: '',
      //   imgUrl: ''
      // }
      let param = state.imgUrl
      Bridge.sendMsgToApp('goShardWxFriends',param)

    }
    const setName = (msg) => {
      state.name = `app主动调用js方法，传入数据:${msg}`
    }
    const showAert = (data1,data2,data3) => {
      // Android端
        let data = data1.concat(data2).concat(data3)
        state.name2 = `app主动调用js方法，传入数据:${data}`
    }

    Bridge.receiveFromApp('dataToJs',setName)
    Bridge.receiveFromApp('dataToJs2',showAert)
    // window.dataToJs = setName // 要将方法挂载在window上
    // window.dataToJs2 = showAert // 要将方法挂载在window上


    const mEditor = ref(null)

    const jsonxml = reactive({
      scriptText: '',
      runStr: ''
    })
    const scriptChange = (param) => {
      console.debug('scriptChange', param)
      jsonxml.scriptText = param.originalStr
      let a = param.originalStrArray
      let buildStr = ''
      for (let i = 0; i < a.length; i++) {
        let text = a[i]
        buildStr += text + '\n'
      }
      jsonxml.runStr = buildStr
    }

    const clearRunStr = () => {
      jsonxml.runStr = ''
    }
    const getRunStr = () => {
      mEditor.value.getRunScript()
      return {
        runStr: state.runStr,
        originalStr: state.scriptText
      }
    }
    return {
      ...toRefs(state),
      readMiniPdf,
      readBigPdf,
      shareEvent,
      setName,
      showAert,
      ...toRefs(jsonxml),
      scriptChange,
      clearRunStr,
      getRunStr,
      mEditor
    }
  }
}
</script>

<style scoped>
.script_editor {
  height: 500px;
  width: 100%;
}
</style>
