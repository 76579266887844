<template>
  <div class="attach_class">
    <van-nav-bar
      right-text="筛选"
      @click-right="onClickRight"
    >
      <template #title>
        <form action="/">
          <van-search
            v-model="selectName"
            placeholder="投保人姓名、保单号码"
            @search="onSearch"
          />
        </form>
      </template>
    </van-nav-bar>
    <div class="header">
      <van-tabs v-model:active="active" @click-tab="onClickTab">
        <van-tab v-for="item in tabList" :title="item.label" :key="item.value"></van-tab>
      </van-tabs>
    </div>
    <div class="flexBox" v-if="detailList.length">
      <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item,index) in detailList" :key="item" :title="index" >
            <div class="listContent" >
              <div class="orderHeader" @click="handelToDetail(item)">

                <span class="orderName">
                  <van-icon name="label-o" style="font-size: 16px; margin-right: 5px"/>
                  {{ item.productInfoName }}</span>
                <span class="order-status">{{ item.statusName }}</span>
              </div>
              <div class="order-details" @click="handelToDetail(item)">
                <div class="order-info">
                  <span class="order-label">订单号：</span>
                  <span class="order-value">{{ item.tradeNo }}</span>
                </div>
                <div class="order-info">
                  <span class="order-label">投保人：</span>
                  <span class="order-value">{{ item.applicantName }}</span>
                </div>
                <div class="order-info">
                  <span class="order-label">被保人：</span>
                  <span class="order-value">{{ item.protectedmanName }}</span>
                </div>
                <div class="order-info">
                  <span class="order-label">投保时间：</span>
                  <span class="order-value">{{ item.polApplyDate }}</span>
                </div>
              </div>
              <van-divider />
              <div class="order-bottom">
                <div class="order-left">
                  <span class="order-label">保费：</span>
                  <span style="color: red;font-size: 18px">￥{{item.payMoney}}</span>
                </div>
                <div class="order-actions">
                  <van-button
                    class="confirm-button cancel-button"
                    type="default"
                    size="small"
                  >
                    取消
                  </van-button>
                  <van-button
                    class=""
                    size="small"
                    confirm-buttonplain
                  >
                    继续
                  </van-button>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>

    <div v-if="!detailList.length">
      <div class="null_img">
        <img alt="" src="../../../assets/img/no_order.png" />
        <div>暂无记录</div>
      </div>
    </div>
    <div class="drawer_class" v-if="visible">
      <a-drawer
        title="保险公司"
        :width="'85%'"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        :footer-style="{ textAlign: 'right' }"
        @close="onClose"
      >
        <template #footer>
          <a-button key="back" @click="onClose">重置</a-button>
          <a-button key="submit" type="primary" @click="onClose">确定</a-button>
        </template>
      </a-drawer>
    </div>
  </div>


</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from 'vue'
import {Search, NavBar, Cell, List, PullRefresh, Divider, Icon, Button, Tab, Tabs} from 'vant'
import {request} from '@/utils/request'
import router from '@/router'
export default defineComponent({
  components:{
    [Search.name]: Search,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [List.name]: List,
    [Divider.name]: Divider,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  setup() {
    const state = reactive({
      current: 0,
      selectName: '',
      detailList: [],
      active:0,
      tabList: [{value: 1, label: '待续保'},{value: 1, label: '已续保'}]
    })
    const showState = reactive({
      loading: false,
      refreshing: false,
      finished: false,
      visible: false
    })
    // 搜索
    const onSearch = () =>{
      state.current = 0
      state.detailList = []
      getDetailList()
    }
    const onRefresh = () =>{
      onLoad()
    }

    const onLoad = () => {
      showState.loading = true
      showState.finished = false
      showState.refreshing = true
      getDetailList()
    }
    const getDetailList = () =>{
      state.current++
      let token = router.currentRoute.value.query.token ? router.currentRoute.value.query.token : ''
      // let token = '0f9f2dbd2e8b4ea495d628a850d84da3'
      let parameter = {
        pagination:{
          current: state.current,
          pageSize: 10
        },
        data:{
          selectName: state.selectName,
          statusType: 'all',
        }
      }
      request(`/product-service/product/productInsured/bdt/api/queryListForPageFront?token=${token}`, parameter, true, 'post').then(res => {
        if(res.code === 1 && res.result && res.result.length){
          if (res.result.length !== 10) {
            showState.finished = true
          }
          state.detailList = state.detailList.concat(res.result)
          showState.loading = false
          showState.refreshing = false
        }else{
          showState.loading = false
          showState.finished = true
          showState.refreshing = false
        }
      })
    }

    // tab切换
    const onClickTab = () =>{
      console.log('这个是切换')
    }
    const onClose = () =>{
      showState.visible = false
    }

    const onClickRight = () => {
      console.log(666, '筛选')
      showState.visible = true
    }
    onMounted(()=>{
      document.title = '在线续保'
      console.log(router)
    })

    return {
      ...toRefs(state),
      ...toRefs(showState),
      onClickRight,
      onSearch,
      onRefresh,
      onLoad,
      onClickTab,
      onClose,
    }
  }
})
</script>

<style scoped>
:deep(.van-tabs__line){
  background-color: #1677ff !important;
  height: 2px !important;
  width: 60px !important;
}
:deep(.van-tab--active) {
  color: #1677ff !important;
}
:deep(.van-nav-bar__title){
  width: 87% !important;
  max-width: 90% !important;
  margin: 0 !important;
}
:deep(.van-search__content){
  border-radius: 20px !important;
}
:deep(.van-nav-bar__text){
  color: #837b7b !important;
}
.orderName {
  font-size: 3.5vw;
  line-height: 2.3rem;
  font-weight: 700;
  display: inline-block;
  max-width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.order-status {
  color: #ff3333;
  font-size: 3.5vw;
  line-height: 2.3rem;
  font-weight: 700;
  float: right;
}

.order-details {
  margin-bottom: 8px;
}

.order-info {
  display: flex;
  margin-bottom: 4px;
  font-size: .7rem;
  line-height: 1.5rem;
}

.order-label {
  color: #222;
}

.order-value {
  text-align: right;
  flex: 1;
}

.order-actions {
  display: flex;
  justify-content: flex-end;
}

.cancel-button {
  margin-right: 16px;
}
.flexBox{
  margin-top: 10px;
  background: #f5f6fa;
}

:deep(.van-nav-bar .van-icon) {
  color: #cbcbcb !important;
}
:deep(.van-grid-item__content){
  padding: 20px 8px !important;
}
.time_class span{
  margin: 2px 8px 15px;
  min-width: 95px;
  display: inline-block;
  color: #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
}

.listContent {
  margin: 8px 0 15px;
  padding: 8px 20px;
  background-color: #fff;
}
.order-bottom{
  display: flex;
  justify-content: space-between;
}
:deep(.van-tabs--card>.van-tabs__wrap){
  margin: 8px;
}
:deep(.van-tabs__nav--card){
  border-radius: 18px;
  color: #318dff;
  border: 1px solid #318dff;
  height: 25px !important;
}
:deep(.van-tab--card) {
  border-radius: 18px 0 0 18px;
  color: #318dff;
  border-right: 0;
}
:deep(.van-tab--card.active) {
  border-radius: 18px 0 0 18px;
}
:deep(.van-tab--card:last-child) {
  border-radius: 0 18px 18px 0;
}
:deep(.van-tab--card:last-child.active) {
  border-radius: 0 18px 18px 0;
}
:deep(.van-tab--card.van-tab--active) {
  background-color: #318dff;
  color: #ffffff;
}

:deep(.van-dropdown-menu__title--active) {
  color: #1989fa;
}
.confirm-button{
  border-color: #5b5b5b;
  color: #646566;
  height: 23px;
  width: 70px;
  font-size: 0.8rem;
  border-radius: 10px;
}
.orderHeader{
  text-align: left;
}
:deep(.van-divider){
  background: #cbcbcb;
}
button{
  width: 50%;
}
.drawer_class :global(.ant-drawer-footer){
  padding: 0 !important;
}
.null_img{
  padding: 8vw 0 4vw;
}
.null_img img{
  width: 5rem;
}
.null_img div{
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}
</style>
