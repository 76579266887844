<style scoped>
#nav-bar {
  display: flex;
  height: 44px;
  line-height: 44px;
  text-align: center;
  z-index: 10;
  background-color: #ebebeb;
  position: relative;
}

#imgList {
  width: 100%;
  height: 170px;
  background-color: cornflowerblue;
}


.nav-left {
  width: 40px;
  font-size: 20px;
}

.nav-center {
  width: 30px;
  font-size: 20px;
}

.nav-right {
  flex: 1;
  float: left;
  font-weight: bold;
}

#crad-style {
  width: 90%;
  text-align: left;
  margin: 0 auto;
}

.crad-style {
  width: 100%;
  float: left;
  margin-top: 10px;
}
.confirmBottom{
  width: 100%;
  height: 50px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.confirmBottom div{
  background-color: cornflowerblue;
  color: #fff;
  height: 50px;
  line-height: 50px;
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
}
</style>

<template>
  <div id="nav-bar">
    <div class="nav-left">
      <LeftOutlined/>
    </div>
    <div class="nav-center">
      <CloseOutlined/>
    </div>
    <div class="nav-right"><h2 style="font-weight: bold;font-family: Apple;margin-left: -60px">电子化回访问卷</h2></div>
  </div>
  <div id="imgList">
    <h1 style="font-weight: bold;color: #ebebeb;font-size: 36px;padding-top: 10px">您的回访问卷</h1>
    <div style="word-wrap: normal; font-weight: bold;font-size: 15px;color: #ebebeb;position: relative;top: -15px; width: 95%;margin: 0 auto;">尊敬的客户您好，感谢您购买华贵保险产品，为了维护您的权益，现根据要求对您进行回访，本次存档将通过系统记录并存档，请您认真回答以下问题</div>
    <div></div>
  </div>
  <div id="crad-style">
    <div class="crad-style" v-for="(item, index) in buttonBackground" :key="index">
      <div style="word-wrap: normal;color: #7e7e7e; font-weight: bold;">{{ item.miaoshu }}</div>
      <a-button block :style="{marginTop: item.marginTop, backgroundColor: item.yesNO === true ? item.backgroundColor : '', color: item.yesNO === true ? item.color : 'black'}" @click="backgroundChange(item, index)">是</a-button>
      <a-button block :style="{marginTop: item.marginTop, backgroundColor: item.yesNO === false ? item.backgroundColor : '', color: item.yesNO === false ? item.color : 'black'}" @click="backgroundChangeNo(item)">否</a-button>
    </div>
  </div>
  <div class="confirmBottom">
    <div @click="nextPage">提交</div>
  </div>
</template>

<script>
import {CloseOutlined, LeftOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, ref, toRefs} from 'vue'
import {useRouter} from 'vue-router'

export default defineComponent({
  components: {
    CloseOutlined,
    LeftOutlined
  },
  setup() {
    const router = useRouter()
    const userDrawerRef = ref()
    console.log(userDrawerRef)
    const state = reactive({
      name: '',
      buttonBackground: [
        {
          marginTop: '5px',
          miaoshu: '1,投保信息：姓名：要海洋，证件号码2132323，保单号：2342334，联系电话：18945194492，联系地址:辽宁省大连市高新园区光线路一人家园零号楼',
          backgroundColor: '',
          color: 'black',
          yesNO: undefined
        },
        {
          marginTop: '5px',
          miaoshu: '2,请问您是否收到了保险合同？',
          backgroundColor: '',
          color: '',
          yesNO: undefined
        },
        {
          marginTop: '5px',
          miaoshu: '3,您是否缴费？',
          backgroundColor: '',
          color: '',
          yesNO: undefined
        }
      ]
    })
    const backgroundChange = function(item) {
      item.yesNO = true
      item.backgroundColor = '#00a0e9'
      item.color = '#ebebeb'
    }
    const backgroundChangeNo = function(item) {
      item.yesNO = false
      item.backgroundColor = '#00a0e9'
      item.color = '#ebebeb'

    }
    const nextPage = function () {
      router.push({
        path: '/returnVisit'
      })
    }
    return {
      ...toRefs(state),
      nextPage,
      backgroundChange,
      backgroundChangeNo
    }
  }
})
</script>
