export const authWhiteList = [
   'signatureVerification', 'transactionPage', 'ProductDetail', 'detail', 'detailNew', 'healthTold', 'informationInput', 'confirmation',
  'declarationToInform', 'returnTheQuestionnaire', 'returnVisit', 'policyList', 'policyListNew','policyList1','provisionShould', 'payStatus', 'state',
  'elecSignature', 'processDrawer', 'channelProductDetail', 'InsuredInfoConfirmation', 'identification', 'relevanceHealthTold',
  'inusredsConfimPage','verificationSign','orderService','paymentOrder', 'detailPages', 'searchData', 'renewInsurance','underwriting','underwritingProcess',
  'underwritNotification', 'underwritingSearch','underclusion'

]

export const appRouter = [
  {
    //产品详情页
    path: '/app/productDetails',
    name: 'ProductDetail',
    component: () => import('../pages/transaction/productDetails')
  },
  {
    path: '/app/detail',
    name: 'detail',
    component: () => import('../pages/transaction/product_detail'),
  },
  {
    path: '/app/detailNew',
    name: 'detailNew',
    component: () => import('../pages/transaction/product_detail_new'),
  },
  {
    //存在和健康按钮的健康告知页
    path: '/app/healthTold',
    name: 'healthTold',
    component: () => import('../pages/transaction/healthTold')
  },
  {
    //全部为否和下一步的健康告知页
    path: '/app/relevanceHealthTold',
    name: 'relevanceHealthTold',
    component: () => import('../pages/transaction/relevance_healthTold')
  },
  {
    //投保录入页
    path: '/app/informationInput',
    name: 'informationInput',
    component: () => import('../pages/transaction/informationInput')
  },
  {
    //投保职业查询页
    path: '/app/OccupationSelect',
    name: 'OccupationSelect',
    component: () => import('../pages/InsuranceConfiguration/element/views/OccupationSelect')
  },
  {
    //信息确认页
    path: '/app/confirmation',
    name: 'confirmation',
    component: () => import('../pages/transaction/confirmation')
  },
  {
    //声明告知页
    path: '/app/declarationToInform',
    name: 'declarationToInform',
    component: () => import('../pages/transaction/declarationToInform')
  },
  {
    //回访问卷页
    path: '/app/returnTheQuestionnaire',
    name: 'returnTheQuestionnaire',
    component: () => import('../pages/transaction/returnTheQuestionnaire')
  },
  {
    //回访页
    path: '/app/returnVisit',
    name: 'returnVisit',
    component: () => import('../pages/transaction/returnVisit')
  },
  {
    // 条款确认页
    path: '/app/provisionShould',
    name: 'provisionShould',
    component: () => import('../pages/transaction/provisionShould')
  },
  {
    //保单列表页
    path: '/app/policyList',
    name: 'policyList',
    component: () => import('../pages/transaction/policyList')
    // component: () => import('../pages/transaction/order/orderList')
  },
  {
    //保单列表页
    path: '/app/policyListNew',
    name: 'policyListNew',
    component: () => import('../pages/transaction/policyListNew')
  },
  {
    //保单列表页
    path: '/app/policyList1',
    name: 'policyList1',
    component: () => import('../pages/transaction/list/policyList1')
  },
  {
    // 支付状态
    path: '/app/payStatus',
    name: 'payStatus',
    component: () => import('../pages/transaction/payStatus')
  },
  {
    //  返回状态页
    path: '/app/state',
    name: 'state',
    component: () => import('../pages/transaction/state')
  },
  {
    //  签名
    path: '/app/elecSignature',
    name: 'elecSignature',
    component: () => import('../pages/transaction/elSign/elecSignature')
  },
  {
    //  签名流程的审核状态
    path: '/app/identification',
    name: 'identification',
    component: () => import('../pages/transaction/elSign/identification')
  },
  {
    //  签名流程之前的短信验证
    path: '/app/verificationSign',
    name: 'verificationSign',
    component: () => import('../pages/transaction/elSign/verificationSign')
  },
  {
    //  远程签名跳转的短信验证
    path: '/app/signatureVerification',
    name: 'signatureVerification',
    component: () => import('../pages/transaction/elSign/signatureVerification')
  },
  {
    //  试算页面理赔流程
    path: '/app/processDrawer',
    name: 'processDrawer',
    component: () => import('../pages/transaction/processDrawer')
  },
  // 渠道产品中转页
  {
    path: '/app/channelProductDetail',
    name: 'channelProductDetail',
    component: () => import('../pages/channelProductDetail/channelProductDetail')
  },
  // 被保人信息确认
  {
    path: '/app/InsuredInfoConfirmation',
    name: 'InsuredInfoConfirmation',
    component: () => import('../pages/transaction/public/ShowPayForm')
  },
  // 多被保人信息确认
  {
    path: '/app/inusredsConfimPage',
    name: 'inusredsConfimPage',
    component: () => import('../pages/transaction/inusredsConfimPage')
  },
  // 临时测试支付用
  {
    path: '/app/ShowPayForm',
    name: 'ShowPayForm',
    component: () => import('../pages/transaction/public/ShowPayForm')
  },

  {
    //  返回状态页
    path: '/app/orderList',
    name: 'orderList',
    component: () => import('../pages/transaction/order/orderList')
  },
  {
    //  待支付
    path: '/app/paymentOrder',
    name: 'paymentOrder',
    meta: {
      keepAlive: true //需要被缓存
    },
    component: () => import('../pages/transaction/orderService/paymentOrder')
  },
  {
    //  待双录 待回访 待回执
    path: '/app/orderService',
    name: 'orderService',
    component: () => import('../pages/transaction/orderService/index')
  },
  {
    //  订单详情页
    path: '/app/detailPages',
    name: 'detailPages',
    component: () => import('../pages/transaction/orderService/detailPages')
  },
  {
    //  订单查询
    path: '/app/searchData',
    name: 'searchData',
    component: () => import('../pages/transaction/orderService/searchData')
  },
  {
    //  订单查询
    path: '/app/renewInsurance',
    name: 'renewInsurance',
    component: () => import('../pages/transaction/orderService/renewInsurance')
  },
  {
    //  查看核保详情
    path: '/app/underwriting',
    name: 'underwriting',
    component: () => import('../pages/transaction/orderService/underwriting')
  },
  {
    //  流程中健康告知跳智核
    path: '/app/underwritingProcess',
    name: 'underwritingProcess',
    component: () => import('../pages/transaction/orderService/underwritingProcess')
  },
  {
    //  流程中核保答题
    path: '/app/underclusion',
    name: 'underclusion',
    component: () => import('../pages/transaction/orderService/underclusion')
  },
  {
    //  流程中智核详情
    path: '/app/underwritNotification',
    name: 'underwritNotification',
    component: () => import('../pages/transaction/orderService/underwritNotification.vue')
  },
  {
    //  流程中智核详情
    path: '/app/underwritingSearch',
    name: 'underwritingSearch',
    component: () => import('../pages/transaction/orderService/underwritingSearch')
  },


]
