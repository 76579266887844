<template>
  <div class="detail_class">
    <div style="min-height: 120px">
      <div class="header">
        <IOSbackBar :title="'疾病搜索'" ></IOSbackBar>
        <p>请选择被保人过往或现有的病症（可多选）</p>
        <van-search
            v-model="searchValue"
            ref="searchVal"
            placeholder="搜索预评估的疾病"
            show-action
            autofocus
            clearable
            @update:model-value="onUpdateValue"
            id="search-input"
            @search="getSearchData"
            input-align="left"
        >
          <template #action>
            <span @click="onCancel">取消</span>
          </template>
        </van-search>
      </div>
    </div>
    <!--    查询展示的内容-->
    <div class="content">
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
      >
        <div class="popup_content">
          <van-cell-group inset>
            <div class="cell_class" v-for="(child, index) in searchList" :key="child.id">
              <!--                <div class="popup_content_title" v-if="searchList.length === 1">{{ child.parentDiseaseContent }}</div>-->
              <van-cell :title="child.diseaseName">
                <template #right-icon>
                  <van-checkbox :name="child.id" v-model:checked="child.type" @click.stop="handelClickCell(child, index)"/>
                </template>
              </van-cell>
            </div>
          </van-cell-group>
        </div>
      </van-list>
    </div>
    <footer class="footer">
      <div class="popup_footer">
        <p v-if="!selectList.length" style="color: #d1d0d0">还未选择...</p>
        <div class="popup_footer_select" v-if="selectList.length">
          <van-tag v-for="(item, index) in selectList" :key="item.id" color="#f7f7f9" text-color="#333" closeable
                   size="large" type="primary" @close="delSelect(item, index)">
            {{ item.diseaseName }}
          </van-tag>
        </div>
        <div class="foo-right">
          <van-button type="primary" :disabled="!selectList.length" @click="saveSearchValue">添加<span
              v-if="selectList.length > 0">({{ selectList.length }})</span></van-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive, toRefs, ref} from 'vue'
import {Search, Tag, Button, Icon, Cell, List, Checkbox, CheckboxGroup, CellGroup} from 'vant'
import {request} from '@/utils/request'
// import {getExtendUri} from '@/utils/request_extend'
import store from '@/store'
import router from '@/router'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'

export default defineComponent({
  components: {
    [Search.name]: Search,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [List.name]: List,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [CellGroup.name]: CellGroup,
    IOSbackBar
  },
  setup() {
    const state = reactive({
      loading: false,
      finished: true,
      searchValue: router.currentRoute.value?.query?.diseaseValue,
      selectList: [],
      supperCode: JSON.parse(store.getters['insured/getProductDetailData']).productInfo.supplierCode,
      searchList: [],
      contextList: [],
      commonList: [],
      saveList: []
    })
    const searchVal = ref()
    onMounted(() => {
      state.saveList = sessionStorage.getItem('processSelectList') ? JSON.parse(sessionStorage.getItem('processSelectList')) : []
      console.log(state.saveList, 'state.saveList')
      // sessionStorage.setItem('processSelectList', '')
      document.title = '智能核保'
      nextTick(() => {
        document.getElementById('search-input').focus()
      })
      getSelAllAndCommon()
      onUpdateValue()
    })

    // 查询数据
    const getSelAllAndCommon = () => {
      request('/product-service/product/disease/api/getDiseaseList?riskCode=' + JSON.parse(store.getters['insured/getRiskList']).riskList[0].riskCode, null, false, 'get').then(res => {
        if (res.code === 1) {
          state.contextList = res.result
        }
      })
      // request(getExtendUri('selAllAndCommon'), {supperCode: state.supperCode}).then(res =>{
      //   if(res.code === 1){
      //     state.commonList = res.result.commonList
      //     res.result.list.forEach(item =>{
      //       item.children.forEach(child =>{
      //         child.type = false
      //       })
      //     })
      //     state.contextList = res.result.list
      //     let diseaseValue = router.currentRoute.value.query.diseaseValue
      //     if (diseaseValue){
      //       state.searchValue = diseaseValue
      //       getSearchData()
      //     }
      //   }
      // })
    }
    // 删除选中的
    const delSelect = (data, value) => {
      let parentIndex = state.contextList.findIndex(item => item.id === data.parentId)
      state.contextList[parentIndex].children.forEach(child => {
        if (child.id === data.id) {
          child.type = false
        }
      })
      state.searchList.forEach(child => {
        if (child.id === data.id) {
          child.type = false
        }
      })
      state.selectList.splice(value, 1)
    }
    // 点击 查询的数据
    // const getClickCellValue = (data) =>{
    //   // 得到数据 进行搜素
    //   state.searchValue = data.diseaseContent
    //   handelClickCell(data)
    //   getSearchData(data)
    // }
    // 输入框发生改变的时候
    const onUpdateValue = () => {
      let time = null
      if (!time) {
        state.searchList = []
        time = setTimeout(() => {
          getSearchData()
          clearTimeout(time)
        }, 300)
      }
    }
    // 点击选择框的
    const handelClickCell = (data) => {
      console.log(data, state.selectList, state.contextList, 'data')
      let index = state.selectList.findIndex(item => item.id === data.id)
      // let parentIndex = state.contextList.findIndex(item => item.id === data.parentId)
      // console.log(index, 'parentIndex')
      // state.searchList[idx].forEach(child =>{
      //   if(child.id === data.id) {
      //     child.type = !child.type
      //   }
      // })
      state.searchList.forEach(child => {
        if (child.id === data.id) {
          child.type = !child.type
        }
      })
      if (index !== -1) {
        state.selectList.splice(index, 1)
      } else {
        state.selectList.push(state.searchList.filter(sea => sea.id === data.id)[0])
      }
    }
    const onCancel = () => {
      if (!state.searchValue) {
        router.go(-1)
      } else {
        state.searchValue = ''
        state.searchList = []
      }
    }
    // 数据搜索
    const getSearchData = () => {
      state.searchList = []
      let list = []
      if (state.searchValue) {
        state.contextList.forEach(item => {
          if (item.children && item.children.length) {
            item.children?.forEach(child => {
              child.children?.forEach(son => {
                if (son.diseaseName.includes(state.searchValue)) {
                  list.push({
                    id: son.id,
                    diseaseName: son.diseaseName,
                    parentId: son.parentId,
                    type: false
                  })
                }
              })
            })
          }
        })
      } else {
        state.contextList.forEach(item => {
          if (item.children && item.children.length) {
            item.children?.forEach(child => {
              child.children?.forEach(son => {
                list.push({
                  id: son.id,
                  diseaseName: son.diseaseName,
                  parentId: son.parentId,
                  type: false
                })
              })
            })
          }
        })
      }
      // 对比并设置type属性
      for (let obj1 of list) {
        for (let obj2 of state.selectList) {
          if (obj1.id === obj2.id) {
            obj1.type = true
            break
          }
        }
      }

      //去重
      let map = new Map()
      for (let cal of list) {
        if (!map.has(cal['id'])) {
          map.set(cal['id'], cal)
        }
      }
      list = [...map.values()]
      state.searchList = list.filter(obj1 => !state.saveList.some(obj2 => obj2.id === obj1.id))
    }
    // 添加
    const saveSearchValue = () => {
      if (!state.selectList.length) return
      state.saveList.push(...state.selectList)
      sessionStorage.setItem('processSelectList', JSON.stringify(state.saveList))
      // const url = new URL(window.location)
      // url.searchParams.delete('diseaseValue')
      // delete router.currentRoute.value.query.diseaseValue
      // console.log(router)
      router.push({
        path: '/app/underwritingProcess'
      })

    }


    return {
      ...toRefs(state),
      // getClickCellValue,
      getSearchData,
      onUpdateValue,
      saveSearchValue,
      onCancel,
      handelClickCell,
      searchVal,
      delSelect
    }
  }
})
</script>

<style scoped>
.detail_class {
  text-align: left;
  overflow-y: auto;
}

.header {
  width: 100%;
  z-index: 2;
  padding: 0 8px 20px 20px;
  margin-bottom: 10px;
  background-color: var(--van-cell-background-color);
  position: fixed;
  top: 0;
}

.header :deep(.van-search) {
  padding: 0 !important;
  border-radius: 20px;
  border: 1px solid #1181f6;
}
.content{
  margin-top: 20px;
}
:deep(.van-cell__title) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header :deep(.van-search__action) {
  color: #1677ff;
}

.header :deep(.van-search__content ) {
  background-color: #fff !important;
  border-radius: 24px;
}

.cell_class {
  /*position: relative;*/
}

.popup_content_title {
  font-size: 17px;
  color: #cacaca;
  padding-top: 25px;
}

.footer {
  min-height: 70px;
}

.popup_footer {
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  background: #fff;
}

.popup_footer /deep/ .van-button {
  width: 90px;
  padding: 0 20px !important;
  border-radius: 13px !important;
}

.popup_footer_select {
  overflow-x: auto;
  white-space: nowrap;
  margin-right: 10px;
}
</style>
