import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user.js'
import dictionary from './modules/dic.js'
import rule from './modules/rule.js'
import insured from './modules/insured.js'
const store = createStore({
  modules: {
    user: user,
    dictionary: dictionary,
    rule: rule,
    insured: insured
  },
  mutations: {
    RESET_STATE: (state) => {
      Object.assign(state, {
        insured: {}
      })
    },
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    paths: ['user', 'dictionary', 'rule', 'insured'],
    reducer(val) {
      let {...u} = val.user
      return {
        dictionary: val.dictionary,
        rule: val.rule,
        user: u,
        insured: val.insured
      }
    }
  })]
})

export default store
