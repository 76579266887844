<template>
  <div class="attach_class">
    <div class="example" v-if="loadingShow">
      <a-spin size="large"/>
    </div>
    <van-nav-bar
      right-text="取消"
      @click-right="onClickRight"
    >
      <template #title>
        <form action="/">
          <van-search
            v-model="selectName"
            placeholder="请输入您想要搜索的关键词"
            @search="onSearch"
            @cancel="onClickRight"
          />
        </form>
      </template>
    </van-nav-bar>
    <div class="flexBox" v-if="detailList.length">
      <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item,index) in detailList" :key="item" :title="index" >
            <div class="listContent">
              <div class="orderHeader" @click="handelToDetail(item)">

                <span class="orderName">
                  <van-icon name="label-o" style="font-size: 16px; margin-right: 5px"/>
                  {{ item.productInfoName }}</span>
                <span class="order-status">{{ item.statusName }}</span>
              </div>
              <div class="order-details" @click="handelToDetail(item)">
                <div class="order-info">
                  <span class="order-label">订单号：</span>
                  <span class="order-value">{{ item.tradeNo }}</span>
                </div>
                <div class="order-info">
                  <span class="order-label">投保人：</span>
                  <span class="order-value">{{ item.applicantName }}</span>
                </div>
                <div class="order-info">
                  <span class="order-label">被保人：</span>
                  <span class="order-value">{{ item.protectedmanName }}</span>
                </div>
                <div class="order-info">
                  <span class="order-label">投保时间：</span>
                  <span class="order-value">{{ item.polApplyDate }}</span>
                </div>
              </div>
              <van-divider />
              <div class="order-bottom">
                <div class="order-left">
                  <span class="order-label">保费：</span>
                  <span style="color: red;font-size: 18px">￥{{item.premium}}</span>
                </div>
                <div class="order-actions" v-if="item.contStatus !== '1'">
                  <van-button
                    v-if="item.cancelShow"
                    class="confirm-button cancel-button"
                    type="default"
                    size="small"
                    @click="cancelOrder(item)"
                  >
                    取消
                  </van-button>
                  <van-button
                    v-if="item.okShow"
                    class="ok-button"
                    size="small"
                    plain
                    @click="nextJump(item)"
                  >
                    继续
                  </van-button>
                  <van-button
                    v-if="item.underwritingStatus === '2' || item.underwritingStatus === '5'"
                    class="ok-button"
                    size="small"
                    plain
                    @click="seeDetail(item)"
                  >
                    查看详情
                  </van-button>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>


</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from 'vue'
import { Search, NavBar,Cell, List, PullRefresh, Divider, Icon,Button } from 'vant'
import {request} from '@/utils/request'
import router from '@/router'
import {getExtendUri} from '@/utils/request_extend'
import store from '@/store'
import {message, Modal} from 'ant-design-vue'
import {nowPathShow} from '@/utils/product_process'
import {getUrlParams} from '@/utils/utlis'
export default defineComponent({
  components:{
    [Search.name]: Search,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [List.name]: List,
    [Divider.name]: Divider,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Button.name]: Button,
  },
  setup() {
    const state = reactive({
      current: 0,
      selectName: '',
      detailList: []
    })
    const showState = reactive({
      loadingShow: false,
      loading: false,
      refreshing: false,
      finished: false,
    })
    // 搜索
    const onSearch = () =>{
      state.current = 0
      state.detailList = []
      getDetailList()
    }
    const onRefresh = () =>{
      state.current = 0
      state.detailList = []
      onLoad()
    }

    const onLoad = () => {
      showState.loading = true
      showState.finished = false
      showState.refreshing = true
      showState.loadingShow = true
      getDetailList()
    }
    const getDetailList = () =>{
      state.current++
      let token = router.currentRoute.value.query.token ? router.currentRoute.value.query.token : ''
      // let token = '60c3c4f0550f4a898502bc46883384a1'
      let parameter = {
        pagination:{
          current: state.current,
          pageSize: 10
        },
        data:{
          selectName: state.selectName,
          statusType: 'all',
        }
      }
      request(`/product-service/product/productInsured/bdt/api/queryListForPageFront?token=${token}`, parameter, true, 'post').then(res => {
        if(res.code === 1 && res.result && res.result.length){
          if (res.result.length !== 10) {
            showState.finished = true
          }
          res.result.forEach(item => {
            item.okShow= ['0','2'].includes(item.paySataus) && ['0','1','4'].includes(item.underwritingStatus) && item.contStatus === '0'
            item.cancelShow = ['0','2'].includes(item.paySataus) && ['0','1','2','4','5'].includes(item.underwritingStatus)&& item.contStatus === '0'
          })
          state.detailList = state.detailList.concat(res.result)
          showState.loading = false
          showState.refreshing = false
          showState.loadingShow = false
        }else{
          showState.loading = false
          showState.loadingShow = false
          showState.finished = true
          showState.refreshing = false
        }
      })
    }

    const onClickLeft = () => history.back()
    const onClickRight = () => {
      state.selectName = ''
      state.detailList = []
    }
    // 查看详情
    const seeDetail = (data) =>{
      const params = getUrlParams(window.location.href)
      router.push({
        path: '/app/underwriting',
        query: {
          ...params,
          id: data.id,
          underwritingStatus: data.underwritingStatus
        }
      })
    }
    // 根据订单状态跳转不同的页面
    const nextJump =  function (item) {
      showState.loadingShow = true
      //待支付中的继续
      if (item.payUrl) {
        window.location.href = item.payUrl
      } else {
        saveProductInfo(item)
      }
    }
    const saveProductInfo = (item) => {
      request(getExtendUri('getLinkData'), {
        data: {
          id: item.shareId
        }
      }).then(res => {
        if (res.code === 1 && res.result) {
          // 将详情信息存入
          store.dispatch('insured/changeInsureJSInfo', { productDetailData: JSON.stringify(res.result) })
          store.dispatch('insured/changeInsureJSInfo', { productId: res.result.productInfo.productId })
          let productDetailInfo = res.result
          state.productId = productDetailInfo ? productDetailInfo.productInfo.productId : ''
          getNodeState(item)
        }else if (res.code === 300) {
          window.location.assign(res.result)
        } else {
          showState.loadingShow = false
          message['error'](res.message)
        }
      })

    }
    const getNodeState = (item) => {
      request(getExtendUri('getProcessByProductId'), {
        data: {
          productId: state.productId
        }
      }).then(res => {
        // 先将获取到的node节点存入缓存
        let nodeDetailData = res.result
        showState.loadingShow = false
        // 遍历获取到的node节点
        nodeDetailData.forEach(nodeItem => {
          if (nodeItem.nextCode) {
            if (nodeItem.nextCode.indexOf('acknowledgementOrder') != -1) {
              nowPathShow(nodeItem.processId, nodeItem.sort, item.tradeNo)
              getRiskListFun(item.tradeNo)
              router.push({
                path: '/app/confirmation',
                query: {
                  tradeNo: item.tradeNo,
                  shareId: item.shareId,
                  processId: nodeItem.processId,
                  // sort:nodeItem.sort,
                  // id: nodeItem.processId
                }
              })
            }
          }
        })
      })
    }
    const getRiskListFun = (tradeNo) => {
      request(getExtendUri('getCacheParameters'), {
        tradeNo: tradeNo
      }).then(res => {
        store.dispatch('insured/changeInsureJSInfo', { riskList: JSON.stringify(res.result.selectRisk) })
        store.dispatch('insured/changeInsureJSInfo', {submitDate: JSON.stringify(res.result.saveData)})
      })
    }
// 取消
    const cancelOrder = (data) =>{
      let token = router.currentRoute.value.query.token ? router.currentRoute.value.query.token : ''
      // let token = 'def8594bcd424ee083e16e11fbf3b058'

      Modal.confirm({
        title: '提示',
        content: '确认取消该订单？',
        onOk: () => {
          request('/trade-service/trade/insuredPayData//bdt/api/cancelInsured?token='+token, { insuredId: data.id }).then(res => {
            if (res.code === 1) {
              showState.loadingShow = true
              state.current = 0
              getDetailList()
              message.success('取消成功')
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    // 跳转详情页
    const handelToDetail = (data) =>{
      sessionStorage.setItem('selectName', state.selectName)
      const params = getUrlParams(window.location.href)
      router.push({
        path: '/app/detailPages',
        query: {
          ...params,
          id: data.id,
          underwritingStatus: data.underwritingStatus,
        }
      })
    }
    onMounted(()=>{
      document.title = '订单查询'
      if (sessionStorage.getItem('selectName')){
        state.selectName = sessionStorage.getItem('selectName')
        getDetailList()
      }
    })

    return {
      ...toRefs(state),
      ...toRefs(showState),
      handelToDetail,
      onClickLeft,
      cancelOrder,
      nextJump,
      onClickRight,
      onSearch,
      onRefresh,
      onLoad,
      seeDetail
    }
  }
})
</script>

<style scoped>

:deep(.van-nav-bar__title){
  width: 80% !important;
  max-width: 80% !important;
}
:deep(.van-search__content){
  border-radius: 20px !important;
}
:deep(.van-nav-bar__text){
  color: #837b7b !important;
}
.orderName {
  font-size: 3.5vw;
  line-height: 2.3rem;
  font-weight: 700;
  display: inline-block;
  max-width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.order-status {
  color: #ff3333;
  font-size: 3.5vw;
  line-height: 2.3rem;
  font-weight: 700;
  float: right;
}

.order-details {
  margin-bottom: 8px;
}

.order-info {
  display: flex;
  margin-bottom: 4px;
  font-size: .7rem;
  line-height: 1.5rem;
}

.order-label {
  color: #222;
}

.order-value {
  text-align: right;
  flex: 1;
}

.order-actions {
  display: flex;
  justify-content: flex-end;
}

.cancel-button {
  margin-right: 16px;
}
.flexBox{
  margin-top: 10px;
  background: #f5f6fa;
}

:deep(.van-nav-bar .van-icon) {
  color: #cbcbcb !important;
}
:deep(.van-grid-item__content){
  padding: 20px 8px !important;
}
.time_class span{
  margin: 2px 8px 15px;
  min-width: 95px;
  display: inline-block;
  color: #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
}

.listContent {
  margin: 8px 0 15px;
  padding: 8px 20px;
  background-color: #fff;
}
.order-bottom{
  display: flex;
  justify-content: space-between;
}
:deep(.van-tabs--card>.van-tabs__wrap){
  margin: 8px;
}
:deep(.van-tabs__nav--card){
  border-radius: 18px;
  color: #318dff;
  border: 1px solid #318dff;
  height: 25px !important;
}
:deep(.van-tab--card) {
  border-radius: 18px 0 0 18px;
  color: #318dff;
  border-right: 0;
}
:deep(.van-tab--card.active) {
  border-radius: 18px 0 0 18px;
}
:deep(.van-tab--card:last-child) {
  border-radius: 0 18px 18px 0;
}
:deep(.van-tab--card:last-child.active) {
  border-radius: 0 18px 18px 0;
}
:deep(.van-tab--card.van-tab--active) {
  background-color: #318dff;
  color: #ffffff;
}

:deep(.van-dropdown-menu__title--active) {
  color: #1989fa;
}
.confirm-button{
  border-color: #5b5b5b;
  color: #646566;
  height: 23px;
  width: 70px;
  font-size: 0.8rem;
  border-radius: 10px;
}
.orderHeader{
  text-align: left;
}
:deep(.van-divider){
  background: #cbcbcb;
}
.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}
.ok-button{
  border-color: #ff3333;
  color: #FF7D15;
  height: 23px;
  width: 70px;
  font-size: 0.8rem;
  border-radius: 10px;
}
</style>
