<style scoped>
.cash-value-content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.risk_divider {
  margin: 6px 0;
}

.cash-table {
  width: 100%;
  text-align: center;
}

.cash-slider {
  margin: 3rem 1rem 1rem 1rem;
}

:deep(.ant-slider-handle) {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -.5rem;
}

.slider-tip {
  line-height: 2rem;
  margin-left: 1rem;
}

.slider-tip label {
  display: inline-block;
  width: 35%;
}
</style>

<template>
  <a-skeleton :loading="loading" active>
    <div v-if="type === 'Slider'" class="cash-value-content">
      <template v-for="(riskList, riskIndex) in cashList" :key="'riskCash_' + riskIndex">
        <template v-for="(dutyList, dutyIndex) in riskList['dutyInfoList']"
                  :key="'dutyCash_' + riskIndex + '_' + dutyIndex">
          <a-divider class="risk_divider"/>
          <a-slider class="cash-slider"
                    v-model:value="dutyList['curIndex']"
                    :max="dutyList['max']"
                    :min="dutyList['min']"
                    @change="onChange(dutyList)"/>
          <div class="slider-tip">
            <label>保单年度末：</label><span>{{ dutyList['curLabel'] ? dutyList['curLabel']['duration'] : '' }}</span>
          </div>
          <div class="slider-tip">
            <label>年龄：</label><span>{{ dutyList['curLabel'] ? dutyList['curLabel']['age'] : '' }}</span></div>
          <div class="slider-tip">
            <label>现金价值：</label><span>{{ dutyList['curLabel'] ? dutyList['curLabel']['val'] : '' }}</span></div>
        </template>
      </template>
    </div>
    <div v-else-if="type === 'Table'" class="cash-value-content">
      <template v-for="(riskList, riskIndex) in cashList" :key="'riskCash_' + riskIndex">
        <template v-for="(dutyList, dutyIndex) in riskList['dutyInfoList']"
                  :key="'dutyCash_' + riskIndex + '_' + dutyIndex">
          <a-divider class="risk_divider"/>
          <table class="cash-table">
            <tr class="cash-table-head-row">
              <th style="width: 35%">保单年度末</th>
              <th style="width: 35%">年龄</th>
              <th style="width: 30%">现金价值</th>
            </tr>
            <template v-for="(valueList, valueIndex) in dutyList['durationInfoList']"
                      :key="'cashValue_' + riskIndex + '_' + dutyIndex + valueIndex">
              <tr class="cash-table-data-row">
                <td style="width: 35%">{{ valueList.duration }}</td>
                <td style="width: 35%">{{ valueList.age }}</td>
                <td style="width: 30%">{{ valueList.val }}</td>
              </tr>
            </template>
          </table>
        </template>
      </template>
    </div>
  </a-skeleton>
</template>

<script>
import {defineComponent, onBeforeMount, reactive, toRefs, watch} from 'vue'
import {request} from '../../utils/request'
import {getExtendUri} from '../../utils/request_extend'

export default defineComponent({
  name: 'ProductCashValue',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    type: {
      // Slider, Table
      type: String,
      required: true,
      default: 'Table'
    }
  },
  components: {},
  setup(props) {
    const state = reactive({
      baseInfo: {
        tradeNo: props.modelValue
      },
      loading: false,
      cashList: []
    })
    watch(props, (nVal, oVal) => {
      if (nVal.modelValue !== oVal.modelValue) {
        state.baseInfo.tradeNo = nVal.modelValue
        console.debug('updateInfo', nVal)
        updateInfo()
      }
    })
    onBeforeMount(() => {
      if (state.baseInfo.tradeNo && state.baseInfo.tradeNo !== '') {
        updateInfo()
      }
    })
    const updateInfo = () => {
      state.loading = true
      request(getExtendUri('queryCashValueByTradeNo'), {
        baseInfo: state.baseInfo
      }).then(res => {
        state.loading = false
        if (res.code === 1 && res.result) {
          state.cashList = res.result
          state.cashList.forEach(r => {
            r.dutyInfoList.forEach(d => {
              d['curIndex'] = 1
              d['curLabel'] = {
                ...d['durationInfoList'][0]
              }
              d['min'] = 1
              d['max'] = d['durationInfoList'].length
            })
          })
        }
      })
    }
    const onChange = (data) => {
      let i = data['curIndex']
      data['curLabel'] = {
        ...data['durationInfoList'][i - 1]
      }
    }
    return {
      ...toRefs(state),
      onChange,
    }
  }
})
</script>
