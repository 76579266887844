const rule = {
  namespaced: true,
  state: () => ({
    keyWordsStr: '',
    keywordList: [],
    keywords: [],
    keywords_CN: [],
    objects: [],
    objects_CN: [],
    functions: [],
    functions_CN: [],
    operators: [],
    operators_CN: []
  }),
  mutations: {
    updateRuleJSInfo(state, payload) {
      if (payload) {
        Object.keys(payload).forEach(key => {
          state[key] = payload[key]
        })
      }
      if(payload.nextValue && payload.nextValue === '1'){
        Object.assign(state, {
          keyWordsStr: '',
          keywordList: [],
          keywords: [],
          keywords_CN: [],
          objects: [],
          objects_CN: [],
          functions: [],
          functions_CN: [],
          operators: [],
          operators_CN: []
        })
      }
    }
  },
  actions: {},
  getters: {
    getConfigInfo(state) {
      return state
    }
  }
}
export default rule
