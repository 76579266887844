import {createRouter, createWebHistory} from 'vue-router'
import store from '../store'
import {appRouter, authWhiteList} from './appRouter'
import {startRecord} from '@/utils/rrweb'
import {createFWNum} from '@/utils/fw_serial_number_utils'
import {getQueryJson} from '@/utils/utlis.js'
import {Dialog} from 'vant'

export const isAllMobile = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

// noinspection JSCheckFunctionSignatures
const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_PATH),
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/testVue',
      name: 'testVue',
      component: () => import('../pages/zzwTest/TestJSbridge')
    },
    {
      path: '/uploadTest',
      name: 'uploadTest',
      // 区分一下线上线下的界面，判断为移动端为线上购买？
      component: () => import('../pages/InsuranceConfiguration/testPage')
    },
    // 文浩需要的页面，直接跳转详情页

    {
      path: '/auth',
      name: 'Auth',
      component: () => import('../components/framework/auth')
    },
    {
      path: '/error',
      name: 'Error',
      component: () => import('../components/framework/error')
    },
    {
      path: '/developerDebug',
      name: 'developerDebug',
      component: () => import('../components/debug')
    },
    {
      path: '/',
      name: 'Root',
      component: () => import('../components/framework/application'),
      children: [
        {
          path: '/:frames+',
          name: 'Frame',
          components: store.getters['user/getMenuComponents']
        }
      ]
    },
    ...appRouter
  ]
})
const whiteList = [
  'Auth', 'testVue', 'uploadTest', 'Error', 'developerDebug',
  ...authWhiteList
]

// 给可查到的路由设置名字
const setName = (url) => {
  if (url) {
    const item = store.getters['user/getMenuPathMap'][url]
    if (item) {
      document.title = item.name
    } else {
      document.title = '瑞泰人寿'
    }
  }
}
function needLoadMenuComponents() {
  const compObj = router.getRoutes().filter(r => r.name === 'Frame')[0].components || {}
  if (Object.keys(compObj).length > 0 && compObj['frame_home'] !== undefined && compObj['frame_home']['render'] !== undefined) {
    return false
  }
  return true
}
const recordList = [
  'signatureVerification', 'transactionPage', 'ProductDetail', 'detail', 'detailNew', 'healthTold', 'informationInput', 'confirmation',
  'declarationToInform', 'returnTheQuestionnaire', 'returnVisit', 'policyList', 'provisionShould', 'payStatus', 'state',
  'elecSignature', 'processDrawer', 'channelProductDetail', 'InsuredInfoConfirmation', 'identification', 'relevanceHealthTold',
  'inusredsConfimPage','verificationSign','orderService','paymentOrder', 'detailPages', 'searchData', 'renewInsurance','underwriting','underwritingProcess',
  'underwritNotification', 'underwritingSearch','underclusion'
]
const resetRrweb = async (name,to) =>{
  console.log('app.router------->',  to, name)
  if (recordList.includes(name)){
    if (name === 'detail'){
      store.commit('RESET_STATE') // 使用空对象重置store的状态
      localStorage.clear()
      sessionStorage.clear()
    }
    let tradeNo = getTradeNoByRouter(to.fullPath)
    let uuid = !to.query?.tradeNo
    if(uuid){
      if (tradeNo){
        startRecord(tradeNo, true)
      } else {

        if (name !== 'detail'){
          Dialog.alert({
            message: '视频录制异常,请联系工作人员',
            theme: 'round-button',
            // confirmButtonColor: '#0078fc'
            showConfirmButton: false,
            closeOnPopstate: false
          }).then(()=>{
            console.log('视频录制异常,请稍后操作', to, name)
          })
        }else{
          // 创建新订单
          let uuid = await createFWNum()
          await store.dispatch('insured/changeInsureJSInfo', {'uuid': uuid})
          await store.dispatch('insured/changeInsureJSInfo', {'tradeNo': uuid})
          await startRecord(uuid, true)
        }
      }
    }else{
      if (!tradeNo && name !== 'detail'){
        Dialog.alert({
          message: '视频录制异常,请联系工作人员',
          theme: 'round-button',
          // confirmButtonColor: '#0078fc'
          showConfirmButton: false,
          closeOnPopstate: false
        }).then(()=>{
          console.log('视频录制异常,请稍后操作', to, name)
        })
        return
      }
      // startRecord(store.getters['insured/getTradeNo'], true)
      startRecord(tradeNo, true)
    }
  }
}

const getTradeNoByRouter = (path) => {
  const paramObj = getQueryJson(path)
  if (paramObj.tradeNo) {
    return paramObj.tradeNo
  } else {
    return ''
  }
}
router.beforeEach((to, from, next) => {
  setName(to.path)
  console.log('app.router->' + to.name, 'isLogin:' + store.getters['user/isLogin'], to.fullPath)
  if (store.getters['user/isLogin'] && needLoadMenuComponents()) {
    console.log('app.init')
    store.commit('user/init')
    router.getRoutes().filter(r => r.name === 'Frame')[0].components = store.getters['user/getMenuComponents']
  }
  resetRrweb(to.name, to)
  // 判断该路由是否需要登录权限
  if (whiteList.includes(to.name)) {
    next()
  } else if (!store.getters['user/isLogin']) {
    if(process.env.NODE_ENV==='development' || process.env.NODE_ENV==='production') {
      next({name: 'Auth'})
    } else {
      next({name: 'Error'})
    }
  } else {
    next()
  }
})

router.afterEach(async (to) => {
  console.log('app.router.after->' + to.name)


})

export default router
