<template>
  <div class="attach_class">
    <div class="example" v-if="loadingShow">
      <a-spin size="large"/>
    </div>
    <div class="header">
      <van-tabs v-model:active="active" @click-tab="onClickTab">
        <van-tab v-for="item in tabList" :title="item.label" :key="item.value"></van-tab>
      </van-tabs>
    </div>
    <div style="height: 2.8rem"></div>
    <div class="content" v-if="detailList.length">
      <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="listContent"  v-for="(item,index) in detailList" :key="index" >
            <div class="orderHeader">
              <span class="orderName">投保人: {{ item.applicantName }}</span>
              <span class="order-status">
                {{ routerType ==='1' ? item.doubleRecordStatusName : (routerType ==='2' ? item.rvStatusName : item.receiptStatusName ) }}
              </span>
            </div>
            <div class="order_content" v-for="(keyStr, keyIndex) in Object.keys(titleData)"
                 :key="'form_item_' + keyIndex">
              <div v-if="(keyStr === 'doubleRecordRejectTypeName' || keyStr=== 'doubleRecordFeedbackTime')&& item.doubleRecordStatus ==='C'">
                <span class="orderName">{{ titleData[keyStr] }}</span>
                <span class="order-float">{{ item[keyStr] }}</span>
              </div>
              <div v-if="keyStr !== 'customGetPolDate' && keyStr !== 'doubleRecordRejectTypeName' && keyStr!== 'doubleRecordFeedbackTime'">
                <span class="orderName">{{ titleData[keyStr] }}</span>
                <span class="order-float">{{ item[keyStr] }}</span>
              </div>
              <div v-if="keyStr === 'customGetPolDate'&& routerType ==='3' && active === 1">
                <span class="orderName">{{ titleData[keyStr] }}</span>
                <span class="order-float">{{ item[keyStr] }}</span>
              </div>

            </div>
            <div v-if="routerType !== '1'">
              <van-divider style="background-color: #828282"/>
              <div class="order-left">
                <div>
                  <span class="order-label">保费：</span>
                  <span>{{item.premium}}元</span>
                </div>
                <p v-if="item.visitUrl && routerType ==='2' && active===0" @click="getInvitationSign(item)">邀请回访</p>
                <p v-if="item.receiptUrl && routerType ==='3' && active===0" @click="getInvitationSign(item)">邀请签收</p>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>

    <div v-if="!detailList.length && showType">
      <div class="null_img">
        <img alt="" src="../../../assets/img/no_order.png" />
        <div>暂无记录</div>
      </div>
    </div>
  </div >

</template>

<script>
import {defineComponent, reactive, toRefs, ref, onBeforeMount} from 'vue'
import {Cell, Divider, List, PullRefresh, Tab, Tabs} from 'vant'
import router from '../../../router'
import { orderData} from './detailData'
import {getExtendUri} from '@/utils/request_extend'
import {request} from '@/utils/request'
import {callHandler} from '@/pages/zzwTest/js_bridge'
import {message} from 'ant-design-vue'
// import store from '@/store'
export default defineComponent({
  components:{
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [List.name]: List,
    [Divider.name]: Divider,
  },
  setup() {
    const active = ref(0)
    const lineWidth = ref('50px')
    const tabList = ref([])
    const state = reactive({
      titleData: {},
      // 标签切换展示
      // 数据展示
      detailList: [],
      routerType: '',
      loading: false,
      finished: false,
      refreshing: false,
      current: 0,
      showType: false,
      loadingShow: false
    })
    onBeforeMount(()=>{
      console.log('看一下路由', router)
      getTabList()
    })
    // 加载的方法
    const onLoad = () =>{
      state.loading = true
      state.finished = false
      state.refreshing = true
      state.loadingShow = true
      state.showType = false
      getDetailList()
    }
    const onRefresh = () => {
      state.detailList = []
      state.current = 0
      onLoad()
    }
    // 获取列表信息
    const getDetailList = () =>{
      state.current++
      let source = ''
      let token = router.currentRoute.value.query.token ? router.currentRoute.value.query.token : ''
      // let token = '08e1124a45dc45eabfca2a645de6c28e'
      let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
      let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS){
        source = 'ios'
      }
      if (isAndroid) {
        source = 'android'
      }
      let parameter = {
        pagination:{
          current: state.current,
          pageSize: 10
        },
        data:{
          statusType: tabList.value[active.value].value
        }
      }
      request(`/product-service/product/productInsured/bdt/api/queryListForPageFront?source=${source}&sign=xxxx&version=68&token=${token}`, parameter, true, 'post').then(res => {
        if(res.code === 1 && res.result && res.result.length){
          if (res.result.length !== 10) {
            state.finished = true
          }
          state.detailList = state.detailList.concat(res.result)
          state.loading = false
          state.refreshing = false
          state.showType = true
          state.loadingShow = false
        }else{
          state.loading = false
          state.finished = true
          state.refreshing = false
          state.loadingShow = false
          state.showType = true
        }
      })
    }

    // 点击切换tab标签
    const onClickTab = () =>{
      state.detailList = []
      state.showType = false
      state.loadingShow = true
      state.current = 0
      onLoad()
    }
    const getTabList = () =>{
      state.routerType = router.currentRoute.value.query.orderType
      state.titleData = orderData[state.routerType]
      tabList.value = []
      if(state.routerType === '1'){
        document.title = '双录管理'
        getDictionary('double_record')
        // getDictionaryData(tabList, 'double_record')
      }else if(state.routerType === '2'){
        document.title = '回访管理'
        getDictionary('follow_up')
        // getDictionaryData(tabList, 'follow_up')
        lineWidth.value = '100px'
      }else{
        document.title = '回执签收'
        getDictionary('receipt')
        // getDictionaryData(tabList, 'receipt')
        lineWidth.value = '100px'
      }
    }
    const  getDictionary = (type) =>{
      try {
        request(getExtendUri('dictionary_query_byType'),{data: {types: [type]}}).then(
          res => {
            tabList.value = res.result[type]
            onLoad()
          })
      } catch (error) {
        message.error(error)
      }
    }
    // 邀请签收
    const getInvitationSign = (item) =>{
      const str = state.routerType === '2' ? '在线回访' : '在线回执'
      let config = {
        shareurl: state.routerType === '2' ? item.visitUrl : item.receiptUrl,
        sharetitle: '您有一份电子保单' + (state.routerType === '2' ? '待回访' : '回执待签收'),
        // sharetitle: item.shareTitle,
        // sharesecondtitle: item.shareSecondTitle,
        sharesecondtitle: `${item.agentName}邀请您对保单${item.contNo}进行${str}`,
        sharephoto: item.sharePhoto,
        nonameshare: 'true',
      }
      callHandler('share', config)
    }


    return {
      ...toRefs(state),
      tabList,
      active,
      lineWidth,
      onLoad,
      onRefresh,
      onClickTab,
      getDetailList,
      getTabList,
      getInvitationSign
    }
  }
})
</script>

<style scoped>
.attach_class{
  height: calc(100vh);
  background-color: #f5f6fa;
}
.header{
  background: white;
  position: fixed;
  top: 0;
  z-index:99;
  width: 100%;
}
:deep(.van-tabs__line){
  background-color: #1677ff !important;
  height: 2px !important;
  width: v-bind(lineWidth);
}
:deep(.van-tab){
  font-size: 4vw !important;
  color: #333 !important;
}
:deep(.van-tab--active) {
  color: #1677ff !important;
}
.content{
  text-align: left;
  background-color: #f5f6fa;
}
.listContent{
  background-color: #fff;
  padding: 0 15px 15px;
  margin-top: .8rem;
}
.orderHeader{
  font-size: 4.2vw;
  line-height: 2.3rem;
}
.order-status{
  float: right;
  color: #0079fe;
}
.order-float{
  float: right;
}
.order_content{
  line-height: 2rem;
  font-weight: 400;
  font-size: .8rem;
  color: #666;
}
.null_img{
  padding: 8vw 0 4vw;
}
.null_img img{
  width: 5rem;
}
.null_img div{
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}
.order-left{
  display: flex;
  justify-content: space-between;
}
.order-left p{
  border: 1px solid #0078fc;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 12px;
  color: #0078fc;
}
.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}
</style>
