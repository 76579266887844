<template>
  <div>
    <a-modal @cancel="handleCancel" v-model:visible="isVisible" destroyOnClose  @ok="onVerifyOk" width="90%"
             :centered="isVisible">
      <!--    图形验证码-->
      <div v-if="isCancas" class="canvas_class">
        <a-input v-model:value="canvasValue" placeholder="请输入图形验证码"></a-input>
        <img alt="" src="" width="100" height="30" ref="canvasRef" id="myImage" @click="handleDrawCode" />
      </div>
      <div v-if="!isCancas">
        <p v-if="isShow">请先获取验证码</p>
        <p v-if="!isShow">验证码已发送到手机号:{{ insuredMobile }}</p>
        <div>
          <a-input-group compact style="width:100%">
            <a-input v-model:value="verificationCode" style="width: calc(100% - 120px)" :maxlength="6"/>
            <a-button type="primary" @click="countDown" style="width: 120px">{{ textShow }}</a-button>
          </a-input-group>
        </div>
      </div>
      <template #footer>
        <div v-if="isCancas">
          <a-button type="primary" size="large" class="submit_class" @click="onSubmit">提交</a-button>
        </div>
        <div v-if="!isCancas">
          <a-button key="back" @click="handleCancel">取消</a-button>
          <a-button key="submit" type="primary" :loading="loading" @click="onVerifyOk">确认</a-button>
        </div>
      </template>

    </a-modal>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import {defineComponent, toRefs, reactive, ref, nextTick, onBeforeMount} from 'vue'
import {request} from '@/utils/request'
import {getExtendUri} from '@/utils/request_extend'
import router from '@/router'
import store from '@/store'

export default defineComponent({
  props: {
    isCheck: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['update:isCheck'],
  setup(props, context) {
    const state = reactive({
      insuredMobile: '',
      canvasValue: '',
      isVisible: false,
      isCancas: true,
      textShow: '获取验证码',
      verificationCode: '',
      loading: false,
      countDownIng: false,
      countDownTime: 60,
      showCode: '',
      timer: null,
      isShow: true,
      detailData: {}
    })
    const canvasRef = ref()
    onBeforeMount(()=>{
      state.tradeNo = router.currentRoute.value.query.tradeNo
    })
    const showData = (data) =>{
      state.isVisible = true
      nextTick(()=>{
        handleDrawCode()
        state.detailData = data
        state.insuredMobile = data.mobile
      })

    }
    // 画个图
    const handleDrawCode = () =>{
      state.showCode = ''
      const sCode = 'A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,a,b,c,d,e,f,g,h,i,j,k,m,n,p,q,r,s,t,u,v,w,x,y,z,1,2,3,4,5,6,7,8,9,0'
      const canvasW = canvasRef.value.width
      const canvasH = canvasRef.value.height
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.width = canvasW
      canvas.height = canvasH
      const aCode = sCode.split(',')
      const aLength = aCode.length
      for(let i = 0; i < 4; i++) {
        const j = Math.floor(Math.random() * aLength)
        const deg = Math.random() - 0.5
        const txt = aCode[j]
        state.showCode += txt.toLowerCase()
        const x = 10 + i * 20
        const y = 18 + Math.random() * 8
        context.font = '24px bold'
        context.translate(x, y)
        context.rotate(deg)
        context.fillStyle = getColor()
        context.rotate(-deg)
        context.translate(-x, -y)
        context.fillText(txt,x,y)
      }
      for (let k = 0; k<= 5; k++) { //验证码上显示线条
        context.strokeStyle = getColor()
        context.beginPath()
        context.moveTo(Math.random() * canvasW, Math.random() * canvasH)
        context.lineTo(Math.random() * canvasW, Math.random() * canvasH)
        context.stroke()
      }
      for (let z = 0; z <= 20; z++) { //验证码上的小点
        context.strokeStyle = getColor()//随机生成
        context.beginPath()
        const x = Math.random() * canvasW
        const y = Math.random() * canvasH
        context.moveTo(x, y)
        context.lineTo(x + 1, y + 1)
        context.stroke()
      }
      document.getElementById('myImage').src = canvas.toDataURL('image/png', 1)
    }
    const getColor = () => {
      const r = Math.floor(Math.random() * 256)
      const g = Math.floor(Math.random() * 256)
      const b = Math.floor(Math.random() * 256)
      return 'rgb(' + r + ',' + g + ',' + b + ')'
    }


    // 验证码
    const onVerifyOk = () => {
      if (state.verificationCode.length < 6) {
        message['error']('请输入完整的验证码')
        return false
      }
      state.loading = true
      setTimeout(() => {
        state.loading = false
        state.visible = false
      }, 2000)
      let baseInfo = {
        mobile: state.insuredMobile,
        verifyCode: state.verificationCode,
        attributive: state.detailData.attributive,
        tradeNo: state.tradeNo,
      }
      request(getExtendUri('getCheckMessage'), baseInfo).then(res => {
        if (res.code === 1) {
          message['success'](res.message)
          context.emit('update:isCheck', true)
          let data = {
            ...state.detailData,
            type: true,
          }
          store.dispatch('insured/saveVerifyCode', data)
          handleCancel()
        }else {
          message['error'](res.message)
        }
      })

    }
    const handleCancel = () => {
      Object.assign(state, {
        insuredMobile: '',
        canvasValue: '',
        isVisible: false,
        isCancas: true,
        textShow: '获取验证码',
        verificationCode: '',
        loading: false,
        countDownIng: false,
        countDownTime: 60,
        showCode: '',
        isShow: true,
        detailData: {}
      })
      clearInterval(state.timer)
      state.isVisible = false
    }
    //获取验证码  + 倒计时功能
    const countDown = async () => {
      state.isShow = false
      if (!state.countDownIng) {
        request(getExtendUri('getSendVerifyCode'), {mobile: state.insuredMobile,tradeNo: state.tradeNo}).then(res => {
          if(res.code === 1){
            message.success('验证码已经发送到您的手机上,请注意查收')
            state.timer = setInterval(() => {
              state.countDownTime--
              state.textShow = state.countDownTime + 's后重新发送'
              state.countDownIng = true
              if (state.countDownTime <= 0) {
                clearInterval(state.timer)
                state.textShow = '获取验证码'
                state.isShow = true
                state.countDownTime = 60
                state.countDownIng = false
              }
            }, 1000)
          }else{
            message.warning(res.message)
          }
        })
      }
    }

    // 提交
    const onSubmit = () =>{
      if (state.canvasValue === '') {
        message.warning('请输入图形验证码！')
      } else if (state.showCode.toLowerCase() === state.canvasValue.toLowerCase()) {
        message.success('图形验证码验证成功！')
        state.isCancas = false
      } else {
        message.warning('图形验证码错误！请重新输入！')
      }

    }


    return {
      onSubmit,
      onVerifyOk,
      countDown,
      handleCancel,
      showData,
      canvasRef,
      handleDrawCode,
      ...toRefs(state)
    }
  }

})
</script>

<style scoped>
.canvas_class{
  display: flex;
  margin-top: 30px;
}
  canvas {
    margin-left: 2rem;
    vertical-align: middle;
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: pointer;
    background-color: #eee;
  }
  .submit_class{
    color: #fff;
    font-weight: 500;
    border-radius: 25px;
    width: 100%;
  }
  :deep(.ant-input ){
    width: calc(100% - 100px);
  }
</style>
