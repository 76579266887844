<template>
 <div class="status-style" :style="{color: '#4ca539'}" v-if="status">
   <check-circle-outlined />
 </div>
  <div class="status-style" style="color:red" v-else>
    <close-circle-outlined />
  </div>
  <div class="status-context-style">
    <div>{{message}}</div>

  </div>
<!--  <a-button type="danger" @click="nextPage" shape="round">下一步</a-button>-->
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    CheckCircleOutlined,
    CloseCircleOutlined
  },
  setup() {

    const state = reactive({
      status : true,
      message:''
    })
    let url = location.search   //获取url中"?"符后的字串
    if (url.indexOf('?') != -1) {
      let str = decodeURIComponent(url.split('&')[1].split('=')[1])
      let returnData = JSON.parse(str)
      state.status = returnData.flag === 'Y'?true:false
      state.message = returnData.message
    }
    return {
      ...toRefs(state)
    }
  }
})
</script>

<style scoped>
.status-style {
  font-size: 60px;
  margin-bottom: 18px;
  padding-top: 150px;
}
img {
  margin-bottom: 10px;
}
.status-context-style {
  letter-spacing: 1.8px;
  padding-bottom: 5px;
  margin-bottom: 13px;
}
button {
  width: 150px;
  height: 40px;
  /*border-radius: 50%;*/
}
</style>
