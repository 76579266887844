<template>
  <van-pull-refresh v-model:refreshing="refreshing" @refresh="onRefresh">
    <div v-for="(order, index) in orderList" :key="'order'+index" class="order-item">
      <OrderListItem :order="order" />
    </div>
    <div v-if="loading" class="loading-container">
      <van-loading size="24px" type="spinner" color="#c9c9c9" />
      <span class="loading-text">加载中...</span>
    </div>
    <div v-if="!loading && !orderList.length" class="empty-container">
      <van-empty image="search" description="暂无数据" />
    </div>
  </van-pull-refresh>
</template>

<script>
import {reactive, ref, toRefs} from 'vue'
import OrderListItem from './OrderListItem.vue'
import { PullRefresh, Loading, Empty} from 'vant'

export default {
  name: 'OrderListRefresh',
  components: {
    OrderListItem,
    [PullRefresh.name]: PullRefresh,
    [Loading.name]: Loading,
    [Empty.name]: Empty,
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const refreshing = ref(false)
    const loading = ref(false)
    const state = reactive({
      orderList: props.orders
    })
    const onRefresh = () => {
      refreshing.value = true
      setTimeout(() => {
        refreshing.value = false
      }, 1000)
    }

    return {
      ...toRefs(state),
      refreshing,
      loading,
      onRefresh,
    }
  },
}
</script>

<style scoped>
.order-item {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
}

.loading-container,
.empty-container {
  margin-top: 20px;
  text-align: center;
}

.loading-text {
  margin-left: 10px;
  font-size: 14px;
  color: #c9c9c9;
}
</style>
