<template>
  <!-- <van-popup
  closeable
    position="bottom"
    :close-on-click-overlay="false"
    :style="{ height: '60%' }"
    v-model:show="show"
  > -->
  <a-drawer
    placement="bottom"
    :height="height"
    :visible="show"
    :closable="false"
  >
  <div class="gaozhishu">
<!--    <h3 class="gzs-head" style="font-size:1.1rem;margin: 0;">北京恒荣汇彬保险代理有限责任公司</h3>-->
    <h3 class="gzs-head">客户告知书</h3>
    <p>尊敬的客户：</p>
    <p class="gzs-indent">感谢您选择我公司为您办理保险业务。我公司是根据《中华人民共和国保险法》的规定，依法设立的专门从事保险代理业务的保险专业代理机构。为了维护您的合法权益，我公司按照《保险代理人监管规定》的要求，向您告知如下内容：</p>
    <p>&emsp;&emsp;一、公司基本情况</p>
    <p>&emsp;&emsp;（一）公司名称：瑞泰人寿保险有限公司</p>
    <p>&emsp;&emsp;（二）营业场所：北京市海淀区西直门外大街168号腾达大厦33/35层01-03、05-11房间、腾达大厦11层06-09房间</p>
    <p>&emsp;&emsp;（三）经营区域和业务范围：在北京市行政辖区内及已设立分公司的省、自治区、直辖市内经营下列业务（法定保险业务除外）：一、人寿保险、健康保险和意外伤害保险等保险业务；二、上述业务的再保险业务。（市场主体依法自主选择经营项目，开展经营活动；依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）。</p>
<!--    <p>&emsp;&emsp;（四）机构编码：201982000000800</p>-->
    <p>&emsp;&emsp;（四）联系方式：400-810-9339</p>
    <p>&emsp;&emsp;二、请仔细阅读保险条款，重点关注保险责任、责任免除、被保险人权利义务、免赔额或免赔率的计算、犹豫期解除合同、退保损失、保险新型产品费用扣除及投资风险、健康保险产品等待期等内容，并可要求我公司业务人员对上述内容进行详细讲解。</p>
    <p>&emsp;&emsp;三、请向我公司业务人员了解《中华人民共和国保险法》等法律法规对于索赔时效、保险公司理赔时限、合同中止与失效、未成年人投保限额、保险标的转让、重复保险等的相关规定，以及不履行如实告知义务、故意制造保险事故或夸大事故损失、申报年龄不真实等情形导致的法律后果。</p>
    <p>&emsp;&emsp;四、根据《中华人民共和国保险法》的规定，保险代理人根据保险公司的授权代为办理保险业务的行为，由保险公司承担责任。保险代理人没有代理权、超越代理权或者代理权终止后以保险公司名义订立合同，使投保人有理由相信其有代理权的，该代理行为有效。保险公司可以依法追究越权的保险代理人的责任。</p>
    <p>&emsp;&emsp;五、根据《中华人民共和国个人信息保护法》的规定，您同意授权保险公司及保险代理机构出于保险服务目的，使用、加工、存储、共享您投保时提供的个人信息。个人信息包括投保人姓名、证件类型、证件号、手机号，参保人姓名、证件类型、证件号、保单号等。保险公司及保险代理机构可通过短信、邮件、电话或其他形式向您发送服务、通知、活动或其他相关信息。为确保您的信息安全，保险公司及保险代理机构对上述信息负有保密义务，并采取各种措施保证信息安全。</p>
    <p style="margin-bottom: 2rem">
      &emsp;&emsp;六、我公司已按《保险代理人监管规定》的要求，统一投保了职业责任保险。<br/>
      &emsp;&emsp;七、我公司的高级管理人员与被代理的保险公司或其他保险中介机构不存在关联关系。<br/>
      &emsp;&emsp;八、如果您发现我公司从业人员存在误导行为及其他损害您合法权益的行为，请注意保留书面证据或其他证据。可向保监局或当地保险行业协会反映，或通过向有管辖权的人民法院提起诉讼。您也可以向我公司进行反映，投诉问题的受理方式为：<br/>
      &emsp;&emsp;1、联系电话：400-810-9339<br/>
      &emsp;&emsp;2、官网投诉：http://www.oldmutual-chnenergy.com
    </p>
    <p class="gzs-right">瑞泰人寿保险有限公司</p>


  </div>
  <div class="mask-btn-out" v-if="show">
    <div class="mask-btn" @click="handelOk">确认已阅读并知晓本告知内容</div>
  </div>
  </a-drawer>
  <!-- </van-popup> -->
</template>

<script>
import { defineComponent,reactive, toRefs } from 'vue'
  export default defineComponent({
  props:{
    checked:{
      type: Boolean,
      default: () => false
    }
  },
  emits:['update:checked'],
  setup(props,context){
    const state = reactive({
      show: false,
      checkType: false,
      height: '70%'
    })
    const showPage = (height) =>{
      state.height = `${height}px`
      state.show = true
    }
    const handelOk =() =>{
      state.show = false
      state.checkType = true
      context.emit('update:checked', state.checkType)
    }



    return {
      ...toRefs(state),
      showPage,
      handelOk,
    }

  }
})

</script>

<style scoped>
  .gaozhishu{
    font-family: '微软雅黑';
    color: #222;
    height: 90%;
    -webkit-overflow-scrolling: touch;
    line-height: 1.5rem;
    padding: 0.3rem;
    text-align: left;
    padding-bottom: 1.3rem;
    font-size: 1rem;
  }
  /* .ant-drawer::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  } */
  .gzs-head{
    text-align: center;
    font-size: 1.3rem;
    margin-top: 0;
    margin-bottom: 1.3rem;
  }
  .gzs-indent{
    text-indent: 1.8rem;
    line-height: 1.5rem;
  }
  .gzs-right{
    min-height: 130px;
    margin-top: 0.8rem;
    text-align: right;
  }
  .mask-btn-out{
    position: fixed;
    left: 0;
    bottom: 0;
    background: white;
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
  }
  .mask-btn{
    background: #1677FF;
    border-radius: 4px;
    line-height: 2.8rem;
    text-align: center;
    color: white;
    /* box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 95%;
    padding: 0rem 1rem;
    background: #1677FF;
    border-radius: 4px;
    line-height: 2.4rem;
    text-align: center;
    color: white;
    margin: 0 10px; */
  }
  p{
    margin: 0;
  }
</style>
