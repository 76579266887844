let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
const jsToAndroid = (method,params) => {
  if (params){
    window.android[method](JSON.stringify(params))
  } else {
    window.android[method]()
  }
}

const androidToJs = (method,method2) => {
  // 要将方法挂载在window上
  window[method] = method2
}

const jsToIos = (method,params) => {
  if (window.webkit.messageHandlers[method]) {
    if (params){
      window.webkit.messageHandlers[method].postMessage(params)
    } else {
      window.webkit.messageHandlers[method].postMessage()
    }
  }

}
//必须添加的代码
const setupWebViewJavascriptBridge = (callback) => {
  //iOS
  if (window.GCWebviewJSBridge) {
    return callback(window.GCWebviewJSBridge)
  }
  if (window.KBWVJSBCallBacks) {
    return window.KBWVJSBCallBacks.push(callback)
  }
  window.KBWVJSBCallBacks = [callback]
  let GCWVJSBIframe = document.createElement('iframe')
  GCWVJSBIframe.style.display = 'none'
  GCWVJSBIframe.src = 'gcwvjsbscheme://__GC_BRIDGE_LOADED__'
  document.documentElement.appendChild(GCWVJSBIframe)
  setTimeout(function() { document.documentElement.removeChild(GCWVJSBIframe) }, 0)
}

const callHandler = (name, data, callback) => {
  console.info(`执行callHanlder methodName:${name}`)
  setupWebViewJavascriptBridge( (bridge) => {
    console.info(`执行setupWebViewJavascriptBridge bridge对象:${bridge}`)
    bridge.callHandler(name, data, callback)
    console.info(`执行setupWebViewJavascriptBridge2 bridge对象:${bridge}`)
  })
}

const registerHandler = (name, callback) => {
  console.info(`执行 registerHandler methodName:${name}`)
  setupWebViewJavascriptBridge( (bridge) => {
    console.info(`registerHandler-执行setupWebViewJavascriptBridge bridge对象:${bridge}`)
    bridge.registerHandler(name,  (data, responseCallback) => {
      console.info(`registerHandler-执行setupWebViewJavascriptBridge2 bridge对象:${bridge}`)
      callback(data, responseCallback)
      console.info(`registerHandler-执行setupWebViewJavascriptBridge3 bridge对象:${bridge}`)
    })
  })
}
export default {
  sendMsgToApp (method,params,callback) {
    console.info(`isAndroid:${isAndroid} isIos:${isiOS}`)
    if (isiOS){
      callHandler(method, params, callback)
      jsToIos(method,params)
    }
    if (isAndroid) {
      jsToAndroid(method,params)
    }
  },
  // m1 原生方法，m2 vue方法
  receiveFromApp (m1,m2,callback) {
    if (isAndroid) {
      androidToJs(m1,m2)
    }
    if (isiOS) {
      registerHandler(m1,callback)
    }
  }
}


