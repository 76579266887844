<template>

  <div class="clearfix">
    <div>
      <a-upload
        v-model:file-list="fileList"
        list-type="picture-card"
        :multiple="true"
        @change="handleChange"
        @preview="handlePreview"
        :customRequest="uploadFile"
        :accept="filetypeL"
        style="margin-bottom:15px"
        :beforeUpload="beforeUpload"
        :max-count="1"
        :show-upload-list="{showPreviewIcon:true}"
      >

        <div v-if="fileList.length < 1" class="list_class">
          <div style="position: relative; width: 100%;height: 100%" v-if="fileList.length  < 1">
            <div class="plus_lined">
              <img alt="peizhao" style="width: 2rem; height: 2rem;"  src="../../../assets/img/icon-photo.png"/>
              <div style="margin-top: 8px;">{{ fileState.up_placeholder }}</div>
            </div>
          </div>
        </div>
        <template #itemRender="{file}">
          <div class="demo-upload-list">
            <!-- <div> -->
            <img
              v-if="file.status==='done'"
              style="object-fit: fill;width: 100%;height: 100%; flex-grow: 1;}"
              :src="file.playUrl"
              alt="正在飞速加载......"
            >
            <a-progress v-else
                        :width="80" type="circle" :percent="file.percent"/>
            <div class="demo-upload-list-cover">
<!--              <close-circle-outlined  style="width:100px; position: absolute;right:-40px;top: 0px;}"  @click="handleRemove(file)"/>-->
              <DeleteTwoTone style="width:100px; position: absolute;right:-50px;top: 0;}"  @click="handleRemove(file)"/>
            </div>
            <!-- </div> -->
          </div>
        </template>
      </a-upload>
      <a-modal :visible="previewVisible" :title="previewTitle"  @cancel="handleCancel">
        <img alt="example" style="width: 100%;height: 100%" src="../../../assets/img/emblem.png"/>
      </a-modal>
    </div>
  </div>
</template>

<script>

import {reactive, toRefs, onMounted, onBeforeMount} from 'vue'
import { uploadObject, getDownloadInfo} from '../../../utils/upload'
import {addPropToItem, removeByValue, selectItemByProp} from '../../../utils/ArrayUtils'
import {docIcon, zipIcon, defaultIcon, xlsIcon, pptIcon, pdfIcon, rtfIcon, txtIcon, wpsIcon,} from '../../../utils/icon64list'
import { message } from 'ant-design-vue'
import {request} from '../../../utils/request'
// import HeaderImg from '../../../assets/img/header.png'
// import EmblemImg from '../../../assets/img/emblem.png'
// import Card from '../../../assets/img/card.png'
import {  DeleteTwoTone } from '@ant-design/icons-vue'
export default {
  name: 'uploadFileToCos',
  components: {
    DeleteTwoTone: DeleteTwoTone
    // EyeOutlined: EyeOutlined,
    // DeleteOutlined: DeleteOutlined
  },
  props: {
    styleType: {
      type: Number,
      default: () => 0
    },
    maxNum: {
      type: [Number, String],
      default: () => 1
    },
    formatList: {
      type: Array,
      default: () => []
    },
    selectList:{ // 限制类型
      type: Array,
      default:()=> []
    },
    echoList:{
      type: Array,
      default:()=> []
    },
    enclosureName: {
      type: String,
      default: () => ''
    },
    isSpecial:{
      type: Number,
      default: () => 1
    },
    uploadNameType:{
      type: Number,
      default: () => 1
    },
    placeholder: {
      type: String,
      default: () => ''
    }
  },
  emits:['fileChange', 'getFileList'],
  setup(props, context) {
    const params = reactive({
      cosType: 'cos',
      fileObj : {},
      cos: {
        bucket: '',
        region: '',
        folder: '',
        TmpSecretId: '',
        TmpSecretKey: '',
        XCosSecurityToken: '',
        StartTime: '',
        ExpiredTime: '',
        ScopeLimit: true
      },
      styleType: props.styleType,
      fileList: props.echoList,
      filetypeL: '',
      previewVisible: false,
      previewTitle: '',
      previewImage: '',
      type: '',
      previewUrl: '',
      previewVideoImgUrl: '',
      showProgress: false,

    })
    const getCOSType = () => {
      let uri = '/product-service/product/cos/getTempToken'
      let param = {
        'data': {
          'type': ''
        },
      }
      return request(uri, param).then(res => {
        if (res.code === 1) {
          return res.result.osType
        }
        return ''
      })
    }
    onBeforeMount(async ()=> {
      params.cosType = await getCOSType()
    })
    const uploadToCos = (uploadFile) => {
      let uid = uploadFile.uid
      uploadObject(uploadFile, params.cosType,(type, fileUrl, origin_file_name, key, p) => {
        if (p && fileUrl === '') {
          // 设置进度及状态
          if (p !== 100) {
            addPropToItem(params.fileList,'percent',p,'uid',uid)
          } else {
            addPropToItem(params.fileList,'percent',p,'uid',uid)
            addPropToItem(params.fileList,'status','done','uid',uid)
          }
        } else {
          // console.debug(`类型:${type}\ncos-url:${fileUrl}\n文件名:${origin_file_name}\nKEY:${key}`)
          // console.debug(`上传的对象：${JSON.stringify(uploadFile)}`)
          // console.debug(`已上传文件列表：${JSON.stringify(params.fileList)}`)

          let uid = uploadFile.uid
          params.fileList.forEach(file => {
            if (file.uid === uid) {
              file['playUrl'] = fileUrl
              file['cosKey'] = key
              if (file.thumbUrl === '') {
                console.debug(777777,type)
                //  TODO 只做了图文处理，--- 还差 其他文件类型默认图，及展示对应文件的弹窗
                let comType = compareType('mp4',type)
                console.debug(66666666666,comType)
                if (compareType('doc',type)||compareType('docx',type)){
                  file.thumbUrl = docIcon
                } else if (compareType('zip',type)||compareType('rar',type)||compareType('7z',type)){
                  file.thumbUrl = zipIcon
                } else if (compareType('xlsx',type)||compareType('xls',type)){
                  file.thumbUrl = xlsIcon
                } else if (compareType('ppt',type)||compareType('pptx',type)){
                  file.thumbUrl = pptIcon
                } else if (compareType('pdf',type)){
                  file.thumbUrl = pdfIcon
                }else if (compareType('rtf',type)){
                  file.thumbUrl = rtfIcon
                }else if (compareType('txt',type)){
                  file.thumbUrl = txtIcon
                }else if (compareType('wps',type)){
                  file.thumbUrl = wpsIcon
                }else {
                  file.thumbUrl = defaultIcon
                }
              }
            }
          })
          console.debug('更新属性后的已上传文件列表',params.fileList)
          // 将更新的值 返回
          context.emit('fileChange', params.fileList)
        }
      })
    }

    /**
     * 根据uid获取已上传列表的file对象
     * @param e
     * @returns {*}
     */
    const getFileFormListByEuid = (e) => {
      return selectItemByProp(params.fileList, 'uid', e.uid)[0]
    }

    /**
     * 执行移除操作
     * @param e
     */
    const handleRemove = (e) => {
      let file = getFileFormListByEuid(e)
      if (file.cosKey) {
        removeByValue(params.fileList, 'uid', file.uid)
        message.success('删除成功')
        // 删除之后的值返回出去
        context.emit('getFileList',  file)
      }
    }
    const handlePreview = async (e) => {
      params.fileObj = e
      let file = getFileFormListByEuid(e)
      console.debug('执行步骤: handlePreview',file)
      params.previewTitle = file.name
      params.type = file.name.split('.').pop() // 得到上传的后缀名
      // TODO 在这里处理弹窗预览的东西
      if (params.type === 'mp4') {
        // 在此处获取
        params.previewUrl = file.playUrl
      } else {
        params.previewImage = file.thumbUrl
      }
      params.previewVisible = true
    }
    const handleChange = () => {
      context.emit('fileChange', params.fileList, props.isSpecial)
    }
    // 上传腾讯云
    const uploadFile = (e) => {
      uploadToCos(e.file)
    }
    const beforeUpload = (file) => {
      if(!props.selectList.length) {
        return
      }
      // console.debug('上传的文件',file)
      // TODO 做判断：1.文件格式，2. 数量
      // 限制类型
      let fileType = file.name.split('.').pop() // 得到上传的后缀名
      let format = props.selectList.map(item => item.toLocaleUpperCase())
      if(!format.includes('.' + fileType.toLocaleUpperCase())){
        message.warning(`请上传 ${format} 格式的文件`)
        return false
      }else {
        return true
      }
    }
    const handleCancel = () => {
      params.previewVisible = false
      params.previewImage = ''
      params.previewUrl = ''
      //  TODO  注意播放视频关闭弹窗，也要处理
    }

    const compareType = (key,type) => {
      console.debug('得到想要的值', key,type)
      if (key.toUpperCase() === type.toUpperCase()) {
        return true
      }
      return false
    }

    // 预览下载
    const downloadFile = () =>{
      let file = getFileFormListByEuid(params.fileObj)
      if (file.cosKey) {
        getDownloadInfo(file, params.cosType)
      }
    }
    const fileState = reactive({
      specialImg: '',
      up_placeholder: props.placeholder ? props.placeholder : '点击拍照/上传'
    })

    onMounted(() => {
      //限制的类型进行遍历
      params.filetypeL = props.selectList.join()
      // console.debug('限制的类型', params.filetypeL)
    })
    return {
      fileState,
      ...toRefs(params),
      handlePreview,
      handleChange,
      uploadFile,
      beforeUpload,
      handleCancel,
      handleRemove,
      compareType,
      downloadFile
    }
  }
}
</script>

<style scoped>
:deep(.ant-upload.ant-upload-select-picture-card){
  width: 8rem;
  height: 5.7rem;
  border: 0;
}
.plus_lined{
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width: 100%;

}
:deep(.ant-upload-list-picture-card-container){
  width: 8rem;
  height: 5.7rem;
  /*margin-bottom: 4.6rem;*/
}
.demo-upload-list-cover {
  /*position: absolute;*/
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover :deep(.anticon-eye) {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-left: 15px;
}

.demo-upload-list-cover :deep(.anticon-delete) {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 15px;
}

.demo-upload-list {
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  background: #fff;
  position: relative;
  margin-right: 4px;
  /*position: relative;*/
  width: 8rem;
  height: 5.7rem;
}
.clearfix{
  height: 100%;
  width: 100%;
}
.list_class{
  background: #F0F7FF;
  width: 100%;
  height: 100%;
}
</style>
