<style scoped>
.product_wrap {
  width: 100%;
  height: 100vh;
  background-color: #f6f8fb;
  overflow-x: hidden;
  text-align: left;
}

.bottom_title {
  position: fixed;
  bottom: 2.6rem;
  width: 100%;
  height: 3.6rem;
  line-height: 3.6rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 999;
  padding: 0 1rem;
  box-shadow: 0 0 2px 0 rgba(179, 179, 179);
}
.bottom_bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  /*height: 2.6rem;*/
  line-height: 2.6rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 999;
  box-shadow: none;
}
.butt_class{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.6rem;
  line-height: 2.6rem;
  font-size: 1.5rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem;

}

.bottom_fix {
  height: 7rem;
}
.insure_active{
  color: #fff;
  font-weight: 500;
  border-radius: 0;
  width: 7rem;

}

.insure_btn {
  color: #0079f1;
  width: 7rem;
  border: 1px solid #0079f1;
  font-weight: 500;
  border-radius: 0;
}
:deep(.ant-modal-content .ant-modal-footer) {
  border-top: none !important;
  text-align: center !important;
}
.modal-footer{
  width: 8.5rem;
  height: 2.6rem;
  border:1px solid #0079f1;
  color: #0079f1;
}
.modal_class{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.1rem;
}
.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}
.borderNone{
  border: 0;
}
.detailModal_class{
  font-family: PingFangSC-Regular;
  font-size: 1.01rem;
  color: #333333;
  text-align: center;
  margin: 0.45rem 0.25rem;
}
.butt_tong{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem;
  font-size: 1.5rem;
}
.title_but{
  height: 3rem;
  padding: 0 1rem;
  font-size: 1rem;
}

</style>

<template>
  <div class="product_wrap">
    <div class="example" v-if="loadingShow">
      <a-spin size="large"/>
    </div>
    <template v-for="(item,index) in showData" :key="'healthTold_' + index">
      <PreviewPop :isBlod="true" :previewData="item" @PreviewPopData="PreviewPopData"/>
    </template>
    <div class="bottom_fix"></div>

    <div class="bottom_bar" >
      <div class="example" v-if="loadingShow">
        <a-spin size="large"/>
      </div>
      <div class="bottom_title" v-if="configList && configList.length" v-html="showEditorContent"></div>
      <div class="butt_class" v-if="configList && configList.length">
        <div v-for="(item, index) in configList" :key="index">
          <a-button
            v-if="item.modelValue === '1' || item.modelValue=== '3'"
            type="ghost"
            size="large" class="insure_btn" :disabled="loadingShow"  @click="getType(item)" >{{item.priInput}}</a-button>
          <a-button type="primary"  v-else
                    size="large" class="insure_active"  :disabled="loadingShow"  @click="getType(item)" >{{item.priInput}}</a-button>

        </div>
      </div>
      <div class="title_but" v-if="!configList || !configList.length">请确认是否存在上述健康问题:</div>
      <div class="butt_tong" v-if="!configList || !configList.length">
        <a-button :disabled="loading" type="text" class="insure_btn" size="large"  @click="allNo">全部为{{yesOrNo}}</a-button>
        <a-button type="primary" class="insure_btn" size="large" :style="{color: disabledShow ? '#999' : '#f1f1f1'}" @click="nextPage" :class="{borderNone: disabledShow}" :disabled="disabledShow">下一步</a-button>
      </div>
      <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isIdentical" @ok="insuranceErrorHandleOk">
      <div class="detailModal_class">{{ detailModal }}</div>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="insuranceErrorHandleOk">确认
        </a-button>
      </template>
    </a-modal>
      </div>

  </div>
</template>

<script>
import {defineComponent, onBeforeMount, reactive, toRefs, watch, onMounted, onUnmounted} from 'vue'
// eslint-disable-next-line no-unused-vars
import {request} from '../../utils/request.js'
import {getExtendUri} from '../../utils/request_extend.js'
import {message} from 'ant-design-vue'
import { useRouter } from 'vue-router'
import store from '../../store'
import {getAge} from '@/utils/utlis'

export default defineComponent({
  components: {
    // PreviewPop
  },
  beforeRouteEnter(to, from, next) {
    // document.title = '健康告知'
    next()
  },
  setup() {
    let  router  = useRouter()
    const routerData = JSON.parse(store.getters['insured/getRouterJump'])
    const state = reactive({
      applicantIdNo: '',
      insuredsIdNo: '',
      btnShow: false,
      activeShow: false,
      isIdentical: false,
      typeJump: true,
      loadingShow: true,
      loading: false,
      name: '',
      showData: [],
      visible: false,
      modelValue: '1',
      showEditorContent: '',
      configList: [],
      showObj: {},
      nowArr: [],
      riskList: '',
      riskObj: {},
      tradeNo: router.currentRoute.value.query['tradeNo'],
      elementId: '',
      pageNum: 0,
      tohandelHealthData: false,
      healthSort: '',
      healthSum: 0,
      disabledShow: true,
      relationToappnt: '',
      yesOrNo: '否',
      detailModal: '',
      showDataCopy: [],
      moduleType: '',
      idNo:'',
      huancunIdNo: '',
      parametersDataList: [],
      typeCode: router.currentRoute.value.query['routerCode'] ?router.currentRoute.value.query['routerCode'] : 1
    })
    onBeforeMount(() => {
      state.loadingShow = false
      state.tradeNo = router.currentRoute.value.query['tradeNo']
      reverseandsort()
      state.showEditorContent = routerData.bottomTitleConfig
      state.riskList = store.getters['insured/getRiskList'] ? JSON.parse(store.getters['insured/getRiskList']).riskList : []
      state.riskObj = state.riskList.filter(risk => risk.riskSign === '1')[0]['calculateFactor'].reduce((all, curVal) => {
        return {[curVal.factorType]: curVal.factorValue, ...all}
      }, {})
      state.elementId = router.currentRoute.value.query['elementId']
      state.relationToappnt = router.currentRoute.value.query['relationToappnt']
      state.moduleType = router.currentRoute.value.query['moduleType']
      if (router.currentRoute.value.query['moduleIndex'].includes('?')){
        state.moduleIndex = router.currentRoute.value.query['moduleIndex'].split('?')[0]
      }else {
        state.moduleIndex = router.currentRoute.value.query['moduleIndex']
      }
    })

    onMounted(()=>{
    })

    const  reverseandsort = () =>{
      state.configList = routerData.buttonConfigList
      let arr = state.configList.filter(item => item.modelValue > 2)
      arr.length > 0 ? state.configList.sort((a,b)=> b.modelValue - a.modelValue) : state.configList.sort((a,b)=> a.modelValue - b.modelValue)
    }

    // 获取缓存数据
    const getShowDataTemporary = () =>{
      state.parametersDataList = []
      request(getExtendUri('getCacheParameters'), {
        tradeNo: state.tradeNo+ 'showDataTemporary'
      }).then(res => {
        state.parametersDataList = res.result.selectRisk.showData
        getWinLocation()
        getInsureds()
        // 初始化调用接口, 拿到当前产品关联的健康告知
        getCacheHealthToldData()
      })
    }
    getShowDataTemporary()
    //智能核保
    const getIntelligentUnderwriting = () =>{
      handelSaveHealth()
      state.loadingShow = true
      let data = {
        tradeNo: state.tradeNo?state.tradeNo : '',
        supCode: JSON.parse(store.getters['insured/getProductDetailData']).productInfo.supplierCode,
        productCode: state.riskObj.Plan ?state.riskObj.Plan : '',
        planCode: state.riskObj.Plan ? state.riskObj.Plan.slice(0, 3) : '',
        requestType: 'get',
        transRefId: state.tradeNo ? state.tradeNo : '',
        thridBackUrl: new URL(window.location).href,
        intelligenceHBInsureds: getInsureds(),
        productInfoId: store.getters['insured/getProductId']
      }
      request(getExtendUri('getIntelligent'), {...data}).then(res =>{
        if(res.code === 1){
          // 存标识
          saveHealthToldIdentifying()
          window.location.href = res.result
          state.loadingShow = false
        }else{
          message.warning(res.message)
          state.loadingShow = false
        }
      })
    }

    // 获取被保人
    const getInsureds = () =>{
      let insuredsList = []
      let applicantist  = state.parametersDataList.filter(item => item.moduleType === 'applicant')[0].productElementList
      const applicanIdNo  = state.parametersDataList.filter(item => item.moduleType === 'applicant')[0].productElementList[0].filter(ele => ele.elementCode === 'appntIdNo')[0].firstValue
      state.applicantIdNo = applicanIdNo
      if(state.moduleType === 'insuranceInfo'){
        insuredsList  = state.parametersDataList.filter(item => item.moduleType === 'insureds')[0].productElementList
      }else if(state.moduleType ==='insureds'){
        insuredsList.push(state.parametersDataList.filter(item => item.moduleType === 'insureds')[0].productElementList[Number(state.moduleIndex)])
      }
      let arr = new Array(insuredsList.length)
      for (let i = 0; i < insuredsList.length; i++) {
        let data = {
          insuredName: '',
          birthDate : '' ,
          age: '',
          gender : '',
          certiType : '',
          certiNo: '',
          healthInfoFlag: '0'
        }
        for (let j = 0; j < insuredsList[i].length; j++) {
          if(insuredsList[i][j].elementCode === 'relationToAppnt' && insuredsList[i][j].firstValue === '01'){
            getApplicant(arr, applicantist)
            break
          }else{
            switch (insuredsList[i][j]['elementCode']){
              case 'idType':
                data.certiType = insuredsList[i][j].firstValue
                break
              case 'idNo':
                data.certiNo = insuredsList[i][j].firstValue
                state.idNo = insuredsList[i][j].firstValue
                state.huancunIdNo = applicanIdNo + insuredsList[i][j].firstValue
                state.insuredsIdNo = insuredsList[i][j].firstValue
                break
              case 'birthday':
                data.birthDate = insuredsList[i][j].firstValue
                data.age = calAge(insuredsList[i][j].firstValue)  + ''
                break
              case 'sex':
                data.gender = insuredsList[i][j].firstValue
                break
              case 'name':
                data.insuredName = insuredsList[i][j].firstValue
                break
            }
          }
          arr[i] = data
        }
      }
      return arr
    }
    const getApplicant = (arr, applicantist) =>{
      for (let i = 0; i < applicantist.length; i++) {
        let data = {
          insuredName: '',
          birthDate : '' ,
          age: '',
          gender : '',
          certiType : '01',
          certiNo: '',
          healthInfoFlag: '0'
        }
        for (let j = 0; j < applicantist[i].length; j++) {
          switch (applicantist[i][j]['elementCode']){
            case 'appntBirthday':
              data.birthDate = applicantist[i][j].firstValue
              data.age = calAge(applicantist[i][j].firstValue) + ''
              break
            case 'appntSex':
              data.gender = applicantist[i][j].firstValue
              break
            case 'appntIdNo':
              data.certiNo = applicantist[i][j].firstValue
              state.idNo = applicantist[i][j].firstValue
              state.huancunIdNo = applicantist[i][j].firstValue
              break
            case 'appntName':
              data.insuredName = applicantist[i][j].firstValue
              break
          }
          arr[i] = data
        }
      }
    }
    const calAge = (d) => {
      let age = getAge(d.split('-'), store.getters['insured/getTNUMBER'] ? Number(store.getters['insured/getTNUMBER']) : undefined).age[0]
      return age
    }

    // 监视url
    const getWinLocation = async () => {
      const url = new URL(window.location).href
      const newObject = {}
      // 安盛
      let healthInfoResultJson = ''
      if (url.includes('healthInfoResultJson')) {
        healthInfoResultJson = url.substring(url.lastIndexOf('?') + 1).replace('healthInfoResultJson=', '')
      } else if (url.includes('hbStatus')) {
        const urlString = new URL(url)
        const searchParams = new URLSearchParams(urlString.search)
        for (const [key, value] of searchParams.entries()) {
          newObject[key] = value
        }
        // 不是保险模块的话 加上身份证
        if (newObject['moduleType'] && newObject['moduleType'] !== 'insuranceInfo') {
          const insuredsList = state.parametersDataList.filter(item => item.moduleType === newObject['moduleType'])[0].productElementList[Number(newObject['moduleIndex'])]
          const relationToAppnt = insuredsList.filter(item => item.elementCode === 'relationToAppnt')
          if (relationToAppnt.length) {
            if (relationToAppnt[0].firstValue === '01') {
              const applicantist = state.parametersDataList.filter(item => item.moduleType === 'applicant')[0].productElementList[0]
              newObject['idNo'] = applicantist.filter(item => item.elementCode === 'appntIdNo')[0].firstValue
            } else {
              newObject['idNo'] = insuredsList.filter(item => item.elementCode === 'idNo')[0].firstValue
            }
          }
        }
      } else {
        return
      }
      state.loadingShow = true
      request('/edt-service/edt/intelligenceHB/api/receive/' + JSON.parse(store.getters['insured/getProductDetailData']).productInfo.supplierCode, {
        healthInfoResultJson,
        ...newObject
      }).then(res => {
        state.typeCode = res.code
        if (res.code === 1) {
          message.success('智能核保成功, 可以继续投保了')
        } else {
          message.warning(res.message + ',不可以投保了,即将退出')
        }
        setTimeout(() => {
          state.loadingShow = true
          router.push({
            path: '/app/informationInput',
            query: {
              id: JSON.parse(store.getters['insured/getRouterJump']).processId,
              sort: JSON.parse(store.getters['insured/getRouterJump']).sort,
              tradeNo: state.tradeNo,
              type: '1',
              code: res.code,
              moduleType: state.moduleType,
              moduleIndex: state.moduleIndex
            }
          })
          state.loadingShow = false
        }, 1)
      })
    }



    const handelHealthData =  ()=>{
      state.loadingShow = true
      let data = {
        elementId: state.elementId,
        relationToappnt: state.relationToappnt,
        sort: state.pageNum + ''
      }
      request(getExtendUri('getElementHealthTold'),{data}).then(res=>{
        state.loadingShow = false
        if(res.code === 1){
          if(state.showData.length > 0){
            // store.dispatch('insured/saveHealthShowData', state.showData)
            store.dispatch('insured/saveRelationToappnt', state.relationToappnt)
          }
          if(res.result && res.result.length > 0){
            state.associationType = res.result[0].associationType
            document.title =  res.result[0].moduleName
            let arr = []
            // let arr = store.getters['insured/getHealthShowData'] ? store.getters['insured/getHealthShowData'] : []
            let type = store.getters['insured/getRelationToappnt'] ? store.getters['insured/getRelationToappnt'] : ''
            if(arr && arr.length && type && type === state.relationToappnt){
              state.showData = []
              for (let i = 0; i < arr.length; i++) {
                if(arr[i][0].id === res.result[0].id){
                  state.showData = arr[i]
                  state.disabledShow = false
                  break
                }else{
                  Object.assign(res.result[0], {productElementList:[res.result[0].productElementList]})
                  state.showData = res.result
                  refactoringFun(state.showData)
                  InitializeFun(state.showData)
                  break
                }
              }
            }else{
              state.healthSum = 0
              res.result[0].productElementList.forEach(item=>{
                if(item.healthType == 'radioType' && item.required == 1){
                  state.healthSum +=1
                }
              })
              Object.assign(res.result[0], {productElementList:[res.result[0].productElementList]})
              state.showData = res.result
              refactoringFun(state.showData)
              InitializeFun(state.showData)
            }

            state.healthSort = res.result[0].sort
            state.typeJump = true
          }else{
            saveHealthToldIdentifying()
            state.typeJump = false
          }
        }
      })
    }
    const saveHealthToldIdentifying = () =>{
      let data = {
        tradeNo: state.tradeNo,
        applicantIdNo: state.applicantIdNo,
        insuredsIdNo: state.insuredsIdNo,
        moduleType: state.moduleType,
        moduleIndex: state.moduleIndex
      }
      store.dispatch('insured/changeHealthToldIdentifying', data)
    }
    // 弹窗
    const insuranceErrorHandleOk = () => {
      state.isIdentical = false
    }

    const PreviewPopData = function (data) {
      let keys = Object.keys(data)[0]
      let arr = Object.keys(state.showObj).filter(item => item === keys)
      if(arr && arr.length){
        state.showObj[keys] = data[keys]
      }else{
        state.showObj[keys] = {}
        Object.assign(state.showObj[keys],data[keys])
      }
      for (let keys in state.showObj) {
        if (state.showObj[keys].healthType === 'radioType' && state.showObj[keys].required == '1' && !state.showObj[keys][keys] && state.showObj[keys][keys] !== 0) {
          state.disabledShow = true
          break
        }else{
          state.disabledShow = false
        }
      }
      // 为了下一步做缓存
      if(data[keys].healthType === 'inputType'){
        // superiorId
        if(data[keys].superiorId) {
          const superiorList = state.showData[0]['productElementList'][0].filter(item => item.id === data[keys].superiorId)
          if(superiorList.length){
            const child =  superiorList[0].elementOperatingMode.mode_health.conSpanList.filter(con => con.value === 0)[0].elementList[data[keys].index].firstValue
            if(child && child.length){
              let list = child.filter(val => val.type === 'input')
              for (let i = 0; i < data[keys][keys].length; i++) {
                for (let j = 0; j < list.length; j++) {
                  if(data[keys][keys][i].key === list[j].mPropKey && data[keys][keys][i].value !== ''){
                    list[j].value = data[keys][keys][i].value
                  }
                }
              }
            }
          }
        }else{
          let eleList = state.showData[0]['productElementList'][0].filter(item => item.elementCode === keys)
          if(eleList.length && eleList[0].firstValue){
            let list = eleList[0].firstValue.filter(val => val.type === 'input')
            for (let i = 0; i < data[keys][keys].length; i++) {
              for (let j = 0; j < list.length; j++) {
                if(data[keys][keys][i].key === list[j].mPropKey && data[keys][keys][i].value !== ''){
                  list[j].value = data[keys][keys][i].value
                }
              }
            }
          }
        }
      }
    }

    // 判断按钮类型
    const getType = (data) =>{
      if (data.modelValue === '1'){
        state.isIdentical = true
        state.detailModal = '很抱歉，被保人的健康状况不满足该保险的投保规定！'
        return false
      }else if(data.modelValue === '2'){
        allNo(data)
        nextPage(data)
      }else if(data.modelValue === '3'){
        // 智能核保
        getIntelligentUnderwriting()
      }
    }


    //全部为否
    const allNo = function () {
      for (let keys in state.showObj) {
        if (state.showObj[keys].healthType === 'radioType') {
          state.showObj[keys][keys] = 1
        }
        if (state.showObj[keys].healthType === 'inputType' && state.showObj[keys].superiorId) {
          if(Array.isArray(state.showObj[keys][keys]) && state.showObj[keys][keys].length ){
            const list = state.showObj[keys][keys].filter(item => item.type === 'input')
            list.forEach(ele => ele.value = '')
          }
        }
      }
      state.showData[0]['productElementList'][0].forEach(item =>{
        if(item.healthType === 'radioType'){
          item.firstValue = 1
        }
      })
      state.disabledShow = false
      //  数据处理
      allNoFun(state.showData)
    }
    const handleOk = () => {
      state.visible = false
      window.history.back(-1)
    }
    const modalClose = () => {
      state.visible = false

    }
    //健康告知全部为否
    const allNoFun = function (Arr = []) {
      for (let i = 0; i < Arr.length; i++) {
        if (Arr[i].productElementList) {
          Arr[i].productElementList.forEach(item => {
            item.forEach(obj => {
              if(obj.elementOperatingMode.mode_health.conSpanList){
                obj.elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                  allNoFun(item2.elementList)
                })
              }
            })
          })
        } else {
          //这里开始第二层的循环，或者多层的循环
          if (Arr[i].elementOperatingMode && (Arr[i].elementOperatingMode.mode_health.conSpanList.length > 0 || (Arr[i].elementOperatingMode.mode_health.healthInputConfig && Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray ? (Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) : false))) {
            if(state.showObj[Arr[i].elementCode] && state.showObj[Arr[i].elementCode]['superiorId']){
              Arr[i].firstValue = (state.showObj[Arr[i].elementCode] || {})[Arr[i].elementCode] || ''
            }
            if(Arr[i].elementOperatingMode.mode_health.conSpanList.length){
              Arr[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                allNoFun(item2.elementList)
              })
            }
          }
        }
      }
    }
    const InitializeFun = function (eleList = [], moduleType = '', parentId = '') {
      for (let i = 0; i < eleList.length; i++) {
        if (eleList[i].productElementList) {
          eleList[i].productElementList.forEach(item => {
            item.forEach(obj => {
              //如果是第一层级的数据key,并且还有下一层，添加obj
              // if (obj.elementOperatingMode && (obj.elementOperatingMode.mode_health.conSpanList.length > 0 ||(obj.elementOperatingMode.mode_health.healthInputConfig && obj.elementOperatingMode.mode_health.healthInputConfig.strArray?(obj.elementOperatingMode.mode_health.healthInputConfig.strArray.length>0?true:false):false))) {
              if (obj.elementOperatingMode) {
                state.showObj[obj.elementCode] = {
                  required: obj.required,
                  elementParentId: parentId || '',
                  elementId: obj.id,
                  healthType: obj.healthType,
                  moduleType: eleList[i].moduleType,
                  [obj.elementCode]: null,
                  valueToChildCode: []
                }
                obj.elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                  if (item2.elementList && item2.elementList.length > 0) {
                    state.showObj[obj.elementCode]['valueToChildCode'].push({
                      value: item2['value'],
                      elementList: item2.elementList
                    })
                  }
                  InitializeFun(item2.elementList, eleList[i].moduleType, obj.id)
                })
              }
            })
          })
        } else if (eleList[i].elementOperatingMode &&
          (eleList[i].elementOperatingMode.mode_health.conSpanList.length > 0 ||
            (eleList[i].elementOperatingMode.mode_health.healthInputConfig &&
            eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray ?
              (eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) :
              false))) {
          state.showObj[eleList[i].elementCode] = {
            required: eleList[i].required,
            healthType: eleList[i],
            elementParentId: parentId || '',
            elementId: eleList[i].id,
            moduleType: moduleType,
            [eleList[i].elementCode]: null,
            valueToChildCode: []
          }
          eleList[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
            if (item2.elementList && item2.elementList.length > 0) {
              state.showObj[eleList[i].elementCode]['valueToChildCode'].push({
                value: item2['value'],
                elementList: item2.elementList
              })
            }
            InitializeFun(item2.elementList, moduleType, eleList[i].id)
          })
        }
      }
    }

    // 处理带表达式的告知项
    const getNewShowData = (expressionValue) =>{
      // expressionValue = 'insureds: age < 3,applicant: age < 18 '
      const applicantList = state.parametersDataList.filter(item => item.moduleType === 'applicant')[0].productElementList
      const insuredsList = state.parametersDataList.filter(item => item.moduleType === 'insureds')[0].productElementList
      // 以逗号分割
      let arr = expressionValue.split(',')
      if(arr.length){
        for (let i = 0; i < arr.length; i++) {
          let idNo = '', age = '', sex = ''
          let type = arr[i].split(':')[0].trim()
          if(type === 'insureds'){
            for (let j = 0; j < insuredsList.length; j++) {
              const relationToAppnt = insuredsList[j].filter(ele => ele.elementCode === 'relationToAppnt')[0].firstValue
              if(relationToAppnt === '01'){
                idNo = applicantList[0].filter(ele => ele.elementCode === 'appntIdNo')[0].firstValue
                age = getDetailAge(idNo.substr(6, 4) + '-' + idNo.substr(10, 2) + '-' + idNo.substr(12, 2))
                sex = parseInt(idNo.substr(16, 1)) % 2 == 1 ? '男' : '女'
              }else{
                sex = insuredsList[j].filter(ele => ele.elementCode === 'sex')[0].firstValue === '1' ? '男' : '女'
                age = getDetailAge(insuredsList[j].filter(ele => ele.elementCode === 'birthday')[0].firstValue)
              }
              if(!eval(arr[i].split(':')[1].trim())){
                console.debug(age, sex)
                return false
              }
            }
          }else if(type === 'applicant'){
            idNo = applicantList[0].filter(ele => ele.elementCode === 'appntIdNo')[0].firstValue
            age = getDetailAge(idNo.substr(6, 4) + '-' + idNo.substr(10, 2) + '-' + idNo.substr(12, 2))
            sex = parseInt(idNo.substr(16, 1)) % 2 == 1 ? '男' : '女'
            if(!eval(arr[i].split(':')[1].trim())){
              console.debug(age, sex)
              return false
            }
          }
        }
        return true
      }



    }
    const getDetailAge = (d) => {
      return getAge(d.split('-'), store.getters['insured/getTNUMBER'] ? Number(store.getters['insured/getTNUMBER']) : undefined).age[0]
    }

    //初始化加校验处理数据
    const refactoringFun = function (Arr = []) {
      // insureds 被保人   applicant 投保人
      for (let i = Arr.length - 1; i >= 0; i--) {
        if (Arr[i].productElementList) {
          Arr[i].productElementList.forEach(item => {
            for (let j = item.length - 1; j >= 0; j--) {
              let expressionValue = item[j].expressionValue
              if (expressionValue) {
                let type = getNewShowData(expressionValue)
                //  替换完成做校验
                if (!type) {
                  item.splice(j, 1)
                } else {
                  item[j].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                    refactoringFun(item2.elementList)
                  })
                }
              } else {
                item[j].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                  refactoringFun(item2.elementList)
                })
              }
            }
          })
        } else {
          //这里开始第二层的循环，或者多层的循环
          if (Arr[i].elementOperatingMode && (Arr[i].elementOperatingMode.mode_health.conSpanList.length > 0 || (Arr[i].elementOperatingMode.mode_health.healthInputConfig && Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray ? (Arr[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) : false))) {
            let expressionValue = Arr[i].expressionValue
            if (expressionValue) {
              let type = getNewShowData(expressionValue)
              //  替换完成做校验
              if (!type) {
                Arr.splice(i, 1)
              } else {
                Arr[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                  refactoringFun(item2.elementList)
                })
              }
            } else {
              Arr[i].elementOperatingMode.mode_health.conSpanList.forEach(item2 => {
                refactoringFun(item2.elementList)
              })
            }
          }
        }
      }
    }

    const cleanChildrenCode = (eleList = [], saveObj = {}) => {
      for (let i = 0; i < eleList.length; i++) {
        if (eleList[i].elementOperatingMode &&
          (eleList[i].elementOperatingMode.mode_health.conSpanList.length > 0 ||
            (eleList[i].elementOperatingMode.mode_health.healthInputConfig &&
            eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray ?
              (eleList[i].elementOperatingMode.mode_health.healthInputConfig.strArray.length > 0 ? true : false) :
              false))) {
          if(saveObj[eleList[i].elementCode]){
            saveObj[eleList[i].elementCode]['valueToChildCode'].forEach(item => {
              cleanChildrenCode(item.elementList, saveObj)
              item.elementList.forEach(element => delete saveObj[element.elementCode])
            })
          }

        }
      }
    }
    const nextPage = function () {
      if(state.configList && state.configList.length > 0) {
        const list = state.configList.filter(item => item.modelValue === '3')
        if (!list || !list.length) return
        let data = {
          tradeNo: state.tradeNo,
          intelligenceHBInsureds: []
        }
        if (state.moduleType === 'insuranceInfo') {
          const arr = getInsureds()
          arr.forEach(item =>{
            data.intelligenceHBInsureds.push({certiNo: item.certiNo})
          })
        } else if (state.moduleType === 'insureds') {
          const insuredsList = state.parametersDataList.filter(item => item.moduleType === state.moduleType)[0].productElementList[Number(state.moduleIndex)]
          const relationToAppnt = insuredsList.filter(item => item.elementCode === 'relationToAppnt')
          if (relationToAppnt.length) {
            if (relationToAppnt[0].firstValue === '01') {
              const applicantist = state.parametersDataList.filter(item => item.moduleType === 'applicant')[0].productElementList[0]
              data.intelligenceHBInsureds.push({certiNo: applicantist.filter(item => item.elementCode === 'appntIdNo')[0].firstValue})
            } else {
              data.intelligenceHBInsureds.push({certiNo: insuredsList.filter(item => item.elementCode === 'idNo')[0].firstValue})
            }
          }
        }
        request(getExtendUri('clearHistoryInfo'), data).then(res =>{
          if(res.code === 1){
            handelRouterJump()
          }
        })
      }else{
        handelRouterJump()
      }
    }
    // 监视下一页的
    watch(()=>state.tohandelHealthData, (newValue)=>{
      if(newValue) {
        state.loadingShow = true
        state.disabledShow = true
        getCacheHealthToldData()
      }
    },{immediate: true})
    watch(()=>state.typeJump, (newValue)=>{
      if (!newValue) {
        router.push({
          path:'/app/informationInput',
          query:{
            id:JSON.parse(store.getters['insured/getRouterJump']).processId,
            sort:JSON.parse(store.getters['insured/getRouterJump']).sort,
            tradeNo: state.tradeNo,
            type: '1',
            code: 1,
            moduleType: state.moduleType,
            moduleIndex: state.moduleIndex
          }
        })
      }
    })
    const  handelRouterJump =  () =>{
      let nextObj = {}
      for (let key in state.showObj) {
        let elementParentId = state.showObj[key].elementParentId
        let first = !state.showObj[key].elementParentId ? true : false
        if (first) {
          //first为true,一级选项,直接添加
          nextObj[key] = state.showObj[key]
        } else {
          //二级选线需判断是否选中的是当前二级数据的一级数据
          for (let keyA in state.showObj) {
            let elementId = state.showObj[keyA].elementId
            if (elementId === elementParentId) {
              //如果父节点有值，说明存在
              if (state.showObj[keyA][keyA]) {
                nextObj[key] = state.showObj[key]
              }
            }
          }
        }
      }
      for (let key in nextObj) {
        if (nextObj[key]) {
          if (nextObj[key][key] === null &&nextObj[key].required==='1') {
            // TODO 空值校验
            state.isIdentical = true
            state.tohandelHealthData = false
            state.detailModal = '请检查录入选项,有漏填'
            return false
          } else if(Array.isArray(nextObj[key][key]) && nextObj[key].required==='1'){
            for(let i = 0; i < nextObj[key][key].length; i++){
              if(nextObj[key][key][i].type === 'input' && !nextObj[key][key][i].value && nextObj[key][key][i].value !== '0'){
                state.isIdentical = true
                state.tohandelHealthData = false
                state.detailModal = '请输入' + nextObj[key].titleStr
                return false
              }
            }
          }else{
            // 删除不需要记录的问题
            if(nextObj[key]['valueToChildCode']){
              nextObj[key]['valueToChildCode'].filter(item => item.value !== nextObj[key][key]).forEach(item => {
                cleanChildrenCode(item.elementList, nextObj)
                item.elementList.forEach(element => delete nextObj[element.elementCode])
              })
            }

          }
        }
      }
      //将组建返回数据组合成接口传参
      let arr = []
      for (let key in nextObj) {
        arr.push({
          moduleId: nextObj[key].moduleType,
          elementId: nextObj[key].elementId,
          submitKey: key,
          submitValueObj: nextObj[key][key],
          elementParentId: nextObj[key].elementParentId || '',
        })
      }
      if (state.moduleType === 'insureds'){
        getInsureds()
      }
      let submitData = {
        // 'productId': sessionStorage.getItem('productId'),
        'productId': store.getters['insured/getProductId'],
        'tradeNo': state.tradeNo,
        'sortCode': routerData.code,
        'sort': state.healthSort,
        'submitRecordList': arr,
        'associationType': state.associationType,
        'idNo': state.idNo
      }
      state.loading = true
      state.tohandelHealthData = false
      request(getExtendUri('record'), submitData).then(res => {
        state.loading = false
        if (res.code === 1) {
          state.disabledShow = false
          state.tohandelHealthData = true
          handelSaveHealth()
        } else {
          message['error'](res.message)
          state.tohandelHealthData = false
        }
      })

    }

    // 下一步缓存健康告知数据
    const handelSaveHealth = () => {
      state.showObj = {}
      request(getExtendUri('savecacheHealthTold'),
        {
          tradeNo: state.tradeNo,
          pageNum: state.pageNum,
          applicantIdNo: state.applicantIdNo,
          insuredsIdNo: state.insuredsIdNo,
          saveData: {showData: state.showData, sort: state.sort, associationType: state.associationType}})
    }
    // 获取缓存数据
    const getCacheHealthToldData = () =>{
      state.loadingShow = true
      state.pageNum++
      request(getExtendUri('getCacheHealthTold'), {
        tradeNo: state.tradeNo,
        pageNum: state.pageNum,
        applicantIdNo: state.applicantIdNo,
        insuredsIdNo: state.insuredsIdNo,
      }).then(res => {
        if(!res.result || JSON.stringify(res.result.saveData) === '{}'){
          handelHealthData()
        }else{
          refactoringFun(res.result.saveData.showData)
          state.associationType = res.result.saveData.associationType
          state.loadingShow = false
          state.tohandelHealthData = false
          state.showData = res.result.saveData.showData
          // store.dispatch('insured/saveHealthShowData', state.showData)
          store.dispatch('insured/saveRelationToappnt', state.relationToappnt)
          // state.showDataCopy = JSON.parse(JSON.stringify(res.result.saveData.showData))
        }
      })
    }





    onUnmounted(() => {
    })
    return {
      handelRouterJump,
      insuranceErrorHandleOk,
      ...toRefs(state),
      nextPage,
      PreviewPopData,
      allNo,
      handleOk,
      modalClose,
      handelHealthData,
      getIntelligentUnderwriting,
      getType,
      getInsureds,
      allNoFun,
      getWinLocation,
      handelSaveHealth,
      getCacheHealthToldData,
      cleanChildrenCode,
      getShowDataTemporary,
      refactoringFun,
      saveHealthToldIdentifying
    }
  },
})
</script>

