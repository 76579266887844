<template>
  <div>
    <form :action="url" method="get" ref="payform" style="display:none"   accept-charset="GBK">
      <label>请求数据:</label><textarea name="requestDoc" ref="requestDoc" placeholder="请求数据"  ></textarea><br/>
    </form>
    <div class="example">
      <a-spin size="large" :delay="200" spinning/>
    </div>
<!--    测试用-->
<!--    <form :action="rUrl" method="post" ref="payform" accept-charset="GBK">-->
<!--      <label>请求数据:</label><textarea name="requestDoc" ref="requestDoc" placeholder="请求数据"  ></textarea><br/>-->
<!--    </form>-->
<!--    <a-button @click="onclick" >提交</a-button>-->

  </div>
</template>

<script>
import {watch, reactive, toRefs, ref, nextTick, onUnmounted} from 'vue'
import {message} from 'ant-design-vue'
import { isOnlyPayUrl} from '../../../utils/request_extend'
// import {request} from '../../../utils/request'
// import {request} from '../../../utils/request'
// import store from '../../../store'
// import * as iconv from 'iconv-lite'
// import {request} from '../../../utils/request'
// import axios from 'axios'

export default {
  name: 'ShowPayForm',
  props: {
    payJson: {
      type: Object,
      default: () => {
      }
    },
    url: {
      type: String,
      // default: () => 'https://uatpay.axa.cn/onlyPay.do'
      default: () => ''
    },
    gKey: {
      type: String,
      default: () => 'requestDoc'
    },
    tradeNo: {
      type: String
    },
    supplierId: {
      type: String
    },
    isShow:{
      type: Boolean,
      default:()=> false
    }
  },
  setup(props) {
    const requestDoc = ref()
    const payform = ref()
    const state = reactive({
      loadingShow: props.isShow,
      timeId: undefined,
      JsonData: props.payJson,
      rUrl: props.url,
      mkey: props.gKey,
      payObj: {
        header: [],
        requestBody: [],
        sign: []
      },
      formBody: {}
    })
    // const initNewData = (Header) => {
    //
    // }
    /**
     * 测试用
     */
    const onclick = () => {
      nextTick(()=> {
        requestDoc.value.value='<?xml version=\'1.0\' encoding=\'GBK\' standalone=\'yes\'?><Package><Header><RequestType>102</RequestType><SendTime>2023-03-28 10:23:34</SendTime><SystemNo>000</SystemNo></Header><Request><DepartmentCode>5</DepartmentCode><IsPolicy>1</IsPolicy><NotifyUrl>http://82.156.34.193/product/backend/edt-service/edt/externalCallback/anShengPaycallback</NotifyUrl><PageUrl>http://82.156.34.193/product/app/payStatus?tradeNo=HB2023kshjup0000000974&amp;shareId=f0612d888d434c8daf9415bf7a676cc7</PageUrl><PayAmount>167.00</PayAmount><PayTimeout></PayTimeout><PayType>7</PayType><TradeList><TradeIfo><ApplyPolicyNo>1000H09000015840480</ApplyPolicyNo><BatchPolicyNo></BatchPolicyNo><InsuranceBeginTime>2023-03-10 00:00:00</InsuranceBeginTime><PersonnelName>方法</PersonnelName><PlanCode>H09</PlanCode><ProductId>1000H09000015840480</ProductId><ProductName>卓越守护百万住院医疗保险高血压患者专款</ProductName><ProductType>2</ProductType><TradeAmt>167.00</TradeAmt><ViewProductUrl></ViewProductUrl></TradeIfo></TradeList><TransSourceCode>296</TransSourceCode></Request><Sign>AVXjBeLfwqSE+Ug1nMzOtlzqpqBavd4Fd6DQWXP/+gMxYs2slVPTaolszTpTUj+ySBRp9/SH5FM6fKpa1XhSJrO73hQ9RHWp93BYerv9YcYbGjM4hytNc+dmB/D046TTdWtIhrCuM7NFKyeXGYXkG8A5mAWMzp17knNj+xt42gw=</Sign></Package>'
        payform.value.submit()
      })
    }
    /**
     * 初始化数据去渲染form
     */
    const initDate = () => {
        if (isOnlyPayUrl(props.supplierId)) {
          if (state.rUrl) {
          nextTick(()=> {
            window.location.href = state.rUrl
            // payform.value.submit()
          })
        }
      } else {
        if (state.JsonData) {
          requestF2(state.rUrl)
        } else {
          message.warn('异常')
        }
      }

      // if (state.JsonData.Package) {
      //   if (state.JsonData.Package.Header) {
      //     state.payObj.header = []
      //     initNewData(state.JsonData.Package.Header)
      //     getKeyAndVal(state.JsonData.Package.Header, state.payObj.header)
      //   }
      //   if (state.JsonData.Package.Request) {
      //     state.formBody = state.JsonData.Package.Request[0]
      //     state.payObj.requestBody = []
      //     getKeyAndVal(state.formBody, state.payObj.requestBody)
      //     requestF(state.rUrl, state.JsonData.Package.Request)
      //   }
      //   if (state.JsonData.Package.Sign) {
      //     state.payObj.sign = []
      //     getKeyAndVal(state.JsonData.Package.Sign, state.payObj.sign)
      //   }
      // } else {
      //   message.warn('异常')
      // }
    }
    /**
     * 转json-》表单格式
     * @param params
     * @returns {FormData}
     */
    // const formdataify = (params) => {
    //   // const formDataG = new FormData()
    //   const formData = new FormData()
    //   Object.keys(params).forEach(key => {
    //     if (typeof params[key] === 'string') {
    //       formData.append(key, params[key])
    //     } else {
    //       formData.append(key, JSON.stringify(params[key]))
    //     }
    //   })
    //   // formDataG.append(state.mkey, formData)
    //   return formData
    // }
    /**
     * 网络请求
     * @param url
     */
    // const requestF = (url, obj) => {
    //   if (url) {
    //     axios({
    //       method: 'post',
    //       url: url,
    //       data: obj[0],
    //       transformRequest: [
    //         function (data) {
    //           // 将请求数据转换成功 formdata 接收格式
    //
    //           return formdataify(data)
    //         }
    //       ],
    //       headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //         'Access-Control-Allow-Origin': '*'
    //       }
    //     }).then(res => {
    //       console.log(JSON.stringify(res))
    //     })
    //   }
    // }
    //   const getDateByGbk = (str) => {
    //     str = '<?xml version=\'1.0\' encoding=\'GBK\' standalone=\'yes\'?><Package><Header><RequestType>102</RequestType><SendTime>2023-03-28 10:23:34</SendTime><SystemNo>000</SystemNo></Header><Request><DepartmentCode>5</DepartmentCode><IsPolicy>1</IsPolicy><NotifyUrl>http://82.156.34.193/product/backend/edt-service/edt/externalCallback/anShengPaycallback</NotifyUrl><PageUrl>http://82.156.34.193/product/app/payStatus?tradeNo=HB2023kshjup0000000974&shareId=f0612d888d434c8daf9415bf7a676cc7</PageUrl><PayAmount>167.00</PayAmount><PayTimeout></PayTimeout><PayType>7</PayType><TradeList><TradeIfo><ApplyPolicyNo>1000H09000015840480</ApplyPolicyNo><BatchPolicyNo></BatchPolicyNo><InsuranceBeginTime>2023-03-10 00:00:00</InsuranceBeginTime><PersonnelName>方法</PersonnelName><PlanCode>H09</PlanCode><ProductId>1000H09000015840480</ProductId><ProductName>卓越守护百万住院医疗保险高血压患者专款</ProductName><ProductType>2</ProductType><TradeAmt>167.00</TradeAmt><ViewProductUrl></ViewProductUrl></TradeIfo></TradeList><TransSourceCode>296</TransSourceCode></Request><Sign>AVXjBeLfwqSE+Ug1nMzOtlzqpqBavd4Fd6DQWXP/+gMxYs2slVPTaolszTpTUj+ySBRp9/SH5FM6fKpa1XhSJrO73hQ9RHWp93BYerv9YcYbGjM4hytNc+dmB/D046TTdWtIhrCuM7NFKyeXGYXkG8A5mAWMzp17knNj+xt42gw=</Sign></Package>'
    //     const gbkText = iconv.encode(str, 'gbk')
    //     console.log('GBK__:', gbkText.toString('gbk'))// 输出 GBK 编码的字符串
    //     return gbkText.toString('gbk')
    //   }
    const requestF2 = (url) => {
      if (url) {
        nextTick(()=> {
          requestDoc.value.value=state.JsonData
          payform.value.submit()
        })
      }
    }
    const getKeyAndVal = (Obj, array) => {
      for (const key in Obj) {
        console.log('key', key)
        console.log('Value', Obj[key])
        array.push({
          label: key,
          value: getData(Obj[key])
        })
      }
    }

    const getData = (obj) => {
      if (typeof (obj) === 'object' && Object.prototype.toString.call(obj).toLowerCase() === '[object object]' && !obj.length) {
        const array = []
        return getKeyAndVal(obj, array)
      }
      if (Array.isArray(obj) && obj.length === 1 && typeof (obj[0]) != 'object') {
        console.log(obj[0])
        if (obj[0]) {
          return obj[0]
        } else {
          return undefined
        }
      }
      return obj
    }
    // onMounted(()=> {
    //   // HB2023kshjup0000000974
    //   // request(getExtendUri('confirmCharge'), {
    //   request('http://h3fs2f.natappfree.cc/trade/insuredPayData/api/paymentInterface', {
    //     baseInfo: {
    //       tradeNo: 'HB2023kshjup0000000974',
    //       shareId: 'f0612d888d434c8daf9415bf7a676cc7'
    //     }
    //   }).then(res => {
    //     state.loadingShow = false
    //     if (res.code === 1) {
    //       const xmlData = state.payObj.payStateResult = res.result.value || ''
    //       // state.payObj.payJson = xmlData
    //       // state.payObj.payUrl = res.result.url
    //       // state.payObj.payKey = res.result.key
    //       console.log('xmlData', xmlData)
    //       console.log('res.result.url', res.result.url)
    //       console.log('res.result.key', res.result.key)
    //       requestDoc.value.value=xmlData
    //       state.rUrl = res.result.url
    //
    //     }
    //   })
    // })
    watch(props.payJson, () => {
      initDate()
    }, {immediate: true})
    onUnmounted(()=>{
    })
    return {
      ...toRefs(state),
      requestDoc,
      payform,
      onclick,
      initDate
    }
  }
}
</script>

<style scoped>

.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}


</style>
