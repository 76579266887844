const dictionary = {
  namespaced: true,
  state: () => ({
    dictionary: {}
  }),
  mutations: {
    setCache(state, payload) {
      state.dictionary[payload.type] = payload.data
    },
    clear(state) {
      state.dictionary = {}
    }
  },
  actions: {
    clear({commit}) {
      commit('clear')
    }
  },
  getters: {
    getValue: (state) => (type, code) => {
      let data = state.dictionary[type]
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].value === code) {
            return data[i].label
          }
        }
      }
      return ''
    },
    getData: (state) => (type) => {
      return state.dictionary[type]
    }
  }
}
export default dictionary
