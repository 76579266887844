let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
const setupWebViewJavascriptBridge = (callback) => {
  if (isiOS) {
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge)
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback)
    }
    window.WVJBCallbacks = [callback]
    const WVJBIframe = document.createElement('iframe')
    WVJBIframe.style.display = 'none'
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
    document.documentElement.appendChild(WVJBIframe)
    setTimeout(() => {
      document.documentElement.removeChild(WVJBIframe)
    }, 0)
  }
  if (isAndroid) {
    if (window.WebViewJavascriptBridge) {
      callback(window.WebViewJavascriptBridge)
    } else {
      document.addEventListener('WebViewJavascriptBridgeReady', () => {
        callback(window.WebViewJavascriptBridge)
      }, false)
    }
  }
}
setupWebViewJavascriptBridge(() => {
})

const callHandler = (handlerName, params, callback) => {
  if (typeof params === 'function') {
    callback = params
    params = null
  }
  if (isAndroid) {
    // 安卓返回json字符串
    let jsonString = ''
    let json
    if (params) {
      if (typeof params === 'object') {
        params = JSON.stringify(params)
      }
      jsonString = window.locJs[handlerName](params)
    } else {
      jsonString = window.locJs[handlerName]()
    }
    if (jsonString && jsonString.indexOf('{') == 0) {
      json = JSON.parse(jsonString)
    }
    callback && callback(json)
  } else if (isiOS) {
    // alert(typeof WebViewJavascriptBridge)
    if (window.locJs) {
      if (typeof params === 'object') {
        params = JSON.stringify(params)
      }
      return callback(window.locJs[handlerName](params))
    }
    //产品分享涉及3方分享方式，需要对象类型（微信share不可用）
    if (typeof params == 'string') {
      params = JSON.parse(params)
    }
    return window.WebViewJavascriptBridge.callHandler(handlerName, params, callback)
  }
}
export {
  callHandler
}
