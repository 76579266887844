<style scoped></style>

<template>
<h3>产品详情页</h3>
  <div class="confirmBottom">
    <div @click="nextPage">下一步</div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref, toRefs} from 'vue'
import {request} from '../../utils/request.js'
import {getExtendUri} from '../../utils/request_extend.js'
import {message} from 'ant-design-vue'
import {routerJump,nowPathShow} from '../../utils/product_process'
import {useRouter} from 'vue-router'
import store from '../../store'

export default defineComponent({
  setup() {
    nowPathShow()
    let router = useRouter()
    let productId = router.currentRoute.value.query.id
    // localStorage.setItem('productId',productId)
store.dispatch('insured/changeInsureJSInfo',{
  productId
})
    const userDrawerRef = ref()
    console.log(userDrawerRef)
    const state = reactive({
      name:''
    })
    const processDetailsFun = function(){
      request(getExtendUri('processDetails'), {id:productId}).then(res => {
        if (res.code === 1) {
          console.log(res)
          // localStorage.setItem('routerJump',JSON.stringify(res.result.processDictList))
          store.dispatch('insured/changeInsureJSInfo',{
            routerJump: JSON.stringify(res.result.processDictList)
          })
        } else {
          message['error'](res.message)
        }
      })
    }
    processDetailsFun()
   const  postSelectByIdFun = function(){
     request(getExtendUri('postSelectById'), {data:{id:productId}}).then(res => {
       if (res.code === 1) {
         // localStorage.setItem('productDetailData',JSON.stringify(res.result))
         store.dispatch('insured/changeInsureJSInfo',{
           productDetailData: JSON.stringify(res.result)
         })
       } else {
         message['error'](res.message)
       }
     })
   }
    postSelectByIdFun()

    const nextPage = function () {
      routerJump()
    }
    return {
      ...toRefs(state),
     nextPage,
    }
  },
})
</script>
