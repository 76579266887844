<template>
    <div class="detail_class">
      <div class="header">
        <IOSbackBar :title="'智能核保'" ></IOSbackBar>
        <p>请选择被保人过往或现有的病症（可多选）</p>
        <van-search
          v-model="searchValue"
          background="#ffffff"
          placeholder="搜索预评估的疾病"
          @click="handelToSearch"
        />
      </div>
      <div class="content">
        <!--      <div class="top">-->
        <!--        <p class="top_title">常规疾病</p>-->
        <!--        <div class="top_detail" >-->
        <!--          <span v-for="item in commonList" @click="handelClickCommon(item)" :key="item.id" class="top_detail_span">{{ item.content }}</span>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--      列表-->
        <!--      <div class="content_list">-->
        <!--        <div class="content_list_detail" v-for="item in contextList" :key="item.id" @click="getPathogen(item)">-->
        <!--          <p>{{item.diseaseContent}}</p>-->
        <!--          <van-icon name="arrow-down" />-->
        <!--        </div>-->
        <!--      </div>-->
        <!-- 疾病大全  -->
        <van-collapse :border="false" accordion v-model="activeName" @change="disChange" class="disCollapse">
          <van-collapse-item v-for="(item, index) in contextList" :key="index" :title="item.diseaseName" :name="index">
<!--            <van-cell-group class="disCon" v-if="item.diseaseName === '其他疾病'">-->
<!--              <van-field-->
<!--                v-model="message"-->
<!--                rows="6"-->
<!--                autosize-->
<!--                label=""-->
<!--                type="textarea"-->
<!--                placeholder="请详细说明您的疾病名称、发病时间、治疗方法、治疗医院、最后治疗时间、现在情况..."-->
<!--              />-->
<!--              <van-button type="primary" @click="clickOk">确定</van-button>-->
<!--            </van-cell-group>-->
            <van-cell-group class="disCon">
              <van-cell v-for="(itemSon, indexSon) in item.children" :key="indexSon" clickable
                        @click="getPathogen(itemSon)"> {{ itemSon.diseaseName }}
              </van-cell>
            </van-cell-group>
          </van-collapse-item>
        </van-collapse>

      </div>
      <footer class="footer" v-if="selectList.length > 0">
        <div class="footer_display">
          <div class="footer-left">
            <van-button type="primary" @click="()=> detailShow = true">已选中<span
              v-if="selectList.length >0">({{ selectList.length }})</span></van-button>
          </div>
          <div class="footer-right">
            <van-button type="primary" @click="handelOK">确定</van-button>
          </div>
        </div>
      </footer>
      <!--    病源详情-->
      <div v-show="showBottom">
        <van-popup
          v-model:show="showBottom"
          round
          position="bottom"
          @close="onPopupClose"
          :style="{ maxHeight: '75%', minHeight: '30%' }"
        >
          <template #default>
            <div class="popup_class">
              <div class="popup_title" @click="()=> showBottom = false">
                <div class="title">
                  <span>&nbsp;</span>
                  <p>{{ clickData.diseaseName }}</p>
                </div>
                <van-icon name="arrow-down"/>
              </div>
              <div class="popup_content">
                <van-cell-group inset>
                  <div v-for="(child, index) in clickData.children" :key="child.id" @click="onCheck(child, index)">
                    <van-cell :title="child.diseaseName" clickable>
                      <template #right-icon>
                        <van-checkbox v-model:checked="child.type" @click.stop="onCheck(child, index)" :name="child.id"/>
                      </template>
                    </van-cell>
                  </div>
                </van-cell-group>
              </div>
              <div class="popup_footer">
                <div class="popup_footer_select">
                  <van-tag v-for="(item, index) in selectList" :key="item.id" color="#f7f7f9" text-color="#333" closeable
                           size="large" type="primary" @close="delSelect(item, index)">
                    {{ item.diseaseName }}
                  </van-tag>
                </div>
                <div class="foo-right">
                  <van-button type="primary" @click="saveDiseaseContent">添加<span
                    v-if="selectList.length > 0">({{ selectList.length }})</span></van-button>
                </div>
              </div>
            </div>
          </template>
        </van-popup>
      </div>
      <!--    已选中详情-->
      <div>
        <van-popup
          v-model:show="detailShow"
          position="bottom"
          round
          :style="{ height: '45%' }"
        >
          <div class="select_detail">
          <span v-for="(item, index) in selectList" :key="item.id" class="top_select_span">
            <span class="to_span">{{ item.diseaseName }}</span>
            <span class="to_span"><van-icon name="cross" @click="delSelect('', index)"/></span>
          </span>
          </div>
        </van-popup>
      </div>
      <!--    确定的时候-->
      <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isIdentical">
        <div class="modal_title">{{titleTip}}</div>
        <p class="modal" :class="{'modal-Y' : offlineUnderwriting === 'Y'}">{{ detailModal }}</p>
        <template #footer>
          <a-button type="link"
                    style="width: 40%;font-size: 16px;text-align: center !important; color: #333;"
                    @click="continueAdd">{{leftButton}}
          </a-button>
          <a-button type="link"
                    style="width: 50%;font-size: 16px;text-align: center !important; color: #1677FF;"
                    @click="handelSubmit">{{rightButton}}
          </a-button>
        </template>
      </a-modal>
    </div>

</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, nextTick} from 'vue'
import {Search, Tag, Button, Icon, Popup, Cell, CellGroup, Checkbox, CheckboxGroup, Collapse, CollapseItem, Field} from 'vant'
import router from '@/router'
import {getExtendUri} from '@/utils/request_extend'
import {request} from '@/utils/request'
import store from '@/store'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'
import {routerJump} from '@/utils/product_process'
// import store from '@/store'
export default defineComponent({
  components: {
    IOSbackBar,
    [Search.name]: Search,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Field.name]: Field,
  },
  setup() {
    const state = reactive({
      searchValue: '',
      tagShow: true,
      showBottom: false,
      detailShow: false,
      isIdentical: false,
      selectList: [],
      titleTip: '已选中疾病确认',
      detailModal: '',
      rightButton: '确认',
      leftButton: '继续添加',
      contextList: [],
      supperCode: JSON.parse(store.getters['insured/getProductDetailData']).productInfo.supplierCode,
      tradeNo: router.currentRoute.value.query['tradeNo'],
      commonList: [],
      clickData: {},
      checked: undefined,
      processSelectList: [],
      activeName: '',
      message: '',
      offlineUnderwriting: 'N'
    })

    onMounted(() => {
      // 获取一下缓存
      state.selectList = sessionStorage.getItem('processSelectList') ? JSON.parse(sessionStorage.getItem('processSelectList')) : []
      document.title = '智能核保'
      handelInitMethod()
      getSelAllAndCommon()

    })
    const disChange = (e) => {
      state.activeName = e
    }
    // 点击选择详情数据
    const getPathogen = (data) => {
      state.showBottom = true
      state.clickData = JSON.parse(JSON.stringify(data))
      nextTick(() => {
        if (state.selectList.length) {
          state.clickData.children.forEach(item => {
            let findIndex = state.selectList.findIndex(sel => sel.id === item.id)
            if (findIndex !== -1) item.type = true
          })
        }
      })
    }
    //
    const onPopupClose = () => {
      state.clickData = []
      state.showBottom = false
    }


    // 删除选中的
    const delSelect = (data, value) => {
      if (data) {
        state.clickData.children.forEach(child => {
          if (child.id === data.id) {
            child.type = false
          }
        })
      }
      state.selectList.splice(value, 1)
    }

    // 弹出层选择的
    const onCheck = (data) => {
      state.clickData.children.forEach(child => {
        if (child.id === data.id) {
          child.type = !child.type
        }
      })
      let findIndex = state.selectList.findIndex(item => item.id === data.id)
      if (findIndex !== -1) {
        state.selectList.splice(findIndex, 1)
      } else {
        state.selectList.push(state.clickData.children.filter(child => child.id === data.id)[0])
      }
    }
    // 添加
    const saveDiseaseContent = () => {
      onPopupClose()
    }
    // 确认
    const handelOK = () => {
      if (!state.selectList.length) return
      state.isIdentical = true
      state.detailModal = ''
      let arr = []
      if (state.selectList.length) {
        state.selectList.forEach(item => {
          arr.push('“' + item.diseaseName + '”')
        })
        let str = arr.join('、')
        state.detailModal = `您已选择${str}共${state.selectList.length}个疾病`
      }
    }
    // 确认提交
    const handelSubmit = () => {
      if(state.offlineUnderwriting === 'Y'){
        request(getExtendUri('onSavePerson'), {
          tradeNo: router.currentRoute.value.query.tradeNo,
          uwStatus: '1',
          remark: '',
          imageList: []
        })
        routerJump(router.currentRoute.value.query.tradeNo, 'health')
        return
      }
      const list = state.selectList.filter(item => item.diseaseType && item.diseaseType === 'Y')
      if(list.length){
        state.titleTip = ''
        state.detailModal = '是否确认转人核'
        state.leftButton = '取消'
        state.rightButton = '确认'
        state.offlineUnderwriting = 'Y'
      }else{
        router.push({
          path: '/app/underclusion',
          query: {
            ...router.currentRoute.value.query,
            selectList: JSON.stringify(state.selectList)
          }
        })
        sessionStorage.setItem('processSelectList', JSON.stringify(state.selectList))
        state.isIdentical = false

      }





      // let data = {
      //   tradeNo: state.tradeNo,
      //   supperCode: state.supperCode,
      //   associationType: router.currentRoute.value?.query?.associationType,
      //   idNo: '',
      //   answerData: []
      // }
      // state.selectList.forEach(item=>{
      //   data.answerData.push({
      //     diseaseCode: item.diseaseCode,
      //     answerId: ''
      //   })
      // })
      // let isShowType = '99'
      // let arr = state.selectList.filter(item => item.conclusion === '1')
      // let arr2 = state.selectList.filter(item => item.conclusion === '2')
      // let arr1 = state.selectList.filter(item => item.conclusion === '0')
      // if (arr &&arr.length === state.selectList.length){
      //   isShowType = '1'
      // }else if(arr1 && arr1.length){
      //   isShowType = '0'
      // }else if(arr2 &&arr2.length === state.selectList.length){
      //   isShowType = '2'
      // }
      // 删除选中的缓存
      // request(getExtendUri('delCacheParameters'),{tradeNo: state.tradeNo+ 'underwritingProcess'})
      // 为了后面的逻辑
      // request(getExtendUri('cacheParameters'), {tradeNo: state.tradeNo+ 'underwritNotification', saveData: {select: state.selectList}}).then(res =>{
      //   if(res.code === 1){
      //     router.push({
      //       path: '/app/underwritNotification',
      //       query: {
      //         ...router.currentRoute.value.query,
      //         isShowType
      //       }
      //     })
      //   }
      // })

    }
    // 初始化
    const handelInitMethod = () => {
      request(getExtendUri('initMethod'), {tradeNo: state.tradeNo})
    }
    // 继续添加  或者取消
    const continueAdd = () => {
      state.isIdentical = false
      state.offlineUnderwriting = 'N'
      state.titleTip = '已选中疾病确认'
      state.rightButton =  '确认'
      state.leftButton = '继续添加'
    }
    // 点击了查询的
    const handelToSearch = () => {
      request(getExtendUri('cacheParameters'), {
        seconds: 86400, 
        tradeNo: state.tradeNo + 'underwritingProcess',
        saveData: {select: state.selectList}
      }).then(res => {
        if (res.code === 1) {
          sessionStorage.setItem('processSelectList', JSON.stringify(state.selectList))
          router.push({path: '/app/underwritingSearch', query: {...router.currentRoute.value.query}})
        }
      })
    }
    // 点击常规疾病
    const handelClickCommon = (data) => {
      request(getExtendUri('cacheParameters'), {
        seconds: 86400, 
        tradeNo: state.tradeNo + 'underwritingProcess',
        saveData: {select: state.selectList}
      }).then(res => {
        if (res.code === 1) {
          router.push({
            path: '/app/underwritingSearch',
            query: {diseaseValue: data.content, ...router.currentRoute.value.query}
          })
        }
      })
    }

    // 查询数据
    const getSelAllAndCommon = () => {
      request('/product-service/product/disease/api/getDiseaseList?riskCode=' + JSON.parse(store.getters['insured/getRiskList']).riskList[0].riskCode, null, false, 'get').then(res => {
        if (res.code === 1) {
          // state.commonList = res.result
          res.result.forEach(item => {
            item.children.forEach(child => {
              child.type = false
            })
          })
          state.contextList = res.result



          // if(state.processSelectList && state.processSelectList.length){
          //   state.processSelectList.forEach(pro =>{
          //     let index = state.contextList.findIndex(con => con.id === pro.parentId )
          //     state.contextList[index].children.forEach(item =>{
          //       let findIndex = state.processSelectList.findIndex(sel => sel.id === item.id)
          //       if(findIndex !== -1){
          //         state.selectList.push(item)
          //       }
          //     })
          //   })
          // }
          // getCacheParameters()
        }
      })
    }

    // const getCacheParameters = () =>{
    //   request(getExtendUri('getCacheParameters'), {tradeNo: state.tradeNo+ 'underwritingProcess'}).then(res =>{
    //     if(res.code === 1 && res.result){
    //       res.result.saveData.select.forEach(pro =>{
    //         let index = state.selectList.findIndex(con => con.id === pro.id )
    //         if(index === -1){
    //           state.selectList.push(pro)
    //         }
    //       })
    //       console.log('缓存数据', state.selectList)
    //
    //     }
    //   })
    // }
    const clickOk = () => {
      // request('/product-service/product/disease/api/getDiseaseList?riskCode=' + JSON.parse(store.getters['insured/getRiskList']).riskList[0].riskCode, null, false, 'get').then(res => {
      //   if (res.code === 1) {
      //     console.log('我成功了')
      //   }
      // })
    }

    return {
      ...toRefs(state),
      getPathogen,
      delSelect,
      onCheck,
      // onClickCheckbox,
      handelSubmit,
      continueAdd,
      handelOK,
      saveDiseaseContent,
      handelToSearch,
      getSelAllAndCommon,
      handelInitMethod,
      handelClickCommon,
      onPopupClose,
      disChange,
      clickOk
    }
  }
})
</script>

<style scoped>
.detail_class {
  text-align: left;
  overflow-y: auto;
  height: 100vh;
}

.header {
  width: 100%;
  z-index: 2;
  padding: 20px 20px 0;
//margin-bottom: 10px; //background-color: #f3f3f3; position: fixed;
  top: 0;
  background: #FFFFFF;
}

.header /deep/ .van-search {
  padding: 0 !important;
  border-radius: 20px;
  border: 1px solid #1181f6;
}

.header /deep/ .van-search__content {
  background-color: #fff !important;
  border-radius: 24px;
}

.content {
  padding: 15px 20px;
  background-color: #fff;
  margin: 140px 0 80px;
}

.content .top_title {
  font-size: 16px;
  color: #cacaca;
}

.content .top_detail_span {
  display: inline-block;
  padding: 5px 15px;
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 20px;
  margin-right: 12px;
  margin-bottom: 15px;
}

.content .top_detail_span:first-child {
  margin-left: 0;
}

.content_list .content_list_detail p {
  margin: 0 !important;
}

.content_list .content_list_detail {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
//border-top: 1px solid #ececec;
}


.footer {
  background: #fff;
  bottom: -2px;
  left: 0;
  position: fixed;
  height: 64px;
  width: 100%;
  padding: 0 20px;
  line-height: 56px;
  z-index: 2;
//border-top: 1px solid #dfdfdf;
}

.footer .footer_display {
  height: 100%;
  /*position: relative;*/
  display: flex;
  justify-content: space-around;
}

.footer-right, .footer-left {
  height: 100%;
}

.footer-left /deep/ .van-button {
  min-width: 105px;
  padding: 0 20px !important;
  border-radius: 25px !important;
  background: #FFFFFF;
  color: #0a84ff;
}

.footer-right /deep/ .van-button {
  min-width: 105px;
  padding: 0 20px !important;
  border-radius: 25px !important;
}

.popup_class {
  min-height: 30%;
  max-height: 75%;
  position: relative;
  padding: 10px 20px;

}

.popup_title {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 3;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -20px;
  border-radius: 15px 15px 0 0;
  margin-top: -10px;
//box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
}

.popup_title .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.popup_title .title span {
    display: block;
    background: #0a84ff;
    width: 4px;
    height: 1rem;
    margin-right: 6px;
    border-radius: 15px;
}

.popup_title::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  -webkit-box-shadow: 0 0.05333rem 0.10667rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.05333rem 0.10667rem rgba(0, 0, 0, 0.05);
  z-index: 1;
}

.popup_title p {
  margin: 0;
  font-weight: bold;
}

.popup_content {
  margin: 40px 0 60px;
}

.popup_content /deep/ .van-cell-group--inset {
  margin: 0;
}

.popup_content /deep/ .van-cell {
  padding: 10px 0 !important;
}

.popup_footer {
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  background: #fff;
}

.popup_footer /deep/ .van-button {
  width: 90px;
  padding: 0 20px !important;
  border-radius: 25px !important;
}

.popup_footer_select {
  overflow-x: auto;
  white-space: nowrap;
  margin-right: 10px;
}

.popup_footer_select .van-tag {
  margin-right: 10px;
}

.select_detail {
  padding: 20px 0;
}

.select_detail .top_select_span {
  display: inline-block;
  padding: 0 15px;
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 20px;
  margin-left: 8px;
  margin-bottom: 15px;
  height: 45px;
}

.select_detail .top_select_span .to_span {
  display: inline-block;
  height: 100%;
  line-height: 45px;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modal_title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/deep/ .disCollapse {
  margin: 0 auto;
//width: 94%; max-height: 86%;
  border-radius: 12px;
  overflow: auto;
}

/deep/ .disCollapse .van-collapse {
  border: none;
  height: 60px !important;
}

/deep/ .disCollapse .van-collapse-item__title {
  padding: 11px 16px;
  font-size: 16px;
  font-weight: bold;
  color: #323232;
}

/deep/ .disCollapse .van-collapse-item__title .van-cell__left-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .disCollapse .disCon::after {
  border-width: 0;
}

/deep/ .disCollapse .disCon .van-cell {
  border: 0;
  padding: 14px 0;
}

/deep/ .disCollapse .disCon .van-cell .van-cell__value {
  font-size: 16px;
}

/deep/ .van-collapse-item {
  padding: 12px 0 !important;
}

/deep/ .van-collapse-item .van-cell {
  border-radius: 10px;
  padding: 0;
}

/deep/ .van-collapse-item .van-collapse-item__title--expanded {
  border-radius: 10px 10px 0 0 !important;
}
.disCollapse /deep/ .van-field__control {
  background: #FAFAFA;
  border-radius: 15px;
  padding: 18px;
}

.disCollapse /deep/ .van-button {
  width: 90px;
  padding: 0 20px !important;
  border-radius: 25px !important;
}
.modal{
  font-size: 0.98rem;color: #b7b7b7;text-align: left;margin: 0.45rem 0.25rem;
}
.modal-Y{
  font-size: 1.1rem;
  color: #000;
  text-align: center;
}
</style>
