<style scoped>
.boxApp2{
  background: #f5f6fa;
  height: 100vh;
  font-family: '微软雅黑';
}
.null_img{
  padding: 8vw 0 4vw;
}
.null_img img{
  width: 5rem;
}
.null_img div{
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}
.order-list-item {
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}
:deep(.van-tabs__line){
  background-color: #1677ff !important;
  height: 2px !important;
  width: 60px !important;
}
.order_list :deep(.van-tab--active) {
  color: #1677ff !important;
}
.orderName {
  font-size: 3.5vw;
  line-height: 2.3rem;
  font-weight: 700;
  display: inline-block;
  max-width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.order-status {
  color: #ff3333;
  font-size: 3.5vw;
  line-height: 2.3rem;
  font-weight: 700;
  float: right;
}

.order-details {
  margin-bottom: 8px;
}

.order-info {
  display: flex;
  margin-bottom: 4px;
  font-size: .7rem;
  line-height: 1.5rem;
}

.order-label {
  color: #222;
}

.order-value {
  text-align: right;
  flex: 1;
}

.order-actions {
  display: flex;
  justify-content: flex-end;
}

.cancel-button {
}
.flexTop{
  background: white;
  position: fixed;
  top: 0;
  z-index:99;
  width: 100%;
}
.middle_class{
  height: v-bind(middleHeight);
}
.flexBox{
  margin-top: 10px;
  background: #f5f6fa;
}
.tabCardClass {
  text-align: center;
  margin: 0 auto;
  border-color: rgb(49, 141, 255);
  min-width: 200px;
  height: 25px !important;
}
:deep(.van-nav-bar .van-icon) {
  color: #cbcbcb !important;
}
:deep(.van-grid-item__content){
  padding: 20px 8px !important;
}
.picker_class{
  height: 185px;
  overflow: hidden;
  position: relative;
}
.time_class{
  font-size: 1.1rem;
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  background: #fff;
  box-shadow:4px 4px 15px #e0e0e0;
}
.time_class span{
  margin: 2px 8px 15px;
  min-width: 95px;
  display: inline-block;
  color: #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
}
.time_class .time_active{
  margin: 2px 8px 15px;
  min-width: 95px;
  display: inline-block;
  color: #1989fa;
  border-bottom: 1px solid #1989fa;
}
.listContent {
  margin: 8px 0 15px;
  padding: 8px 20px;
  background-color: #fff;
}
.order-bottom{
  display: flex;
  justify-content: space-between;
}
.tabSpan {
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  vertical-align: center;
  border: 1px solid #e2d8d8;
}
:deep(.van-tabs--card>.van-tabs__wrap){
  margin: 8px;
}
:deep(.van-tabs__nav--card){
  border-radius: 18px;
  color: #318dff;
  border: 1px solid #318dff;
  height: 25px !important;
}
:deep(.van-tab--card) {
  border-radius: 18px 0 0 18px;
  color: #318dff;
  border-right: 0;
}
:deep(.van-tab--card.active) {
  border-radius: 18px 0 0 18px;
}
:deep(.van-tab--card:last-child) {
  border-radius: 0 18px 18px 0;
}
:deep(.van-tab--card:last-child.active) {
  border-radius: 0 18px 18px 0;
}
:deep(.van-tab--card.van-tab--active) {
  background-color: #318dff;
  color: #ffffff;
}
.activeBlue{
  color: #1989fa;
  border: 1px solid #1989fa;
}
:deep(.van-dropdown-menu__title--active) {
  color: #1989fa;
}
.confirm-button{
  border-color: #5b5b5b;
  color: #646566;
  height: 23px;
  width: 70px;
  font-size: 0.8rem;
  border-radius: 10px;
  /*text-decoration:underline;*/
}
.ok-button{
  border-color: #ff3333;
  color: #FF7D15;
  height: 23px;
  width: 70px;
  font-size: 0.8rem;
  border-radius: 10px;
  margin-left: 16px;
  /*text-decoration:underline;*/
}
.orderHeader{
  text-align: left;
}
:deep(.van-divider){
  background: #cbcbcb;
}
.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}
</style>

<template>
  <div class="boxApp2">
    <div class="example" v-if="loadingShow">
      <a-spin size="large"/>
    </div>
    <div class="flexTop">
      <van-nav-bar>
        <template #title>
          <van-tabs v-model:active="headerTabValue" type="card" color="#318dff" @click-tab="headerClickTab" class="tabCardClass">
            <van-tab title="线上订单" ></van-tab>
            <van-tab title="线下订单" ></van-tab>
          </van-tabs>
        </template>
        <template #right >
          <van-icon @click="handelSearch" name="search" size="18" />
        </template>
      </van-nav-bar>

      <van-tabs class="order_list" v-model:active="levelFirst" @click-tab="onClickTabFirst">
        <van-tab v-for="item in listFirst" :key="item.value" :title="item.label" ></van-tab>
      </van-tabs>
      <div class="pullTabs" v-if="levelFirst !== 2 && headerTabValue !== 1">
        <van-dropdown-menu :lazy-render="false" :close-on-click-overlay="false" :close-on-click-outside="false">
          <van-dropdown-item  :title="orderAllValue" :lazy-render="false" @open="getTabSubordinate(1)"  ref="listItemRef">
            <van-grid :column-num="3" :gutter="-18" :border="false" >
              <van-grid-item v-for="(item,index) in listNext" :key="index" >
                <span :ref="tabSpanRef" :class="{activeBlue: orderTypeShow === index}" :value="item.value" class="tabSpan" @click="secondSpanClick(item,index)">{{ item.label }}</span>
              </van-grid-item>
            </van-grid>

            <div style="padding-top: 16px;">
              <!-- 待完成，事件编写 -->
              <van-button type="default" @click="onClose(listItemRef)" style="width:50%">取消</van-button>
              <van-button type="primary" @click="onConfirm(listItemRef,'1')" style="width:50%">确认</van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item :title="orderTimeValue" ref="timeRef" @open="getTimeData">
            <van-grid :column-num="3" :gutter="-18" :border="false">
              <van-grid-item v-for="(item,index) in 2" :key="index" >
                <span :ref="tabSpanRef"
                      :class="{activeBlue: timeShow === index}"
                      :value="item"
                      class="tabSpan"
                      @click="secondTimeClick(item,index)">{{ item===1?'全部时间':'自定义时间' }}</span>
              </van-grid-item>
            </van-grid>
            <div class="picker_class" v-if="timeShow === 1">
              <div class="time_class">
                <span :class="{time_active : dateTime=== '1'}" @click="getDateTime('1')">{{dataTimeStart}}</span> 至 <span :class="{time_active : dateTime=== '2'}" @click="getDateTime('2')">{{dataTimeEnd}}</span>
              </div>
              <van-datetime-picker
                type="date"
                :visible-option-num="3"
                :show-toolbar="false"
                v-model="pickerValue"
                :formatter="formatter"
                @change="getDateTimePicker"
              />
            </div>

            <div style="padding-top: 16px;">
              <!-- 待完成，事件编写 -->
              <van-button type="default" @click="onClose(timeRef)" style="width:50%">取消</van-button>
              <van-button type="primary" @click="onConfirm(timeRef,'2')" style="width:50%">确认</van-button>
            </div>
          </van-dropdown-item>

        </van-dropdown-menu>
      </div>

    </div>
    <div class="middle_class"></div>
    <div class="flexBox" v-if="listShow.length">
      <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item,index) in listShow" :key="item" :title="index" >
            <div class="listContent" >
              <div class="orderHeader" @click="handelToDetail(item)">

                <span class="orderName">
                  <van-icon name="label-o" style="font-size: 16px; margin-right: 5px"/>
                  {{ item.productInfoName }}</span>
                <span class="order-status">{{ item.statusName }}</span>
              </div>
              <div class="order-details" @click="handelToDetail(item)">
                <div class="order-info">
                  <span class="order-label">订单号：</span>
                  <span class="order-value">{{ item.tradeNo }}</span>
                </div>
                <div class="order-info">
                  <span class="order-label">投保人：</span>
                  <span class="order-value">{{ item.applicantName }}</span>
                </div>
                <div class="order-info">
                  <span class="order-label">被保人：</span>
                  <span class="order-value">{{ item.protectedmanName }}</span>
                </div>
                <div class="order-info">
                  <span class="order-label">投保时间：</span>
                  <span class="order-value">{{ item.polApplyDate }}</span>
                </div>
              </div>
              <van-divider />
              <div class="order-bottom">
                <div class="order-left">
                  <span class="order-label">保费：</span>
                  <span style="color: red;font-size: 18px">￥{{item.premium}}</span>
                </div>
                <div class="order-actions" v-if="item.contStatus !== '1'">
                  <van-button
                    v-if="item.cancelShow"
                    class="confirm-button cancel-button"
                    type="default"
                    size="small"
                    @click="cancelOrder(item)"
                  >
                    取消
                  </van-button>
                  <van-button
                    v-if="item.okShow"
                    class="ok-button"
                    size="small"
                    plain
                    @click="nextJump(item)"
                  >
                    继续
                  </van-button>
                  <van-button
                    v-if="item.underwritingStatus === '2' || item.underwritingStatus === '5'"
                    class="ok-button"
                    size="small"
                    plain
                    @click="seeDetail(item)"
                  >
                    查看详情
                  </van-button>
                </div>
              </div>

            </div>

          </div>
        </van-list>
      </van-pull-refresh>

    </div>
    <div v-if="!listShow.length  && showType">
      <div class="null_img">
        <img alt="" src="../../../assets/img/no_order.png" />
        <div>暂无记录</div>
      </div>
    </div>
    <!--  校验弹窗  -->
    <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isIdentical"
             @ok="insuranceErrorHandleOk">
      <p style="font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;">{{ statusModal }}</p>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="insuranceErrorHandleOk">确认
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent, ref, reactive, toRefs, nextTick, onMounted} from 'vue'
import {useRouter} from 'vue-router'
import { request} from '../../../utils/request'
import { getExtendUri} from '../../../utils/request_extend'
import {
  NavBar,
  Cell,
  Grid,
  GridItem,
  Divider,
  List,
  Tab,
  Tabs,
  Icon,
  PullRefresh,
  DropdownMenu,
  DropdownItem,
  Button,
  Space,
  Tabbar,
  TabbarItem,
  DatetimePicker,
  Popup,
} from 'vant'
import {message, Modal} from 'ant-design-vue'
import store from '@/store'
import { nowPathShow } from '../../../utils/product_process'
import {today} from '@/utils/date_util'
import dayjs from 'dayjs'
import {getUrlParams} from '@/utils/utlis'

export default defineComponent({
  components: {
    // OrderListRefresh,
    [DatetimePicker.name]: DatetimePicker,
    [Cell.name]: Cell,
    [NavBar.name]: NavBar,
    [Divider.name]: Divider,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Space.name]: Space,
    [Button.name]: Button,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
  },
  setup() {
    const router = useRouter()

    const state = reactive({
      token: router.currentRoute?.value?.query?.token,
      orderAllValue: '全部订单',
      orderTimeValue: '全部时间',
      isIdentical: false,
      statusModal: '',
      dateTime: '1',
      dataTimeStart: today(),
      dataTimeEnd: '选择日期',
      pickerValue: new Date(),
      orderTypeShow: 0,
      timeShow: 0,
      productId: '',
      payObj: {
        payStateResult: '',
        payUrl: '',
        payKey: '',
        payJson: {},
        payState: false,
        supId: '',
        temp: ''
      },
      orderList: []
    })
    // 线上/线下订单切换
    const headerClickTab = (e) =>{
      let type = ''
      if(e.name === 0) {
        levelFirst.value = 1
        type ='pay'
      }else if(e.name === 1){
        levelFirst.value = 0
        type = 'offlineOrders'
      }
      listShow.value = []
      try {
        request(getExtendUri('dictionary_query_byType'),{data: {types: [type]}}).then(
          res => {
            listFirst.value = res.result[type]
            if(e.name === 0) {
              getTabSubordinate()
            }else{
              showState.loadingShow = false
            }
          })
      } catch (error) {
        message.error(error)
      }
    }

    const insuranceErrorHandleOk = (e) =>{
      e.preventDefault()
      e.stopPropagation()
      state.isIdentical = false
    }
    const showState = reactive({
      loading:false,
      finished:false,
      refreshing:false,
      loadingShow: false,
      showType: false,
    })
    const headerTabValue = ref(0)
    const listItemRef = ref(null)
    const timeRef = ref(null)
    const payStatus = ref('')
    const contStatus = ref('')
    const contOKStatus = ref('')
    const listFirst = ref([])
    const listNext = ref([{}])
    const listShow = ref([])
    const LevelNext = ref(0)
    const pageNum = ref(0)
    const middleHeight = ref('135px')
    const levelFirst = ref(1)

    // 通过 actions 属性来定义菜单选项
    const actions = []

    const restData=()=>{
      state.dateTime= '1'
      state.dataTimeStart= today()
      state.dataTimeEnd= '选择日期'
      state.pickerValue= new Date()
      state.orderTypeShow= 0
      state.orderTimeValue = '全部时间'
      state.orderAllValue = '全部订单'
      state.orderTimeType = 0
      state.timeShow= 0

    }
    const formatter = (type, option) => {
      if (type === 'year') {
        option+= '年'
      }
      if (type === 'month') {
        option+= '月'
      }
      if (type === 'day') {
        option+= '日'
      }
      return option
    }
    // 自定义时间点击
    const getDateTime = (type) =>{
      state.dateTime = type
      if(type === '1'){
        state.pickerValue = new Date(state.dataTimeStart)
      }else{
        if(state.dataTimeEnd !== '选择日期'){
          state.pickerValue = new Date(state.dataTimeEnd)
        }else {
          state.dataTimeEnd = dayjs(state.pickerValue).format('YYYY-MM-DD')
        }
      }

    }
    // 日期发生改变的时候
    const getDateTimePicker = (selectedValues) =>{
      state.dateTime === '1' ? state.dataTimeStart = dayjs(selectedValues).format('YYYY-MM-DD') :
        state.dataTimeEnd = dayjs(selectedValues).format('YYYY-MM-DD')
    }



    const tabSpanRef = ref(null)  // 标签的ref
    // 订单类型确定
    const secondSpanClick = function (data, index) {
      state.orderTypeShow = index
      state.orderType = data.label
    }
    // 时间确定
    const secondTimeClick = (data,index) =>{
      state.timeShow = index
      state.orderTimeType = index
      state.pickerValue = new Date()
      state.dataTimeStart = today()
      state.dataTimeEnd = '选择日期'
    }
    const onSelect = (action) => console.log(action.text)
    const onClickTabFirst = function(){
      if (headerTabValue.value === 1) return
      restData()
      showState.loadingShow = true
      showState.showType = false
      showState.finished = false
      showState.loading = true
      pageNum.value = 0
      getTabSubordinate()
      nextTick(()=>{
        const flexTop = document.getElementsByClassName('flexTop')[0]
        middleHeight.value = flexTop.clientHeight + 'px'
      })
    }
    // 点击事件
    const onConfirm = function(data, type){
      if(type === '2'){
        if(state.orderTimeType === 1){
          const start = new Date(state.dataTimeStart).getTime()
          const end = new Date(state.dataTimeEnd).getTime()
          if(state.dataTimeEnd === '选择日期') {
            state.isIdentical = true
            state.statusModal = '请选择结束日期'
            return
          }else if(start > end){
            state.isIdentical = true
            state.statusModal = '结束日期不能小于开始日期'
            return
          }
        }
        state.orderTimeValue = state.orderTimeType === 1 ? '自定义时间' : '全部时间'
        state.timeShow = state.orderTimeType
      }else{
        state.orderAllValue = state.orderType
      }
      pageNum.value = 0
      listShow.value = []
      onLoad()
      data.toggle()
    }
    // 取消的时候
    const onClose = (data) =>{
      data.toggle()
    }
    const onClosed = () =>{
      state.dataTimeStart = today()
      state.dataTimeEnd= '选择日期'
      state.pickerValue= new Date()
      state.orderTypeShow= 0
      state.timeShow= 0
    }


    const onRefresh = () =>{
      pageNum.value = 0
      listShow.value = []
      onLoad()
    }

    const onLoad = () => {
      showState.loading = true
      showState.finished = false
      showState.refreshing = true
      getDetailList()
    }

    // 获取列表信息
    const getDetailList = () =>{
      pageNum.value++
      let source = ''
      showState.loadingShow = true
      let token = router.currentRoute.value.query.token ? router.currentRoute.value.query.token : ''
      // let token = '0ce59168365048138b4503f24fc7febe'
      let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
      let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS){
        source = 'ios'
      }
      if (isAndroid) {
        source = 'android'
      }
      let parameter = {
        pagination:{
          current: pageNum.value,
          pageSize: 10
        },
        data:{
          statusType: levelFirst.value === 2 ? listFirst.value[levelFirst.value].value : listNext.value[state.orderTypeShow].value,
          polApplyStartDate: state.timeShow === 0 ? '': state.dataTimeStart + ' 00:00:00',
          polApplyEndDate: state.timeShow === 0 ? '': state.dataTimeEnd + ' 23:59:59'
        }
      }
      request(`/product-service/product/productInsured/bdt/api/queryListForPageFront?source=${source}&sign=xxxx&version=68&token=${token}`, parameter, true, 'post').then(res => {
        if(res.code === 1 && res.result && res.result.length){
          if (res.result.length !== 10) {
            showState.finished = true
          }
          // 判断当前数据的状态, 是否展示按钮 cancelShow   okShow
          res.result.forEach(item => {
              item.okShow= ['0','2'].includes(item.paySataus) && ['0','1','4'].includes(item.underwritingStatus) && item.contStatus === '0'
              item.cancelShow = ['0','2'].includes(item.paySataus) && ['0','1','2','4','5'].includes(item.underwritingStatus)&& item.contStatus === '0'
          })
          listShow.value = listShow.value.concat(res.result)
          showState.loading = false
          showState.refreshing = false
          showState.loadingShow = false
          showState.showType = false
        }else{
          showState.loadingShow = false
          showState.showType = true
          showState.loading = false
          showState.finished = true
          showState.refreshing = false
        }
      })
    }

    // 获取并保存productDetailData，需要用到productDetailData.productInfo.productId
    const saveProductInfo = (item) => {
      request(getExtendUri('getLinkData'), {
        data: {
          id: item.shareId
        }
      }).then(res => {
        if (res.code === 1 && res.result) {
          // 将详情信息存入
          store.dispatch('insured/changeInsureJSInfo', { productDetailData: JSON.stringify(res.result) })
          store.dispatch('insured/changeInsureJSInfo', { productId: res.result.productInfo.productId })
          let productDetailInfo = res.result
          state.productId = productDetailInfo ? productDetailInfo.productInfo.productId : ''
          getNodeState(item)
        }else if (res.code === 300) {
          window.location.assign(res.result)
        } else {
          showState.loadingShow = false
          message['error'](res.message)
        }
      })

    }
    // 获取条款缓存
    // const getPdfCacheParametersData = (item) =>{
    //   request(getExtendUri('getCacheParameters'), {
    //     tradeNo: item.tradeNo+'PDF'
    //   }).then(res => {
    //     if (res.result){
    //       store.dispatch('insured/changeInsureJSInfo', {
    //         clausePdfList: res.result.saveData.clausePdfList
    //       })
    //     }
    //   })
    // }
    // 根据productId判断到哪个节点
    const getNodeState = (item) => {
      request(getExtendUri('getProcessByProductId'), {
        data: {
          productId: state.productId
        }
      }).then(res => {
        // 先将获取到的node节点存入缓存
        let nodeDetailData = res.result
        showState.loadingShow = false
        // 遍历获取到的node节点
        nodeDetailData.forEach(nodeItem => {
          // for (const key in showArr) {
          // 'acknowledgementOrder'
          if (nodeItem.nextCode) {
            if (nodeItem.nextCode.indexOf('acknowledgementOrder') != -1) {
              nowPathShow(nodeItem.processId, nodeItem.sort, item.tradeNo)
              getRiskListFun(item.tradeNo)
              router.push({
                path: '/app/confirmation',
                query: {
                  tradeNo: item.tradeNo,
                  shareId: item.shareId,
                  processId: nodeItem.processId,
                }
              })
            }
          }
          // }
        })
      })
    }

    // 根据接口获取riskLisk数据存入缓存
    const getRiskListFun = (tradeNo) => {
      request(getExtendUri('getCacheParameters'), {
        tradeNo: tradeNo
      }).then(res => {
        store.dispatch('insured/changeInsureJSInfo', { riskList: JSON.stringify(res.result.selectRisk) })
        store.dispatch('insured/changeInsureJSInfo', {submitDate: JSON.stringify(res.result.saveData)})
      })
    }
    // 查看详情
    const seeDetail = (data) =>{
      const params = getUrlParams(window.location.href)
      router.push({
        path: '/app/underwriting',
        query: {
          ...params,
          id: data.id,
          underwritingStatus: data.underwritingStatus
        }
      })
    }
    // 根据订单状态跳转不同的页面
    const nextJump =  function (item) {
      showState.loadingShow = true
      //待支付中的继续
      if (levelFirst.value === 1 && item.payUrl) {
        window.location.href = item.payUrl
      } else {
        saveProductInfo(item)
      }
    }

    // 取消
    const cancelOrder = (data) =>{
      // let token = '0ce59168365048138b4503f24fc7febe'
      Modal.confirm({
        title: '提示',
        content: '确认取消该订单？',
        onOk: () => {
          request('/trade-service/trade/insuredPayData//bdt/api/cancelInsured?token='+state.token, { insuredId: data.id }).then(res => {
            if (res.code === 1) {
              showState.loadingShow = true
              pageNum.value = 0
              listShow.value = []
              getDetailList()
              message.success('取消成功')
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }

    // 查询
    const handelSearch = () =>{
      const params = getUrlParams(window.location.href)
      router.push({
        path: '/app/searchData',
        query: params
      })
    }
    // 跳转详情页
    const handelToDetail = (data) =>{
      console.log(data,state)
      const params = getUrlParams(window.location.href)
      router.push({
        path: '/app/detailPages',
        query: {
          ...params,
          id: data.id,
          underwritingStatus: data.underwritingStatus
        }
      })
    }





    // 进入获取tab值
    const initTypeList = (list,type) =>{
      try {
        request(getExtendUri('dictionary_query_byType'),{data: {types: [type]}}).then(
          res => {
            list.value = res.result[type]
            getTabSubordinate()
          })
      } catch (error) {
        message.error(error)
      }
    }
    const detailData = reactive({
      stateCopy: {},
      showState: {},
    })
    // 获取tab下级的订单类别
    const getTabSubordinate = (num) =>{
      detailData.state = JSON.parse(JSON.stringify(state))
      detailData.showState = JSON.parse(JSON.stringify(showState))
      const type = listFirst.value[levelFirst.value].value
      try {
        request(getExtendUri('dictionary_query_byType'),{data: {types: [type]}}).then(
          res => {
            listNext.value = res.result[type]
            if(levelFirst.value !== 2) state.orderTypeShow= listNext.value.findIndex(item => item.label === state.orderAllValue)
            state.timeShow = state.orderTimeType || 0
            if(num !== 1){
              listShow.value = []
              getDetailList()
            }
          })
      } catch (error) {
        message.error(error)
      }
    }
    // 时间展示类型
    const getTimeData = () =>{}
    onMounted(()=>{
      document.title = '订单管理'
      sessionStorage.setItem('selectName', '')
      initTypeList(listFirst,'pay')
    })


    return {
      detailData,
      middleHeight,
      headerTabValue,
      listItemRef,
      timeRef,
      tabSpanRef,
      payStatus,
      contStatus,
      contOKStatus,
      ...toRefs(state),
      ...toRefs(showState),
      initTypeList,
      getTabSubordinate,
      getTimeData,
      actions,
      onSelect,
      listShow,
      onRefresh,
      onLoad,
      LevelNext,
      listFirst,
      listNext,
      levelFirst,
      onClickTabFirst,
      secondSpanClick,
      onConfirm,
      nextJump,
      restData,
      formatter,
      getDateTime,
      getDateTimePicker,
      handelSearch,
      insuranceErrorHandleOk,
      secondTimeClick,
      onClose,
      onClosed,
      handelToDetail,
      cancelOrder,
      headerClickTab,
      seeDetail
    }
  }
})
</script>
