<template>
  <div class="main">
    <IOSbackBar :title="'智能核保'" ></IOSbackBar>
    <div class="info-body" :class="(index === selectList.length - 1) ? 'last' : ''" v-for="(item, index) of selectList" :key="index">
      <!-- 疾病名称 -->
      <div class="diseaseInfo" v-if="item.questionArr">
        <!--        <div class="diseaseName">{{ nowRisk + ' - ' + disObjTem.diseaseName }}</div>-->
        <div class="diseaseName">{{ item.diseaseName }} <span v-if="item.diseaseDesc" class="icon" @click="showTip(item)"><InfoCircleTwoTone/></span>
        </div>
        <!--         温馨提示 -->
        <!--                <p class="tips">-->
        <!--                  &lt;!&ndash; <van-icon name="info" /> &ndash;&gt;-->
        <!--                  <span>若疾病评估问答均选择正确，请点击确认按钮；若疾病评估问答选择错误，请点击页面左上角“&lt;”重新评估 </span></p>-->
      </div>
      <!-- 选项 -->
      <ul class="conInfo" v-if="item.questionArr">
        <li class="conLi" v-for="(itm, idx) in item.questionArr" :key="idx">
          <p class="conQuestion"><span class="quesIndex">{{ idx + 1 }}.</span><span>{{ itm.questionDesc }}</span></p>
          <van-radio-group v-model="itm.checkOption" v-for="(option, indexOption) in itm.rquestionOptionDtos"
                           :key="indexOption">
            <van-radio :name="option.optionId" checked-color="#07c160"
                       @click="nextQuestion(itm,option,item.questionArr,index, idx)">
              {{ option.optionText }}
            </van-radio>
          </van-radio-group>
        </li>
      </ul>
    </div>
    <footer class="footer">
      <div class="footer_display">
        <div class="footer-left">
          <van-button type="primary" @click="onClickLeft">返回</van-button>
        </div>
        <div class="footer-right">
          <van-button type="primary" @click="handelOK">确定</van-button>
        </div>
      </div>
    </footer>
    <!-- 疾病释义 -->
    <van-overlay :show="descShow" class="assessOverlay">
      <div class="wrapper">
        <div class="block">
          <div class="assessClose">
            <van-icon name="cross" @click="assessCloseClick "/>
          </div>
          <p class="assessQues">{{ desc }}</p>
          <div class="descBtn">
            <van-button class="descButton" @click="closeDesc">已了解</van-button>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 是否继续评估遮罩 -->
    <van-overlay :show="assessShow" class="assessOverlay">
      <div class="wrapper">
        <div class="block">
          <div class="assessClose">
            <van-icon name="cross" @click="assessCloseClick "/>
          </div>
          <p class="assessQues">您是否已完成健康评估，出具核保结论</p>
          <div class="assessBtn">
            <van-button @click="nextAssessClick(optionId)" class="finishAssess">完成评估</van-button>
            <van-button plain @click="cancelClick" class="finishAssess">取消</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 提示 -->
    <van-overlay :show="showErr" class="assessOverlay">
      <div class="wrapper">
        <div class="block">
          <div class="assessClose">
            <van-icon name="cross" @click="onCloseClick"/>
          </div>
          <p class="assessQues">{{ errMessage }}</p>
          <div class="descBtn">
            <van-button class="descButton" @click="errCloseClick('ok')">确定</van-button>
            <van-button class="closeButton" plain  @click="errCloseClick('close')" v-if="isShowClose">取消</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>

</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from 'vue'
import {
  Search,
  Tag,
  Button,
  Icon,
  Popup,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Overlay
} from 'vant'
import router from '@/router'
import {request} from '@/utils/request'
import {getExtendUri} from '@/utils/request_extend'
import store from '@/store'
// import {message} from 'ant-design-vue'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'
import { routerJump} from '@/utils/product_process'
// import store from '@/store'
export default defineComponent({
  components: {
    [Search.name]: Search,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Overlay.name]: Overlay,
    IOSbackBar
  },
  setup() {
    const state = reactive({
      assessShow: false,
      descShow: false,
      showErr: false,
      isShowClose: false,
      errMessage: '',
      desc: '我是释义我是释义我是释义我是释义我是释义我是释义我是释义',
      nowRisk: '',
      disObjTem: {},
      questionArr: [],
      question: {},
      answer: '',
      endResultMsg: '',
      sickNameAll: '',        // 全部疾病名称（字符串）
      sickRefuseNameAll: [],  // 全部疾病名称（字符串）
      // 疾病整体结果（从重要程度开始：拒保 —— 延期 —— 加费 免责 —— 标体）
      sickList: [],
      optionId: 0,  // 选项Id  如果为0 则传疾病Id
      selectQuesObj: {},
      selectList: [],
      resultCode: '',
      offlineUnderwriting: 'N',
      resultInfo: '',
      resultShow: false
    })
    const errCloseClick = (type) => {
      if((state.resultInfo === '标准体' || state.resultInfo ==='除外承保') && state.offlineUnderwriting === 'N'){
        state.showErr = true
        state.isShowClose = true
        state.assessShow = false
        state.errMessage = '是否同意当前核保结论'
        state.resultInfo = ''
        state.resultShow = true
        return
      }else if(state.resultShow && type === 'close'){
        state.errMessage = '是否进入人核'
        state.showErr = true
        state.isShowClose = true
        state.resultInfo = ''
        state.resultShow = false
        state.assessShow = false
        state.offlineUnderwriting = 'Y'
        return
      }else if(state.offlineUnderwriting === 'Y' && type === 'close'){
        onCloseClick()
        return
      }
      state.showErr = false
      state.resultShow = false
      state.errMessage = ''
      if(state.resultCode === '1'){
        if(state.offlineUnderwriting === 'Y'){
          request(getExtendUri('onSavePerson'), {
            tradeNo: router.currentRoute.value.query.tradeNo,
            uwStatus: '1',
            remark: '',
            imageList: []
          })
        }
        // routerJump(undefined, 'health')
        cacheParametersAndToInputInfomate('health')
        // router.push({
        //   path: '/app/informationInput',
        //   query: {
        //     id: '8298bafc2a6c45e5bb4a65a829b715bf',
        //     sort: 3,
        //     tradeNo: store.getters['insured/getTradeNo'],
        //     shareId: store.getters['insured/getShareId'],
        //     typeCode:  ''
        //   }
        // })
      }
    }
    // 弹窗的X号
    const onCloseClick = () =>{
      state.showErr = false
      state.resultShow = false
      state.isShowClose = false
      state.errMessage = ''
      state.resultInfo = ''
      state.assessShow = false
      state.offlineUnderwriting = 'N'
    }




    // 根据疾病Id获取问题
    const getData = (id, getType) => {
      request('/product-service/product/disease/api/getDiseaseQuestionList?' + 'id=' + id + '&getType=' + getType, null, false, 'get').then(res => {
        if (res.code == 1) {
          state.questionArr = res.result
          res.result.forEach(item => {
            state.selectList?.forEach(val => {
              if (item.diseaseId === val.id) {
                val.questionArr = []
                val.questionArr.push(item)
              }
            })
          })
        } else {
          state.assessShow = true
        }
      })
    }
    onMounted(() => {
      state.selectList = JSON.parse(router.currentRoute.value.query['selectList'])
      let arrayOfIds = state.selectList.map(function (obj) {
        return obj.id
      })

      // 使用join方法将数组中的id拼接成一个字符串
      const id = arrayOfIds.join(',')
      getData(id, '1')
    })
    // 下一级问题
    const nextQuestion = (question, item, questionArr, i, idx) => {
      state.selectList[i].questionArr.checkOption = item.optionId
      state.selectList[i].questionArr = state.selectList[i].questionArr.slice(0, idx + 1)
      request('/product-service/product/disease/api/getDiseaseQuestionList?' + 'id=' + item.targetId + '&getType=2', null, false, 'get').then(res => {
        if (res.code == 1) {
          state.selectList[i].questionArr.push(res.result[0])
        }
      })
    }
    const onClickLeft = () => {
      router.go(-1)
    }
    const handelOK = () => {
      let list = []
      state.selectList.forEach(item => {
        item.questionArr?.forEach (val => {
          if (val.checkOption == '0') {
            list.push('0')
          }
        })
      })
      if (list.length) {
        state.errMessage = '问题未选择'
        state.showErr = true
        // message['error']('问题未选择')
      } else {
        state.assessShow = true
      }
    }
    const assessCloseClick = () => {
      state.assessShow = false
    }
    // 跳转结果页
    const nextAssessClick = () => {
      let diseaseDeatil = []
      state.selectList.forEach(item => {
        diseaseDeatil.push({
          type: item.questionArr ? item.questionArr?.length : 0,//0-疾病直接获取结论1-疾病下有一个问题2-疾病下有两个问题3-疾病下有三个问题
          diseaseId: item.id,//疾病ID
          questionId: item.questionArr ? item.questionArr[item.questionArr?.length - 1].questionId : '',//最后一级问题ID
          optionId: item.questionArr ?  item.questionArr[item.questionArr?.length - 1].checkOption : ''//最后一级选择的选项ID
        })
      })
      const data = {
        tradeNo: router.currentRoute.value.query.tradeNo,//交易流水号
        riskCode: JSON.parse(store.getters['insured/getRiskList']).riskList ? JSON.parse(store.getters['insured/getRiskList']).riskList[0].riskCode : '',//险种编码
        riskFlag: 'ALL',//险种标识
        diseaseDeatil
      }
      request(getExtendUri('saveNotifyResult'), data).then(res => {
        if (res.code === 1) {
          state.resultCode = res.result.resultCode
          state.offlineUnderwriting = 'N'
          if(res.result.resultCode === '1' && res.result.offlineUnderwriting === 'N'){
            state.resultInfo = res.result.resultInfo
            if(res.result.remark || res.result.resultInfo){
              state.errMessage = res.result.remark || res.result.resultInfo
              state.showErr = true
            }
          }else if(res.result.offlineUnderwriting === 'Y' ){
            state.errMessage = '是否进入人核'
            state.showErr = true
            state.isShowClose = true
            state.offlineUnderwriting = 'Y'
          }else{
            // message['error'](res.result.resultInfo)
            state.errMessage = res.result.resultInfo
            state.showErr = true
          }
        }else{
          // message['error']('系统异常，请稍后重试')
          state.errMessage = '系统异常，请稍后重试'
          state.showErr = true
        }
      })
    }
    const cacheParametersAndToInputInfomate = (type) => {
      request(getExtendUri('cacheParameters'), {seconds: 86400, tradeNo: router.currentRoute.value.query.tradeNo+ 'intelligentUnderwriting',selectRisk:{intelligentUnderwriting: state.selectList}}).then((res => {
        if(res.code === 1) {
          if (type === 'health') {
            routerJump(router.currentRoute.value.query.tradeNo, 'health')
            return false
          } else  {
            // 路由跳转 先写死
            router.push({
              path: '/app/informationInput',
              query: {
                id: '8298bafc2a6c45e5bb4a65a829b715bf',
                sort: 3,
                tradeNo: router.currentRoute.value.query.tradeNo,
                shareId: store.getters['insured/getShareId'],
                typeCode:  ''
              }
            })
          }
        }
      }))
    }
    // 取消
    const cancelClick = () => {
      state.assessShow = false
    }
    const showTip = (val) => {
      state.descShow = true
      state.desc = val.diseaseDesc
    }
    const closeDesc = () => {
      state.descShow = false
    }
    return {
      ...toRefs(state),
      errCloseClick,
      nextQuestion,
      cancelClick,
      nextAssessClick,
      assessCloseClick,
      onClickLeft,
      showTip,
      closeDesc,
      handelOK,
      onCloseClick
    }
  }
})
</script>

<style scoped>
.main {
  text-align: left;
  overflow-y: auto;
}

.main {
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(155deg, #1181f6 0%, rgba(163, 204, 252, 0.95) 77%, rgba(215, 233, 255, 0.99) 100%, rgba(109, 255, 65, 0) 100%);
}

.main::before {
  content: "";
  display: table;
}

.main /deep/ .robotNavBar {
  background-color: #00000000;
}

.main /deep/ .robotNavBar::after {
  border-bottom-width: 0;
}

.main /deep/ .robotNavBar .van-nav-bar__title {
  font-size: 16px;
  color: #ffffff;
}

.main /deep/ .robotNavBar .van-icon {
  font-size: 18px;
  color: #ffffff;
}

.main .info-body {
  //height: calc(54vh);
  overflow-y: auto;
}

.main .diseaseInfo {
  width: calc(70%);
  margin: 0 auto;
  margin-top: 20px;
}

.main .diseaseInfo .diseaseName {
//line-height: 30px; font-size: 20px; color: #FFFFFF;
  text-align: center;
}

.main .diseaseInfo .tips {
  color: #FFFFFF;
  line-height: 24px;
  font-size: 14px;
  opacity: 0.6;
}
.last {
  margin-bottom: 60px;
}

.main .conInfo {
  width: 100%;
  min-height: 77%;
  bottom: 16%;
}

.main .conInfo .conLi {
  width: 94%;
  margin: 8px auto 0;
  padding: 8px 16px;
  background-color: #ffffff;
  border-radius: 12px;
  font-size: 16px;
}

.main .conInfo .conLi .conQuestion {
  display: flex;
  align-items: baseline;
}

.main .conInfo .conLi .conQuestion .quesIndex {
  margin-right: 10px;
}

.main .conInfo .conLi .answerActive {
  color: red;
}

.main .conInfo .conLi .van-radio-group {
  margin: 20px 0px 15px 20px;
}

.main .conInfo .conLi .van-radio-group /deep/ .van-radio {
  min-height: 40px;
}

.main .conInfo .conLi .van-radio-group /deep/ .van-radio .van-radio__icon .van-icon {
  color: transparent;
  background: transparent;
  border: 1px solid #c8c9cc;
}

.main .conInfo .conLi .van-radio-group /deep/ .van-radio .van-radio__icon--checked .van-icon {
  color: #fff;
  background-color: #1989fa;
  border-color: #1989fa;
}

.main .conInfo .conLi .van-radio-group /deep/ .van-radio .van-radio__label {
  color: #323233;
}

.main .conInfo .conLi .van-radio-group .van-divider {
  margin: 16px 0;
  border: 0 solid #ebedf0;
}

.main .conInfo .tips {
  margin: 10px auto 0;
  padding: 10px 6px;
  width: 92%;
  border-radius: 10px;
  background-color: #e7f3ff;
  color: #72716d;
  font-size: 12px;
}

.main .conInfo .tips .van-icon {
  margin: 2px 5px 0 0;
  vertical-align: top;
  color: #1f87f4;
  font-size: 15px;
}

.main .conInfo .tips span {
  display: inline-block;
  width: 92%;
}

.main .assessOverlay {
  z-index: 10;
}

.main .assessOverlay .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.main .assessOverlay .wrapper .block {
  width: 80%;
  padding: 18px;
  border-radius: 14px;
  background: linear-gradient(180deg, #d9ebff 0%, #FCFDFF 30%, #FCFDFF 40%);
}

.main .assessOverlay .wrapper .block .assessClose {
  text-align: right;
}

.main .assessOverlay .wrapper .block .assessClose .van-icon {
  padding: 4px;
  font-size: 16px;
  color: #323233;
}

.main .assessOverlay .wrapper .block .assessQues {
  padding: 20px 0;
  text-align: center;
  font-size: 16px;
  font-weight: bolder;
}

.main .assessOverlay .wrapper .block .assessBtn {
  margin: 20px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main .assessOverlay .wrapper .block .assessBtn .van-button {
  width: 100%;
  height: 40px;
  border-radius: 30px;
}

.main .assessOverlay .wrapper .block .assessBtn > :nth-child(1) {
  background: linear-gradient(155deg, #1181f6 0%, #67aef8 77%, #c4dcf6 100%);
  margin-bottom: 10px;
  font-weight: bold;
  color: #FFFFFF;
}

.main .assessOverlay .wrapper .block .assessBtn > :nth-child(2) {
  border: #1989fa 1px solid;
  font-weight: bold;
  color: #1989fa;
}

.main .assessOverlay .wrapper .block .descBtn {
  margin: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main .assessOverlay .wrapper .block .descBtn .van-button {
  width: 100%;
  height: 40px;
  border-radius: 30px;
}

.main .assessOverlay .wrapper .block .descBtn .descButton {
  background: linear-gradient(155deg, #1181f6 0%, #67aef8 77%, #c4dcf6 100%);
  color: #ffffff;
}
.closeButton{
  border: #1989fa 1px solid;
  font-weight: bold;
  color: #1989fa;
  margin-top: 10px;
}

.icon {
  font-size: 22px;
}

.footer {
//background: #fff; bottom: -2px; left: 0;
  position: fixed;
  height: 64px;
  width: 100%;
  padding: 0 20px;
  line-height: 56px;
  z-index: 2;
//border-top: 1px solid #dfdfdf;
}

.footer .footer_display {
  height: 100%;
  /*position: relative;*/
  display: flex;
  justify-content: space-around;
}

.footer-right, .footer-left {
  height: 100%;
}

.footer-left /deep/ .van-button {
  min-width: 105px;
  padding: 0 20px !important;
  border-radius: 25px !important;
  background: #FFFFFF;
  color: #0a84ff;
}

.footer-right /deep/ .van-button {
  min-width: 105px;
  padding: 0 20px !important;
  border-radius: 25px !important;
}
</style>
