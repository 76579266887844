<template>
  <div class="form-group">
    <div class="form-row">
      <input type="text" class="form-input" :value="nameS" disabled />
    </div>
    <div class="form-row">
      <input type="text" class="form-input" :value="phoneS" disabled />
    </div>
    <div class="form-row">
      <div class="form-input">
        <input type="text" v-model="code" class="code-input" :maxlength="6" placeholder="输入验证码" />
        <div class="code-divider"></div>
        <div class="button-class">
          <button :disabled="countdown > 0" @click="sendCode" class="code-button">{{ buttonText }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, toRefs, watch} from 'vue'
import {getVCodeApi} from '../elSign/js/signature_api'
export default {
  name: 'verificationCode',
  props: {
    phone: String,
    name: String,
    tradeNo: String,
    signProvider: String,
    attributive: String,
    customCode: String,
  },
  setup(props, {emit}) {
    const code = ref('')
    const countdown = ref(0)
    const state = reactive({
      phoneS: props.phone,
      nameS: props.name,
      sendCodeClick: false,

    })

    const buttonText = computed(() => {
      if (countdown.value > 0) {
        return `${countdown.value}s后重新获取`
      } else {
        return '获取验证码'
      }
    })

    const sendCode = async () => {
      state.sendCodeClick = true
      if (countdown.value > 0) {
        return
      }
      //禁用获取验证码按钮
      const {code, message} = await getVCodeApi({
        data: {
          attributive: props.attributive,
          customCode: props.customCode,
          mobile: state.phoneS,
          tradeNo: props.tradeNo,
          signProvider: props.signProvider
        }
      }).then(res => {
        return res
      })
      // const response = await new Promise(resolve => {
      //   setTimeout(() => {
      //     // 模拟请求返回结果
      //     resolve({
      //       success: true,
      //       message: '验证码发送成功'
      //     })
      //   }, 2000) // 2秒后模拟请求返回结果
      // })
      // if (response.success) {
      if (code === 1) {
        console.log('验证码发送成功')
        countdown.value = 60
        countDownTimer()
      } else {
        console.log(`验证码发送失败：${message}`)
      }
    }
    // 倒计时函数
    const countDownTimer = () => {
      if (countdown.value > 0) {
        setTimeout(() => {
          countdown.value--
          countDownTimer()
        }, 1000)
      }else{
        state.sendCodeClick = false
      }
    }
    watch(code,(newValue)=> {
      if (newValue.length) {
        // 当验证码长度为 6 时，表示用户已经输入了完整的验证码
        emit('handleCode', code)
      } else {
        emit('handleCode', undefined)
      }
    } )

    // 校验
    const getCheck = (status) =>{
      if(state.sendCodeClick && !status ){
        return '请输入验证码'
      }else if(code.value.length !== 6){
        return '验证码错误'
      }
      return null
    }
    return {
      ...toRefs(state),
      code,
      countdown,
      buttonText,
      sendCode,
      getCheck
    }
  }
}
</script>

<style scoped>
.form-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.form-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-input {
  height: 48px;
  display: flex;
  align-items: center;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.code-divider {
  height: 20px;
  width: 1px;
  background-color: #ccc;
  margin: 0 10px;
}


.code-button:disabled {
  font-size: smaller;
  background-color: #00000000;
  cursor: not-allowed;
  line-height: 1;
}

.code-input {
  /*flex: 0.8;*/
  border: none;
  padding: 5px;
  outline: none;
  min-width: 0; /* 设置表单元素的最小宽度 */
}
.button-class{
  /*width: 48%;*/
  text-align: center;
}
.code-button {
  background-color: #00000000;
  color: #0077cc;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  line-height: 1;
  /*min-width: 80px; !* 设置按钮的最小宽度 *!*/
  /*max-width: 100%; !* 设置按钮的最大宽度 *!*/
  /*flex-shrink: 0; !* 禁止按钮收缩 *!*/
}

</style>
