<style scoped>
.ant-back-top {
  bottom: 5rem;
  display: none;
}

.product_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #efefef;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
}

.bottom_bar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  height: 3.6rem;
  line-height: 3.6rem;
  font-size: 1.5rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 99;
}

.bottom_bar .tool_btn {
  font-size: 2rem;
  padding: .9rem;
  color: #1890ff;
}

.bottom_fix {
  height: 4rem;
}

.insure_btn, .prem_total {
  margin-left: 10px;
  height: 100%;
  font-weight: 500;
}

.insure_btn {
  font-size: 1.2rem;
}

.prem_total {
  color: #ff8400;
  margin-right: .5rem;
}

.block_card {
  background-color: #ffffff;
  border-radius: .5rem;
  padding: 0 .5rem 0rem 1rem;
}

.block_title {
  font-size: 1.2rem;
  font-weight: 500;
  color: #010721;
}

.product_intro {
  padding-top: 0.5rem;
}

.product_logo {
  width: 100%;
}

/* 以下是新增的, 京东安联玫瑰产品的样式 */
.short_name {
  position: absolute;
  /*top: 0.5%;*/
  top: 1rem;
  left: 3%;
  display: inline-block;
  color: #fff;
  font-size: 0.8rem;
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 20.5px;
  padding: 0.08rem 0.6rem;
}

.jd_header {
  width: 92%;
  position: relative;
  margin-top: -2.2rem;
  left: 4%;
  color: #000;
  z-index: 3;
  background: #FFFFFF;
  box-shadow: 0 0 8px 0 rgb(179 179 179 / 50%);
  border-radius: 10px;
  border-radius: 10px;
  padding-bottom: 0.1rem;

}

.jd_p {
  margin: 0;
  color: #666;
  font-size: .83rem;
  margin-top: 0.3rem;
  line-height: 1.58rem;
  font-size: 13px;
}

.jd_productName {
  font-size: 1.1rem;
  margin-bottom: 0;
}

.plan_class {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #f3f5f5;
}

.header_plan {
  width: 33%;
  line-height: 2rem;
  text-align: center;
  margin: 0.8rem 0;
  cursor: pointer;
  position: relative;
}

.header_plan::after {
  content: '';
  background-color: #c3c3c3;
  /*margin-right: 5px;*/
  margin-bottom: -2px;
  position: absolute;
  display: block;
  width: 1px;
  top: 5px;
  bottom: 5px;
  right: 0;


}
.viewP :deep(.ant-drawer-body) {
 padding-left: 0 !important;
 padding-right: 0 !important;
}
.header_plan:nth-last-of-type(1)::after {
  content: '';
  display: none;
}

.active {
  background: #fff;
  color: #0079fe;
}
:deep(.ant-spin-container){
  background: #fff;
}

.width50 {
  width: 50%;
}

.width25 {
  width: 25%;
}

.drawer_class {
  display: flex;
  justify-content: space-between;
}

:deep(.ant-drawer-title) {
  text-align: center !important;
}

.file_class {
  font-family: '微软雅黑';
  color: #666;
  font-size: 15px;
  display: flex;
  align-items: center;
  height: 2.8rem;
  margin: 0 .5rem 0rem 1rem;
  border-bottom: 1px solid #f3f5f5;
}
.lastFile_class {
  border-bottom: 0 !important;
}

.should {
  border-bottom: 1px solid #e2e2e2;
}

.introduce_class {
  font-size: 1.2rem;
  font-weight: 500;
  color: #010721;
}

:deep(.van-index-bar__sidebar) {
  position: fixed;
  top: 3%;
  right: 0;
  width: 100%;
  background: #f3f5f5;
  /* color: #000; */
  min-height: 55px;
  display: none;
  flex-direction: row;
  justify-content: space-around;
}

:deep(.van-index-bar__index) {
  font-size: 18px;
  line-height: 57px;
}
.footer-class{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  text-decoration:underline;
}
.footer_img{
  height: 100%;

}
.footer_img img{
  width: 4rem;
  height: 4rem;
}
.img_class{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
}
.img_title{
  text-align: center;
  text-decoration:underline;
}
.vue-pdf-embed :deep(canvas){
  height: 100% !important;
  opacity: 0;
}
.vue-pdf-embed{
  height: 100% !important;
//box-sizing: border-box;

}
.vue-pdf-embed :deep(.textLayer){
  opacity: 1;
//letter-spacing: .2rem !important;
}
.vue-pdf-embed :deep(.textLayer span){
  color: #000;
  opacity: 1;
//letter-spacing: .2rem !important; //display: inline-block; //min-width: 3px !important;
}
.vue-pdf-embed div:nth-child(1){
  height: 100%;
}
</style>

<template>
  <div id="product_top" class="product_wrap">
    <IOSbackBar :title="'产品详情'" :backMethod="goAppLet" ></IOSbackBar>
    <div ref="headerRef">
      <a-back-top :target="getTarget"/>
      <span class="short_name" v-if="productInfo.isFloating === 'Y'">{{ productInfo.floatingContent}}</span>
      <img v-show="!loading" class="product_logo" :src="productInfo.productBackImg" :title="productInfo.productName"/>
      <div class="block_wrap jd_header">
        <div class="block_card">
          <div class="product_intro">
            <a-skeleton :loading="loading" active :paragraph="{rows: 3}">
              <h3 class="jd_productName">{{ productInfo.productName }}</h3>
              <h4 style="color: #999;">{{ productInfo.productFeature }}</h4>
              <p class="jd_p" v-html="productInfo.productDescription"></p>
            </a-skeleton>
          </div>
        </div>
      </div>
    </div>
    <a-spin :spinning="spinning" :delay="50" tip="试算中..." size="large">
      <div class="block_card" v-if="insurancePlanList">
        <div class="plan_class">
          <template v-for="(factor ,index) in insurancePlanList.factorValueList" :key="'insurancePlanList_' + index">
            <div class="header_plan"
                 :class="{active: acticeNum === index, width50: insurancePlanList.factorValueList.length === 2,
              width25: insurancePlanList.factorValueList.length === 4
            }" @click="handelActive(factor,index)"
            >{{ factor.name }}
            </div>
          </template>
        </div>
      </div>
      <div style="height: 20px" v-else>
      </div>
      <van-index-bar
        ref="barRef"
        :index-list="indexBarList"
        z-index="100"
        :sticky-offset-top="stickyHeight">
        <van-index-anchor ref="barIndexRef" :index="indexBarList[0]"
                          style="height: 0;width: 0; visibility: hidden;"></van-index-anchor>
        <div class="block_wrap">
          <div class="block_card">
            <div class="block_title" id="safeguard_plan">
              <!-- <send-outlined/>&nbsp;&nbsp; -->
              保障计划
            </div>
            <a-skeleton :loading="loading" active :paragraph="{rows: 6}" :title="false">
              <InsurePlan :indexValue="indexBarList[1]" ref="insurePlanRef" @getAdditionalCoverageData="getAdditionalCoverageData"
                          v-model:value="productInfo" :trade-no="uuid" @premChange="onPremChange" :factorObject="factorObject"></InsurePlan>
            </a-skeleton>
          </div>

        </div>
        <div class="block_wrap">
          <div class="block_card" style="line-height: 2.2rem;font-size: 1rem;">
            <!-- 产品条款及须知 -->
            <div class="should" v-if="coverageDataList&&coverageDataList.length>0">
              保险条款及须知
              <template v-for="item in coverageDataList" :key="'coverage_' + item.id">
              <span style="color: #0079f8;"  @click="showPopPage(item)">
                《{{ item['fileDataTypeName'] }}》
              </span>
              </template>
            </div>

            <a-drawer
              placement="bottom"
              :visible="popVisible"
              @close="closePopPage"
              :height="isHeight"
              :title="pdfTitle"
              :style="{zIndex: 19998,color: '#666'}"
              :headerStyle="{'text-align': 'center'}"
              class="bottom_class">
              <template v-for="item in fileDrawerList" :key="item.id">
                <div class="drawer_class" @click="handelPdfTitle(item)">
                  <p>{{ item.uploadFileName }}</p>
                  <right-outlined/>
                </div>
              </template>
            </a-drawer>

          </div>
        </div>

        <a-drawer
          placement="bottom"
          :visible="imgVisible"
          :closable="false"
          height="100%"
          width="100vw"
          :style="{zIndex: 19998,color: '#666'}"
          class="bottom_class viewP">
          <div><IOSbackBar :backMethod="closePop" :title="commonProblem.name" ></IOSbackBar></div>
          <div style="width: 100vw;overflow-x: scroll;">
            <div :style="scaleFirst ? {} : {width: `${imageWidth}px`}" @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd">
            <a-spin style="width: 100%;height: 100%;
            position: fixed;
            top: 50%;
            left: 0;"
            ref="image"
            :spinning="pdfList.length< 1 && imgVisible" :delay="50" tip="加载中..." size="large">
              <div v-for="(pdf, index) in pdfList" :key="index">
                <img alt="" style="width:100%" :src="pdf"/>
              </div>
            </a-spin>
          </div>
          </div>
<!--          <VuePdfEmbed v-show="['pdf','xls', 'xlsx'].includes(commonProblem.type || '')"-->
<!--                       ref="VuePdfEmbedRef"-->
<!--                       :style="scaleFun"-->
<!--                       :page="pageSize"-->
<!--                       :source="commonProblem"-->
<!--                       @loaded="loaded"-->
<!--                       @loading-failed="loadingFailed"-->
<!--                       @rendering-failed="renderingFailed"-->
<!--                       @rendered="rendered"-->
<!--          />-->
<!--          <img alt="" style="width:100%" v-if="['png','jpg', 'jpeg', 'gif', 'bmp'].includes(commonProblem.type)" :src="commonProblem.url" />-->
        </a-drawer>


        <!-- 常见问题 理赔流程 其他-->
        <div class="block_wrap">
          <a-skeleton :loading="loading" active :paragraph="{rows: 5}">
            <template v-for="( item, index ) in imageDataList" :key="'image_' + item.id">
              <div>
                <div class="drawer_class file_class" :class="{lastFile_class: index === imageDataList.length -1}"
                     @click="handelImageData(item, index)">
                  <span>{{ fileTypeMap[item['fileDataType'] + ''] }}</span>
                  <right-outlined style="color:#666"/>
                </div>
              </div>
            </template>
          </a-skeleton>
        </div>

        <van-index-anchor :index="indexBarList[2]" style="height: 0;width: 0; visibility: hidden;"></van-index-anchor>
        <div class="block_wrap">
          <div class="block_card">
            <div class="introduce_class" id="product_id">
              产品介绍
            </div>
            <a-skeleton :loading="loading" active :paragraph="{rows: 5}">
              <template v-for="(item, index) in imageDataSexList" :key="'image_' + index">
                <img style="width: 100%;margin-bottom: .5rem;" :src="item['uploadFile']" :title="item['fileName']">
              </template>
            </a-skeleton>
          </div>
        </div>
      </van-index-bar>

      <div class="bottom_fix"></div>
      <div class="bottom_bar">
        <!-- 暂时隐藏 -->
        <img class="tool_btn" src="../../assets/img/fx.png" @click="testjs" v-if="isPhone">
        <!--      <share-alt-outlined class="tool_btn" @click="testjs" v-if="isPhone"/>-->
        <!--      <comment-outlined class="tool_btn" @click="contacting "/>-->
        <label class="prem_total" v-show="!loading">
          <span>{{ prem_total }}</span>
        </label>
        <a-button v-if="hideBtnFlag" :disabled="loading" type="primary" class="insure_btn" size="large" @click="nextPage">{{nextTitle}}</a-button>
      </div>
    </a-spin>

    <PopupPage ref="pop" :supplierName="productInfo.underwriteCompanyName" @changeEouterFlag="changeEouterFlag"/>
    <a-modal style="width: 90%;" centered :closable="false" :mask-closable="false" v-model:visible="isHaveNotDone">
      <p style="font-family: PingFangSC-Regular;
        font-size: 1.01rem;
        color: #333333;
        text-align: center;
        margin: 0.45rem 0.25rem;">{{ detailModal }}</p>
      <template #footer>
        <div style="text-align: center;">
          <a-button key="back"
                    type="link"
                    style="width: 45%; font-size: 20px; color: rgb(128,128,128); display: inline-block;"
                    @click="cancelEvent">取消
          </a-button>
          <a-button type="link"
                    style="width: 45%; font-size: 20px; color: #1677FF; display: inline-block;"
                    @click="jumpEvent">确认
          </a-button>
        </div>
      </template>
    </a-modal>
    <!--  校验弹窗  -->
    <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isIdentical"
             @ok="insuranceErrorHandleOk">
      <p style="font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;">{{ statusModal }}</p>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="insuranceErrorHandleOk">确认
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch
} from 'vue'
import {request} from '../../utils/request'
import {getExtendUri} from '../../utils/request_extend'
// import {startRecord} from '../../utils/rrweb'
import {message} from 'ant-design-vue'
import {useRouter} from 'vue-router'
import PopupPage from './public/popupPage'
import InsurePlan from '../../components/extended/product_detail_insure_plan'
import {getRouterJumpBean, nowPathShow} from '../../utils/product_process'
// import {guid} from '../InsuranceConfiguration/js/snowflake.js'
// import VuePdfEmbed from 'vue-pdf-embed'

// import ProductCashValue from './product_cashvalue'
import {loadRuleScriptResourceList} from '../../utils/loadScript.js'
// import {createFWNum} from '../../utils/fw_serial_number_utils'
import {IndexAnchor, IndexBar} from 'vant'
import 'vant/lib/index.css'
import store from '../../store'
import {
  changeDataRangeByAgeDiff,
  getDefaultAge,
  isBDT,
  isDateBetween,
  isIOS,
  isQyWeiXin,
  isWeiXin,
  showDataMin,
  showInsurancedateDataMin
} from '../../utils/utlis'
import {callHandler} from '../zzwTest/js_bridge'
import {newPremiumInTK} from '@/utils/special_treatment'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'

export default defineComponent({
  components: {
    IOSbackBar,
    InsurePlan,
    // VuePdfEmbed,
    PopupPage,
    // ProductCashValue
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
  },
  beforeRouteEnter(to, from, next) {
    // document.title = '产品名称'
    next()
  },
  setup() {
    let _f = '-'
    const router = useRouter()
    const headerRef = ref(null)
    const state = reactive({
      routerFlag: false,
      scaleFirst: true,
      baseWidth: 0,
      totalPage: 0,
      hideBtnFlag:true,
      pageSize: 1,
      srcUploadFile: '',
      productInfoSkip:{},
      nextTitle: '立即购买',
      isIdentical: false,
      statusModal: '',
      payStatus: 1,
      payMessage: '',
      isPhone: false,
      fileTypeName: '',
      fileDrawerList: [],
      isHeight: '16%',
      imgVisible: false,
      commonProblem: {
        url: null,
        cMapUrl: './pdf/cmaps/',
        cMapPacked: true
      },
      acticeNum: 0,
      isError: false,
      factorType: '',
      errMessage: '',
      // 详情页的对应产品名称
      productTopName: '',
      // 投保计划
      insurancePlanList: {},
      // 投保没有投保计划
      InsuranceNoPlanList: {},
      uuid: '',
      loading: true,
      productInfo: {},
      productId: '',
      progressId: '',
      prem_total: '0.0元',
      factorValue: '',
      coverageDataList: [],
      imageDataList: [],
      imageDataSexList: [],
      otherDataList: [],
      fileTypeMap: {
        '1': '投保须知',
        '2': '产品条款',
        '19': '免责声明',
        '6': '产品介绍',
        '7': '理赔流程',
        '12': '常见问题',
        '99': '其他'
      },
      pdfSource: null,
      drawerData: {},
      pdfTitle: '',
      spinning: false,
      tradeNo: 'Y002',
      operation: [],
      routerParam: {
        productId: '',
        channelId: '',
        agentId: ''
      },
      isHaveProductInfo: false,
      isHaveMounted: false,
      isToPlay: {},
      isHaveGetProduct: 0,
      effectDay: 0,
      ageDiffValue: '0',
      isHaveNotDone: false,
      detailModal: '',
      thumbnails: [],
      vis: true,
      stickyHeight:100
    })
    // const productDetailInSession = ref(JSON.parse(localStorage.getItem(`riskList${state.uuid}`)))
    const productDetailInSession = ref({})
    const popVisible = ref(false)
    const docRef = ref()
    const barRef = ref()
    let initialDistance = 100
    let baseWidth = 100 // 初始宽度
    const imageWidth = ref(baseWidth)
    const indexBarList = reactive(['保障计划', '保障范围', '产品介绍'])
    const goAppLet = () => {
      location.href = process.env.VUE_APP_APPSITE_URL
    }
    const processDetailsFun = function(productId){
      request(getExtendUri('processDetails'), {id:productId}).then(res => {
        if (res.code === 1) {
          store.dispatch('insured/changeInsureJSInfo',{
            processDetailsList: res.result.processDictList
          })
        } else {
          message['error'](res.message)
        }
      })
    }
    const initProductInfo = async (uuid) => {
      // await request(getExtendUri('postSelectById'), {
      await request(getExtendUri('getLinkData'), {
        data: {
          // id: router.currentRoute.value.query['productId'],
          id: router.currentRoute.value.query['shareId'],
          // tradeNo: uuid
        }
      }).then(async res => {
        state.uuid = store.getters['insured/getTradeNo']
        if (res.code === 1 && res.result) {
          // 这个需要判断跳转位置
          if(res.result.productInfoSkip.listPageSkipType === '2'){
            request(getExtendUri('getH5LinkData'), {
              data: {
                id: router.currentRoute.value.query['shareId'],
                pageType: '1'
              }
            }).then(resq =>{
              if(resq.code === 1){
                state.isHaveGetProduct = -1
                window.location.assign(resq.result.jumpUrl)
              }else{
                state.isHaveGetProduct = -1
                message['error'](resq.message)
              }
            })
          }else{
            // 续保判断
            const continuousRenew = router.currentRoute.value.query?.continuousRenew
            if(continuousRenew && continuousRenew === '1'){
              let obj = res.result.productInfo.riskList.filter(item => item.riskSign === '1')[0]
              obj.calculateFactor.filter(item => item.factorType === 'Plan')[0].factorValueList.forEach(factor => {
                factor.defaultShow = factor.value === '2' ? 'Y' : 'N'
              })
            }

            // 文浩说一定有channelCode
            // 判断 是否有未完成的订单
            // isHavePolicyInDoing(res.result.channelInfo ? res.result.channelInfo.channelCode : '')
            state.isHaveGetProduct = 1
            let initDate = undefined
            handelInsurancePlan(res.result.productInfo.riskList)
            state.productInfoSkip = res.result.productInfoSkip

            store.dispatch('insured/changeInsureJSInfo', {productDetailData: JSON.stringify(res.result)})
            state.progressId = res.result.productInfo.progressId
            processDetailsFun(state.progressId)
            if(state.progressId) {
              nowPathShow(state.progressId, '1', uuid)
            }
            //保存是否开启js校验
            if (res.result.productInfo.jsCheckStatus === 'Y' && res.result.productInfo.jsCheckUrl) {
              store.dispatch('insured/changeInsureJSInfo', {jsCheckStatus: 'Y'})
              store.dispatch('insured/changeInsureJSInfo', {jsCheckUrl: res.result.productInfo.jsCheckUrl})
              // sessionStorage.setItem('jsCheckStatus', 'Y')
              // sessionStorage.setItem('jsCheckUrl', res.result.productInfo.jsCheckUrl)
              await loadRuleScriptResourceList([res.result.productInfo.jsCheckUrl])
              // eslint-disable-next-line no-undef
              if(window.getInitDate) initDate = getInitDate()
              if (initDate && initDate[3]) {
                store.dispatch('insured/changeInsureJSInfo', {TNUMBER: initDate[3]})
              }
            } else {
              store.dispatch('insured/changeInsureJSInfo', {jsCheckStatus: 'N'})

            }
            store.dispatch('insured/changeInsureJSInfo', {productId: res.result.productInfo.productId})
            state.productInfo = res.result.productInfo || {}
            state.isPhone = isBDT && state.productInfo.shareChat==='Y'
            const typeCode = router.currentRoute.value.query?.typeCode
            if(typeCode !== '1' || res.result.productInfoSkip.detailPageSkipType === '2' ){
              state.nextTitle = res.result.productInfoSkip.detailPageSkipButtonName || state.nextTitle
            }else{
              state.nextTitle = '立即购买'
              // if (typeCode === '1'&& res.result.productInfoSkip.detailPageSkipType === '4'){
              //   state.nextTitle = '立即跳转'
              // }else{
              //   state.nextTitle = '立即购买'
              // }
            }
            document.title = state.productInfo.productName || '产品名称'
            state.productInfo.productDescription = state.productInfo.productDescription.replaceAll('\n', '<br>')
            state.prem_total = state.productInfo.detailPagePremiumCountFlag === 'N' ? state.productInfo.productStartPrice : '0.0元'
            state.productInfo['riskList'].forEach((riskItem) => {
              // 投保须知、产品条款、免责声明
              riskItem.coverageDataList.forEach(riskType => {
                if(((riskItem.riskSign !== '1' && riskItem.isSelected > 0) || riskItem.riskSign === '1') && riskType.fileDataType !== '7' && riskType.fileDataType !== '12' && riskType.fileDataType !== '6'){
                  state.coverageDataList.push(riskType)
                }
              })
              // state.coverageDataList.push(...riskItem.coverageDataList.filter(item => ['1', '2', ' 3', '4', '5', '8', '9', '10', '11', '13', '14', '16', '17', '18', '19', '21','22'].includes(item.fileDataType)))
              if (riskItem.riskSign === '1') {
                // 理赔流程、常见问题
                state.imageDataList.push(...riskItem.coverageDataList.filter(item => ['7', '12'].includes(item.fileDataType)))
                state.imageDataList.forEach(item => {
                  item.isPdf = item.fileDataTypeName.slice(-4) == '.pdf' ? true : false
                })
                // 产品介绍
                const fileTypeList = riskItem.coverageDataList.filter(item => ['6'].includes(item.fileDataType))
                if (!fileTypeList.length) return
                state.imageDataSexList.push(...fileTypeList[0].fileList.map(item => item))
              }
              // 如果有这个值，说明已经挂载了外部js，去执行一下获取日期限制
              // changeProduct(initDate)
            })
            // 主险和附加险 会有相同类型的文件类型 整合一下
            state.coverageDataList = getCoverageDataList(state.coverageDataList)
            state.imageDataList.sort(compare('fileType'))
            state.loading = false
          }
        } else {
          state.isHaveGetProduct = -1
          message['error'](res.message)
        }
      })
      // 初始化默认0位置
    }
    const getCoverageDataList = (list) =>{
      let coverageDataList =   list.reduce((acc, cur) =>{
        const fileDataType = cur.fileDataType
        const index = acc.findIndex(item => item.fileDataType === cur.fileDataType)
        if(index !== -1){
          acc[index].fileList.push(...cur.fileList)
        }else{
          acc.push({fileDataType, fileDataTypeName: cur.fileDataTypeName, fileList: [...cur.fileList]})
        }
        return acc
      },[])
      for (let i = 0; i < coverageDataList.length; i++) {
        if(coverageDataList[i].fileList.length){
          let arr = coverageDataList[i].fileList.filter(m => m.mustReadOrder !== 2)
          if (!arr.length){
            coverageDataList.splice(i, 1)
            i--
          }else{
            coverageDataList[i].fileList = arr
          }
        }
      }
      return coverageDataList
    }

    // 附加险选中/不选中之后展示条款须知
    const getAdditionalCoverageData = (data, type) =>{
      data.coverageDataList.forEach(riskType => {
        if(riskType.fileDataType !== '7' && riskType.fileDataType !== '12' && riskType.fileDataType !== '6'){
          if(type){
            state.coverageDataList.push(riskType)
          }else{
            let fileList = state.coverageDataList.filter(cov => cov.fileDataType === riskType.fileDataType)[0].fileList
            riskType.fileList.forEach(risk =>{
              const index = fileList.findIndex(file => file.id === risk.id)
              if(index > -1){
                fileList.splice(index,1)
              }
            })
          }
        }
      })
      state.coverageDataList = getCoverageDataList(state.coverageDataList)
      console.log('选中/不选的: ', state.coverageDataList)

    }


    const compare = (property) => {
      return function (a, b) {
        let value1 = a[property]
        let value2 = b[property]
        return value1 - value2  //降序只需要  return value2- value1
      }
    }
    // 获取投保计划要素值
    const handelInsurancePlan = (list) => {
      if (list && list.length) {
        list.forEach(item => {
          state.effectDay = item.premiumTrialCalculationDate==='1'? item.effectDay : 0
          state.ageDiffValue = item.ageDiffValue ? item.ageDiffValue : '0'
          if (item.riskSign === '1' && item.calculateFactor && item.calculateFactor.length) {
            // 获取年龄类型并且在页面显示的
            // state.InsuranceNoPlanList = item.calculateFactor.filter(cal => ['TextAge', 'Insurancedate'].includes(cal.factorType) && cal.isShow === 'Y')[0]
            state.InsuranceNoPlanList = item.calculateFactor.filter(cal => ['TextAge'].includes(cal.factorType) && cal.isShow === 'Y')[0]
            state.insurancePlanList = item.calculateFactor.filter(cal => cal.factorType === 'Plan' && cal.isShow === 'Y')[0]
            state.factorType = state.InsuranceNoPlanList?.factorType || state.insurancePlanList?.factorType
            item.calculateFactor.forEach((type) => {
              if (type.factorType === 'Plan') {
                type.isShow = 'N'
                // item.calculateFactor.splice(index, 1)
              }
            })
          }
        })
      }

    }
    onBeforeMount(async () => {
      store.dispatch('insured/changeInsureJSInfo', {'shareId': router.currentRoute.value.query['shareId']})
      await initProductInfo(state.uuid)
      setTimeout(() => state.isHaveProductInfo = true, 350)
      productDetailInSession.value = JSON.parse(JSON.stringify(store.getters['insured/getRiskList'])) || {}
    })
    const onCloseDrawer = () =>{
      console.debug('返回')
      // window.location.reload()
      if (history.scrollRestoration) {
        history.scrollRestoration = 'manual'
      }
    }
    window.addEventListener('popstate', onCloseDrawer, false)
      onMounted(() => {
        const userId = router.currentRoute.value.query.userId ?? ''
        if (userId) {
          store.commit('insured/setUserId', userId)
        }
        // nextTick(() => {
        //   var element = document.getElementsByClassName("ant-drawer-body")[0]
        //   var width = element.offsetWidth;
        // })
        state.stickyHeight = headerRef.value.height
        if (isIOS() && (isWeiXin() || isQyWeiXin())) {
          window.history.pushState({}, 'title', '#')
        }
      state.isHaveMounted = true
      // 创建监视滚动条
      window.addEventListener('scroll', scrollListenerHandler, true)
      state.isToPlay = setInterval(() => {
        if (state.isHaveGetProduct === -1) {
          clearThis()
        } else {
          if (state.isHaveMounted && state.isHaveProductInfo) {
            if (JSON.stringify(state.insurancePlanList) !== '{}' && state.insurancePlanList) {
              handelActive(state.insurancePlanList.factorValueList[0], 0)
              clearThis()
            } else {
              if (JSON.stringify(state.InsuranceNoPlanList) !== '{}' && state.InsuranceNoPlanList) {
                handleNoPlanInsurance(state.InsuranceNoPlanList.factorValueList, state.factorType)
              }
              clearThis()
            }
          }
        }
      }, 300)




    })
    let bean = null
    const hideBuybtnBychannelCode = (channelCode) => {
      if (channelCode === 'zongtuo') {
        state.hideBtnFlag = false
      }
    }
    const isHavePolicyInDoing = async (channelCode) => {
      // console.log('zx----------', channelCode)
      hideBuybtnBychannelCode(channelCode)
      if (channelCode !== 'HBWEB') {
        bean = await getRouterJumpBean(router.currentRoute.value.query['shareId'])
        if (bean) {
          state.detailModal = `存在未完成的订单，单号${bean.tradeNo},是否继续完成该订单？`
          state.isHaveNotDone = true
        }
      }
    }
    const jumpEvent = () => {
      state.isHaveNotDone = false
      if (bean) {
        router.push({
          path: bean.path,
          query: {
            tradeNo: bean.tradeNo,
            shareId: bean.shareId,
            sort:bean.sort,
            id: bean.id,
            detailType: '1'
          }
        })
      }
    }
    const cancelEvent = () => {
      state.detailModal = ''
      state.isHaveNotDone = false
    }
    // todo 用一个定时器去轮询

    const clearThis = () => {
      clearInterval(state.isToPlay)
      state.isToPlay = null
    }
    const onPremChange = (flag, t_prem, result) => {
      console.log(t_prem, 't_prem')
      if(result && result.code === 0){
        state.payStatus = 0
        state.payMessage = result.message
      }else{
        state.payStatus = 1
      }
      // 特殊产品的保费展示
      if(window.checkDFPlanSwitch && state.factorValue){
        state.spinning = false
        state.prem_total = window.checkDFPlanSwitch(state.productInfo.productCode, state.factorValue)
      }else{
        switch (flag) {
          case 0:
            state.spinning = true
            break
          case 1:
            setTimeout(() => {
              state.spinning = false
            }, 500)
            state.prem_total = state.productInfo.detailPagePremiumCountFlag === 'N' ? state.productInfo.productStartPrice : t_prem + '元'
            console.log(state.productInfo.productStartPrice, 'state.productInfo.productStartPrice2')
            break
          default:
            setTimeout(() => {
              state.spinning = false
            }, 500)
            console.log(state.productInfo.productStartPrice, 'state.productInfo.productStartPrice1')
            state.prem_total = state.productInfo.detailPagePremiumCountFlag === 'N' ? state.productInfo.productStartPrice : '0.0元'
        }
      }
    }
    const sharing = () => {
      message['info']('sharing')
    }
    const contacting = () => {
      message['info']('contacting')
    }
    const showPopPage = (item) => {
      state.fileDrawerList = []
      if (item.fileList.length === 0) return
      let height = item.fileList.length < 10 ? (item.fileList.length < 5 ? (item.fileList.length * 11) : (item.fileList.length * 8)) : 100
      state.isHeight = item.fileList.length > 1?  (height + '%') : '17%'
      popVisible.value = true
      state.pdfTitle = item['fileDataTypeName']
      item.fileList && item.fileList.forEach(d => {
        Object.assign(d, {
          isImg: false
        })
        state.fileDrawerList.push(d)
      })

      nextTick(() => {
        let a = document.getElementsByClassName('ant-drawer-header-title')
        a[0].style.flexDirection = 'row-reverse'
      })
    }
    const closePopPage = () => {
      popVisible.value = false
    }
    const pop = ref()
    const nextPage = function () {
      console.log('------',state.uuid )
      store.commit('rule/updateRuleJSInfo', {nextValue: '1'})
      const typeCode = router.currentRoute.value.query?.typeCode
      if(state.productInfoSkip.detailPageSkipType === '2'){
        nextTick(() => {
          if (!pop.value.type) {
            pop.value.showPage(state.uuid)
          } else {
            pop.value.type = ''
          }
        })
      }else if((state.productInfoSkip.detailPageSkipType === '3' || state.productInfoSkip.detailPageSkipType === '4') && typeCode !== '1'){
        testjs()
      }else {
        if (typeCode === '1' && state.productInfoSkip.detailPageSkipType === '4') {
          nextTick(() => {
            if (!pop.value.type) {
              pop.value.showPage(state.uuid)
            } else {
              pop.value.type = ''
            }
          })
        } else {
          let TKSelected = JSON.parse(store.getters['insured/getRiskList']).riskList.filter(item => item.riskSign === '1')[0]
          if (TKSelected.supRiskCode === 'AV1') {
            const FJSelect = JSON.parse(store.getters['insured/getRiskList']).riskList.filter(item => item.supRiskCode === 'U24S')[0]
            if (FJSelect && FJSelect.isSelected) {
              let flag = newPremiumInTK(TKSelected, FJSelect.isSelected, FJSelect)
              if (flag && !flag.type) {
                state.isIdentical = true
                state.statusModal = flag.message
                return
              }
            }
          }
          if (window.checkDHFactorSelect) {
            const riskList = JSON.parse(store.getters['insured/getRiskList']).riskList.filter(item => item.riskSign === '1')[0].calculateFactor
            const dutyList = JSON.parse(store.getters['insured/getRiskList']).riskList.filter(item => item.riskSign === '1')[0].dutyList
            const riskItemList = JSON.parse(store.getters['insured/getRiskList']).riskList.filter(item => item.supRiskCode === '1320027')
            let flag = window.checkDHFactorSelect(riskList, dutyList, riskItemList)
            if (flag && !flag.type) {
              state.isIdentical = true
              state.statusModal = flag.message
              return
            }
          }
          if (state.payStatus !== 1) {
            state.isIdentical = true
            state.statusModal = state.payMessage
            return
          }
          nextTick(() => {
            if (!pop.value.type) {
              pop.value.showPage(state.uuid)
            } else {
              pop.value.type = ''
            }
          })
        }
      }
    }
    const getTarget = () => {
      return document.getElementById('product_top')
    }
    const insurePlanRef = ref()
    const factorObject=ref()
    // 投保计划点击事件
    const handelActive = (factor, index) => {
      state.factorValue = factor.value
      nextTick(() => {
        store.dispatch('insured/changeInsureJSInfo', {initPlanEvent: false})
        factorObject.value={...factor}
        state.acticeNum = index
        insurePlanRef.value.changePlanIndex(index)
        // 已经配置js文件
        if (window.getInitDateByPlan) {
          // eslint-disable-next-line no-undef
          let res = getInitDateByPlan(factor.value)
          if (res) {
            insurePlanRef.value.changeDateRange(res, state.factorType)
            // 有无计划都需要存一下年龄限制
            store.dispatch('insured/changeInsureJSInfo', {
              limitDate: res
            })
          }
        }else {
          handleNoPlanInsurance(state.InsuranceNoPlanList?.factorValueList, state.factorType)
          let res = getInsurePlan(state.InsuranceNoPlanList?.factorValueList)
          if (res) {
            insurePlanRef.value.changeDateRange(res, state.factorType)
          }
        }
        store.dispatch('insured/changeInsureJSInfo', {initPlanEvent: true})
      })
    }
    const handleNoPlanInsurance = (list, factorType) => {
      const data = getInsurePlan(list, factorType)
      if (data) {
        insurePlanRef.value.changeDateRange(data,state.factorType)
        // 有无计划都需要存一下年龄限制，主要针对一下寿险没有计划的情况
        store.dispatch('insured/changeInsureJSInfo', {
          limitDate: data
        })

      }
    }

    // 获取投保计划的方法
    const getInsurePlan = (list, factorType) => {
      console.log('获取投保计划的方法=======>', list)
      if(list === undefined) return
      const min =  getMinAge(list)
      const max = getMaxAge(list)
      state.effectDay = min.minAge=== '0' && min.mixType==='Y'? 0 : parseInt(state.effectDay)
      store.dispatch('insured/changeInsureJSInfo', {TNUMBER: state.effectDay })
      store.dispatch('insured/changeInsureJSInfo', {AGEDIFF: state.ageDiffValue })
      let tem = getDefaultAge(list,'1988-08-08',state.effectDay)
      const res = factorType === 'Insurancedate' ?showInsurancedateDataMin(min.minAge, min.mixType, max.maxAge, max.maxType, state.effectDay) : showDataMin(min.minAge, min.mixType, max.maxAge, max.maxType, state.effectDay)
      let last = res.latestDate
      let early = res.earliestDate
      const obj = changeDataRangeByAgeDiff(state.ageDiffValue,last,early)
      last = obj.last
      early = obj.early
      // tem = '1988-08-08'
      if (tem && !isDateBetween(tem, early, last)) {
        tem = factorType === 'Insurancedate' ? early : last
      }
      console.debug('early', early)
      console.debug('last', last)
      const  data = [early, last, tem, state.effectDay, state.ageDiffValue]
      return data ? data : null
    }


    const getMinAge = (list) => {
      if(list === undefined) return
      const minList = list.filter(item => (item.value+ '').includes('D'))
      if(minList.length>0) {
        const str = minList[0].value.slice(0, (minList[0].value+'').indexOf('D'))
        return {
          minAge: str,
          mixType: 'D'
        }
      }else {
        const ageData =  list.reduce((x, y) => {
          let a = x.value + ''
          let b = y.value + ''
          a = a.indexOf(_f) > 0 ? Number(a.split(_f)[0]) : Number(a)
          b = b.indexOf(_f) > 0 ? Number(b.split(_f)[0]) : Number(b)
          return a < b ? x : y
        }).value
        return {
          minAge: ageData,
          mixType: 'Y'
        }
      }
    }
    const getMaxAge = (list) => {
      if(!list || !list.length) return
      const ageMaxList = list.filter(item => !(item.value+ '').includes('D'))
      // 表示都是Y
      if (ageMaxList.length > 0) {
        const ageData = ageMaxList.reduce((x, y) => {
          let a = x.value + ''
          let b = y.value + ''
          a = a.indexOf(_f) > 0 ? Number(a.split(_f)[1]) : Number(a)
          b = b.indexOf(_f) > 0 ? Number(b.split(_f)[1]) : Number(b)
          return a > b ? x : y
        }).value
        return {
          maxAge: ageData,
          maxType: 'Y'
        }
      }else{
        // 包括D 也包括有范围的且包含D
        let a = list[list.length-1].value
        let ageData = ''
        let type = 'D'
        if(a.indexOf(_f) > 0){
          const arr = a.split(_f)
          type = arr[1].indexOf('D') > 0 ? 'D' : 'Y'
          ageData = Number(arr[1].replace(type, ''))
        }else{
          ageData = Number(a.replace('D', ''))
        }
        return {
          maxAge: ageData,
          maxType: type
        }

      }
    }

    // 文档跳转
    const handelPdfTitle = (item) => {
      console.log(9999, item)
      // document.body.scrollTop = document.documentElement.scrollTop = 0
      const url = new URL(window.location)
      url.searchParams.set('openDialog', 'ok')
      window.history.pushState({}, '', url)
      state.pageSize = 1
      state.commonProblem = {
        type: item.uploadFile.substring(item.uploadFile.lastIndexOf('.') + 1),
        url: item.uploadFile,
        name: item.uploadFileName,
        cMapUrl: './pdf/cmaps/',
        cMapPacked: true
      }
      document.title = item.uploadFileName
      state.fileTypeName = ''
      popVisible.value = false
      state.imgVisible = true
      getPdfList(item)
    }
    const pdfList = ref([])
    // 点击获取对应的pdf文件
    const getPdfList = (item) =>{
      let data = {
        pdfUrl: item.uploadFile,
        fileName: item.uploadFileName,
        productCode: state.productInfo.productCode
      }
      request(getExtendUri('getPdfDecompose'), data).then(res =>{
        if(res.code === 1){
          nextTick(()=>{
            pdfList.value = res.result
          })
        }
      })
    }

    const openNewWindow = (url, title) => {
      const win = window.open('about:blank')
      win.document.title = title
      const iframe = document.createElement('iframe')
      iframe.src = url
      iframe.style.width = '100%'
      iframe.style.height = '100vh'
      iframe.style.margin = '0'
      iframe.style.padding = '0'
      iframe.style.overflow = 'hidden'
      iframe.style.border = 'none'
      win.document.body.style.margin = '0'
      win.document.body.appendChild(iframe)
    }
    // 常见问题
    const handelImageData = (data) => {
      // document.body.scrollTop = document.documentElement.scrollTop = 0
      const url = new URL(window.location)
      url.searchParams.set('openDialog', 'ok')
      window.history.pushState({}, '', url)
      state.pageSize = 1
      state.commonProblem = {
        type: data.fileList[0].uploadFile.substring(data.fileList[0].uploadFile.lastIndexOf('.') + 1),
        url: data.fileList[0].uploadFile,
        name: data.fileList[0].uploadFileName,
        cMapUrl: './pdf/cmaps/',
        cMapPacked: true
      }
      document.title = data.fileDataTypeName
      state.fileTypeName = data.fileDataTypeName
      state.imgVisible = true
      console.log('常见问题', data)
      getPdfList(data.fileList[0])
    }
    const scrollListenerHandler = () => {
      // 获取滚动条高度
      let scroll = document.documentElement.scrollTop || document.body.scrollTop
      let barObj = document.querySelectorAll('.van-index-bar__sidebar')
      let barIndex = document.querySelectorAll('.van-index-bar__index')
      if (!barIndex || !barIndex.length) return
      if (scroll > state.safeguardId && scroll < state.coverageId) {
        barObj[0].style.display = 'flex'
        handelCssText(barIndex, 0)
      } else if (scroll > state.coverageId && scroll < state.productId) {
        handelCssText(barIndex, 1)
      } else if (scroll > state.productId || scroll > state.coverageId) {
        handelCssText(barIndex, 2)
      } else {
        barObj[0].style.display = 'none'
      }
    }
    const handelCssText = (arr, num) => {
      arr.forEach((item, index) => {
        if (index !== num) {
          item.style.cssText = 'border-bottom: 0; color: #222;'
        } else {
          item.style.cssText = 'border-bottom: 1px solid #1890ff; color: #1890ff;'
        }
      })
    }

    const barIndexRef = ref()
    const xxxxxx = (key) => {
      if (document.querySelector(key) && document.querySelector(key).getBoundingClientRect()) {
        return document.querySelector(key).getBoundingClientRect().top
      }
      return false
    }
    const handelScollData = () =>{
      // state.safeguardId = document.querySelector('#safeguard_plan').getBoundingClientRect().top - 40
      state.safeguardId = xxxxxx('#safeguard_plan') !== false ? xxxxxx('#safeguard_plan') - 65  : null
      // state.productId = document.querySelector('#product_id').getBoundingClientRect().top - 95
      state.productId = xxxxxx('#product_id') !== false ? xxxxxx('#product_id') - 99 : null
      // if (document.querySelector('#coverage_id')&&document.querySelector('#coverage_id').getBoundingClientRect()){
      //   state.coverageId = document.querySelector('#coverage_id').getBoundingClientRect().top - 150
      state.coverageId = xxxxxx('#coverage_id') !== false ? xxxxxx('#coverage_id')  -70 : null
      // }
    }
    onBeforeUpdate(() => {
      nextTick(()=>{
        if(!state.safeguardId  || !state.productId || !state.coverageId){
          handelScollData()
        }
      })

      const url = new URL(window.location)
      if (url.searchParams.get('openDialog') === 'ok') {
        if (state.commonProblem.url) {
          document.title = state.commonProblem.uploadFileName
          console.log('这个是啥?----', state.commonProblem)
          state.imgVisible = true
        } else {
          state.imgVisible = false
          pdfList.value = []
          document.title = state.productInfo.productName
          const url = new URL(window.location)
          url.searchParams.delete('openDialog')
          window.history.replaceState({}, '', url)
          state.commonProblem = {
            url: null,
            cMapUrl: './pdf/cmaps/',
            cMapPacked: true
          }
        }
      } else {
        state.imgVisible = false
        pdfList.value = []
        document.title = state.productInfo.productName
        // state.imageWidth = baseWidth
        // state.scaleFirst = true
      }
    })
    onUnmounted(() => {
      document.title = state.productInfo.productName
      window.removeEventListener('scroll', scrollListenerHandler, false)
      window.removeEventListener('popstate', onCloseDrawer, false)
    })

    const scaleFun = () => {
      // return 'font-size: 1rem'
      let scale = 0.1
      return `transform:scale(${scale})`
    }
    watch(()=> state.imgVisible, ()=> {
      if (state.imgVisible) {
        // 开启手势缩放
      } else {
        // 关闭手势缩放
        state.imageWidth = baseWidth
        state.scaleFirst = true
      }
    })
    const testjs = () => {
      let share_data = {
        // shareurl: 'http://82.156.34.193/product/app/detail?shareId=a2a5694f9e61444ba267a0e9e5ac9af8',
        // sharetitle: 'zzw测试-京东安联安顺百万意外保障计划',
        // sharesecondtitle: 'zzw测试-京东安联安顺百万意外保障计划\n每次就诊0免赔，100%赔付',
        // sharephoto: 'https://product-15a5.obs.cn-north-4.myhuaweicloud.com/2023/03/27/ecd68195_af84_42ee_8c36_06012c8072d6/tr7dmkexnopln3hbgmr.jpg',
        // shareStatisticalType: '6',
        // itemId: '3d20498625eb0ef4d6150747f910450e', //productCode
        // islocalshare:true
        shareurl:window.location.href+'&typeCode=1',
        sharetitle: state.productInfo.shareTitle,
        sharesecondtitle: state.productInfo.shareSecondTitle,
        sharephoto: state.productInfo.sharePhoto,
        shareStatisticalType: '6',
        itemId: state.productInfo.productCode, //productCode
      }
      callHandler('share', share_data)
    }
    const insuranceErrorHandleOk = () =>{
      state.isIdentical = false
    }
    const VuePdfEmbedRef = ref()
    const handlePrePage = () =>{
      if(state.pageSize <= 1) return
      state.pageSize = state.pageSize - 1

    }

    const handleNextPage = () =>{
      if(state.pageSize >= state.totalPage) return
      state.pageSize = state.pageSize + 1
    }

    let startY = 0
    const touchstartS=(e)=>{
      startY = e.touches[0].clientY
    }
    const touchendS=(e)=>{
      let endY = e.changedTouches[0].clientY
      let deltaY = endY - startY

      if (deltaY > 0) {
        // 向下滑动
        if(state.pageSize <= 1) return
        state.pageSize = state.pageSize - 1
      } else if (deltaY < 0) {
        // 向上滑动
        if(state.pageSize >= state.totalPage) return
        state.pageSize = state.pageSize + 1
      }
    }
    onUnmounted(()=>{
      window.removeEventListener('touchstart', touchstartS, false)
      window.removeEventListener('touchend', touchendS, false)
    })
    const loaded = (pdf) => {
      let bottom_class = document.querySelector('.vue-pdf-embed')

      bottom_class.addEventListener('touchstart',touchstartS )

      bottom_class.addEventListener('touchend', touchendS)
      // state.thumbnails = []
      state.totalPage =  pdf.numPages

      // for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      //   pdf.getPage(pageNumber).then((page) => {
      //     console.log(1444444, page)
          // console.log(page)
          // const scale = 1
          // const viewport = page.getViewport({scale: scale})
          // const canvas = document.createElement('canvas')
          // const context = canvas.getContext('2d')
          // canvas.height = viewport.height
          // canvas.width = viewport.width
          // const renderContext = {canvasContext: context, viewport: viewport}
          // const renderTask = page.render(renderContext)
          // renderTask.promise.then(() => {
          //   let newElement = {
          //     path: canvas.toDataURL('image/png'),
          //     num: pageNumber,
          //     id: pdf.fingerprint + pageNumber
          //   }
          //   const hasSameNum = state.thumbnails.some((item) => item.num === newElement.num)
          //   if (!hasSameNum) {
          //     state.thumbnails.push(newElement)
          //     state.thumbnails.sort((a, b) => {
          //       return a.num - b.num
          //     })
          //   }
          // })
        // })
      // }
      // state.vis = false
    }
    const loadingFailed = (e) => {
      console.debug(e)
    }
    const rendered = () => {
      // this.$emit('rendered')
      // console.debug('renered---doing')
    }
    const renderingFailed = (e) => {
      console.debug('renderingFailed---error', e)
    }
    const closePop = () => {
      state.imgVisible = false
      const url = new URL(window.location)
      url.searchParams.delete('openDialog')
      window.history.replaceState({}, '', url)
    }
    const image = ref(null)

const onTouchStart = (event) => {
  if (state.scaleFirst) {
    state.scaleFirst = false
    var element = document.getElementsByClassName('ant-spin-container')[0]
    baseWidth = element.offsetWidth - 20
    imageWidth.value = baseWidth
    initialDistance = baseWidth
  }
  console.log(event, 'event444')
  if (event.touches.length === 2) {
    initialDistance = Math.hypot(
      event.touches[0].pageX - event.touches[1].pageX,
      event.touches[0].pageY - event.touches[1].pageY
    )
  }
}

const onTouchMove = (event) => {
  if (event.touches.length === 2) {
    const currentDistance = Math.hypot(
      event.touches[0].pageX - event.touches[1].pageX,
      event.touches[0].pageY - event.touches[1].pageY
    )
      console.log(currentDistance, 'currentDistance')
    if (currentDistance > initialDistance) {
      // 放大图片
      imageWidth.value += 10
    } else if (currentDistance < initialDistance) {
      // 缩小图片，可添加边界条件判断防止过小
      if (imageWidth.value <= baseWidth) {
        return false
      }
      imageWidth.value -= 10
    }

    initialDistance = currentDistance
  }
}
const changeEouterFlag = (val) => {
  state.routerFlag = val
}
const onTouchEnd = () => {
  initialDistance = 0
}
    return {
      goAppLet,
      imageWidth,
      image,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      headerRef,
      pdfList,
      insuranceErrorHandleOk,
      productDetailInSession,
      indexBarList,
      barIndexRef,
      barRef,
      handelImageData,
      handelPdfTitle,
      insurePlanRef,
      handelActive,
      handleNoPlanInsurance,
      ...toRefs(state),
      pop,
      popVisible,
      docRef,
      onPremChange,
      sharing,
      contacting,
      showPopPage,
      closePopPage,
      nextPage,
      getTarget,
      handelInsurancePlan,
      openNewWindow,
      scaleFun,
      factorObject,
      jumpEvent,
      cancelEvent,
      testjs,
      isHavePolicyInDoing,
      getAdditionalCoverageData,
      loaded,
      loadingFailed,
      rendered,
      renderingFailed,
      handlePrePage,
      handleNextPage,
      VuePdfEmbedRef,
      touchstartS,
      touchendS,
      hideBuybtnBychannelCode,
      closePop,
      changeEouterFlag
    }
  },
  async beforeRouteLeave () {
    if (this.routerFlag !== true) {
      setTimeout(() => {
        location.href = process.env.VUE_APP_APPSITE_URL
      }, 100)
      return false
    }
  }
})
</script>
