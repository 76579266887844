// 产品详情页面开始投保流程需要的参数
const insured = {
  namespaced: true,
  state: () => ({
    // 小程序用户id
    userId: '',
    // 产品Id
    productId: '',
    // 产品链接 后面参数
    shareId: '',
    // 流水号
    uuid: '',
    tradeNo: '',
    // 已选择的
    riskList: {},
    // 产品详情数据
    productDetailData: {},
    // 是否检查js， 默认为n
    jsCheckStatus: 'N',
    // 外部 url
    jsCheckUrl: '',
    // 从外部js初始化计划标识， 默认f
    initPlanEvent: false,
    // 外部js获取 T+n的 n 默认0
    TNUMBER: 0,
    AGEDIFF: 0,
    routerJump: {},
    healthRouterJump: {}, // 健康告知
    // 提交的核保数据
    contInfoDetail: {},
    // 阅读条款
    /**
     *  id: d.id,
     *  uploadFile: d.uploadFile,
     *  name: d.fileName,
     *  fileName: d.uploadFileName,
     *  type: d.uploadFile.substring(d.uploadFile.lastIndexOf('.') + 1),
     *  status: '未读'
     */
    clausePdfList: [],
    submitDate: {},
    healthShowData: [],
    relationToappnt: '',
    // 1 核保 2不核保，默认2
    isUnderwriting: '2',
    // 选择的签名的对象
    selectSignObj: {},
    // 签名获取的全部数据
    signListObj: {},
    // 是不是分享出去的远程链接
    userFlag: false,
    // 是否短信校验
    verifySMSFlag: [],
    // 可选责任列表
    PrimaryDutyList:[],
    // 选择的年龄范围
    limitDate: [],
    // 健康告知的标识
    healthToldIdentifying : [],
    // 已选的可选责任
    optionalDutyList: [],
    // 手机号验真
    verifyCodeList: [],
    // 流程节点详情列表
    processDetailsList: [],
    // 当前流程节点
    currentProcessNode: {}
  }),
  mutations: {
    updateInsureJSInfo(state, payload) {
      if (payload) {
        Object.keys(payload).forEach(key => {
          if (key === 'productDetailData'|| key === 'riskList' ||  key === 'routerJump'  || key === 'multipleRiskList'){
            state[key] = JSON.parse(payload[key])
          } else {
            state[key] = payload[key]
          }
        })
      }
    },
    changeInsureReadState(state, payload) {
      if (state.clausePdfList.length > 0){
        for (let i = 0; i < state.clausePdfList.length; i++) {
          if (typeof payload === 'object' && state.clausePdfList[i].id === payload.id) {
            state.clausePdfList[i].status = payload.status
          } else if (typeof payload === 'string') {
            state.clausePdfList[i].status = payload
          }
        }
      }
    },
    saveDate(state, payload) {
      state.submitDate = payload
    },
    saveSignDate(state, payload) {
      state.selectSignObj = payload

    },
    // 告知问卷的
    saveHealthShowData(state, payload) {
      // 是否有相同id的值
      if(state.healthShowData.length > 0){
        for (let i = 0; i < state.healthShowData.length; i++) {
          if(state.healthShowData[i][0].id === payload[0].id){
            state.healthShowData.splice(i, 1)
            state.healthShowData.push(payload)
            break
          }else{
            state.healthShowData.push(payload)
          }
        }
      }else{
        state.healthShowData.push(payload)
      }
    },
    // 告知问卷 被与保的关系
    saveRelationToappnt(state, payload) {
      state.relationToappnt = payload
    },
    updateSignList(state, payload){
      state.signListObj = payload
    },
    updateUserFlag(state, payload){
      state.userFlag = payload
    },
    setPrimaryDutyList(state,payload){
      state.PrimaryDutyList= payload
    },
    updateHealthJSInfo(state, payload) {
      if (payload) {
        Object.keys(payload).forEach(key => {
          if (key === 'healthRouterJump' ){
            state[key] = JSON.parse(payload[key])
          } else {
            state[key] = payload[key]
          }
        })
      }
    },
    // 存健告标识
    saveHealthToldIdentifying(state, payload) {
      if(state.healthToldIdentifying.length){
        const index = state.healthToldIdentifying.findIndex(item => item.tradeNo === payload.tradeNo && item.moduleType === payload.moduleType && item.moduleIndex === payload.moduleIndex)
        if(index !== -1){
          state.healthToldIdentifying.splice(index, 1, payload)
        }else{
          state.healthToldIdentifying.push(payload)
        }
      }else{
        state.healthToldIdentifying.push(payload)
      }
    },
    // 存可选责任
    setOptionalDutyList(state, payload) {
      state.optionalDutyList= payload
    },
    // 存手机号对应的验证
    setVerifyCodeList(state, payload) {
      let code = state.verifyCodeList.filter(item =>item.attributive === payload.attributive)
      if(code.length){
        code[0].mobile = payload.mobile
      }else if(payload === ''){
        state.verifyCodeList = []
      }else{
        state.verifyCodeList.push(payload)
      }
    },
    // 存小程序userId
    setUserId(state, payload) {
      state.userId = payload
    },
    setProductId(state, payload) {
      state.productId = payload
    }
  },
  actions: {
    // 注意该方法的对象结构
    changeInsureJSInfo ({ commit }, data) {
      commit('updateInsureJSInfo', data)
    },
    changeHealthJSInfo ({ commit }, data) {
      commit('updateHealthJSInfo', data)
    },
    changeHealthToldIdentifying ({ commit }, data) {
      commit('saveHealthToldIdentifying', data)
    },

    // 修改条款阅读状态的
    // {id:id, statue:'未读'/'已读'}
    changeInsureReadState({ commit }, data) {
      commit('changeInsureReadState', data)
    },
    // getClausePdfAllReadState({ commit }){
    //   commit('getClausePdfAllReadState')
    // }
    saveSubmitDate({ commit }, data) {
      commit('saveDate', data)
    },
    saveSignDate({ commit }, data) {
      commit('saveSignDate', data)
    },
    saveHealthShowData({ commit }, data) {
      commit('saveHealthShowData', data)
    },
    saveRelationToappnt({ commit }, data) {
      commit('saveRelationToappnt', data)
    },
    updateSignList({ commit }, data) {
      commit('updateSignList', data)
    },
    updateUserFlag({ commit }, data) {
      commit('updateUserFlag', data)
    },
    updatePrimaryDutyList({commit},data){
      commit('setPrimaryDutyList',data)
    },
    saveOptionalDuty({commit},data){
      commit('setOptionalDutyList',data)
    },
    saveVerifyCode({commit},data){
      commit('setVerifyCodeList',data)
    }
  },
  getters: {
    getInusredJSInfo(state) {
      return state
    },
    getContInfoDetail(state){
      return state.contInfoDetail
    },
    getProductId(state) {
      return state.productId
    },
    getShareId(state) {
      return state.shareId
    },
    getUuid(state) {
      return state.uuid
    },
    getTradeNo(state) {
      return state.tradeNo
    },
    getRiskList(state) {
      return JSON.stringify(state.riskList)
    },
    getMultipleRiskList(state) {
      return JSON.stringify(state.multipleRiskList)
    },
    getProductDetailData(state) {
      return JSON.stringify(state.productDetailData)
    },
    getJsCheckStatus(state) {
      return state.jsCheckStatus
    },
    getJsCheckUrl(state) {
      return state.jsCheckUrl
    },
    getInitPlanEvent(state) {
      return state.initPlanEvent
    },
    getTNUMBER(state) {
      return state.TNUMBER
    },
    getAGEDIFF(state){
      return state.AGEDIFF
    },
    getRouterJump(state){
      return JSON.stringify(state.routerJump)
    },
    getHealthRouterJump(state){
      return JSON.stringify(state.healthRouterJump)
    },
    getClausePdfList(state){
      return state.clausePdfList
    },
    getClausePdfAllReadState(state){
      if (state.clausePdfList?.length > 0){
        const unReadArr = state.clausePdfList.filter(item => item.status === '未读')
        if (unReadArr.length > 0) {
          return false
        }
      }
      return true
    },
    getSaveDate(state){
      return state.submitDate
    },
    getSignListDate(state){
      return state.signListObj
    },
    getSignDate(state){
      return state.selectSignObj
    },
    userSignFlag(state){
      return state.userFlag
    },
    // 这个是告知问卷的缓存
    getHealthShowData(state){
      return state.healthShowData
    },
    // 被与保的关系
    getRelationToappnt(state){
      return state.relationToappnt
    },
    // 获取短信校验状态
    getVerifySMSFlag(state){
      return state.verifySMSFlag
    },
    // 获取责任可选范围
    getDateRange(state){
      return state.limitDate
    },
    // 获取责任可选范围
    isUnderwriting(state){
      return state.isUnderwriting
    },
    // 获取健告标识
    getHealthToldIdentifying(state){
      return state.healthToldIdentifying
    },
    // 获取可选责任
    getOptionalDutyList(state){
      return state.optionalDutyList
    },
    // 获取手机号是否校验
    getVerifyCode(state){
      return state.verifyCodeList
    },
    // 获取小程序userId
    getUserId(state) {
      return state.userId
    },
    getProcessDetailsList(state) {
      return state.processDetailsList
    },
    getCurrentProcessNode(state) {
      return state.currentProcessNode
    }
  }
}
export default insured
