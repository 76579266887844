<template>
  <div class="detail_class">
    <div class="example" v-if="loadingShow">
      <a-spin size="large"/>
    </div>
    <div style="height: 1px"></div>
    <div class="content">
      <div v-for="(item,index) in Object.keys(showData)" :key="index">
        <div v-for="(detail, index2) in showData[item]" :key="detail" class="confirmBox">
          <div class="confirmTitle">
            <span>{{ showData[item].length > 1 ? detailData[item].label + (index2 + 1) : detailData[item].label }}</span>
          </div>
          <div class="confirmDiv">
            <div v-for="(child,index3) in Object.keys(detail)" :key="index3">
              <p class="confirmP">
                <span class="title">{{detailData[item][child]}}:</span>
                <span>{{detail[child]}}</span>
                <span v-if="child==='premium'">元</span>
              </p>
            </div>
            <p v-if="item === 'insuranceInfo'" class="product_class">本计划由“{{supName}}”承保并负责理赔</p>
          </div>
        </div>
      </div>
    </div>
    <div class="tip_class">
      <span>温馨提示: 此保单内容涉及个人信息, 请谨慎转发</span>
    </div>
    <div class="detail_footer">
      <div class="prem">
        <div>保费:&nbsp; <span class="prem_color">{{premium}}</span><span> 元</span></div>
        <div class="look_class" v-if="okShow" @click="nextJump">继 续</div>
        <van-button
          v-if="underwritingStatus === '2' || underwritingStatus === '5'"
          class="ok-button"
          size="small"
          plain
          @click="seeDetail"
        >
          查看详情
        </van-button>
      </div>
    </div>
  </div >

</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from 'vue'
import {request} from '@/utils/request'
import router from '@/router'
import {formatNumberToFix2, getUrlParams} from '@/utils/utlis'
import {getExtendUri} from '@/utils/request_extend'
import store from '@/store'
import {message} from 'ant-design-vue'
import {nowPathShow} from '@/utils/product_process'
import { Button } from 'vant'
export default defineComponent({
  components:{
    [Button.name]: Button,
  },
  setup() {
    const detailData = reactive({
      policyInformation:{
        label: '保单信息',
        prtNo: '投保单号',
        contNo: '保单号',
        cvaliDate: '生效时间',
        transEndDate: '终止时间',
        premium: '保费'
      },
      applicant:{
        label: '投保人信息',
        appntName: '姓名',
        appntIdType_tagName: '证件类型',
        appntIdNo: '证件号码',
        appntBirthday: '出生日期',
        appntSex_tagName: '性别',
        mobile: '手机号码',
        email: '电子邮箱',
        postalAddress: '联系地址',
      },
      insureds:{
        label: '被保人信息',
        relationToAppnt_tagName: '与投保人关系',
        name: '姓名',
        idType_tagName: '证件类型',
        idNo: '证件号码',
        birthday: '出生日期',
        sex_tagName: '性别',
      },
      beneficiarys:{
        label: '受保人信息',
        bnfType: '受益人'
      },
      insuranceInfo:{
        label: '保障信息',
        productName: '产品名称',
        period: '保险期间'
      }
    })
    const state = reactive({
      productId: '',
      detailObj: {},
      loadingShow: false,
      underwritingStatus: router.currentRoute.value.query.underwritingStatus,
      premium: '0.00',
      supName: '',
      showData: {
        policyInformation:[],
        applicant:[],
        insureds: [],
        beneficiarys: [],
        insuranceInfo: []
      },
      okShow: false
    })
    // 获取详情数据
    const getDetailData = () =>{
      const token = router.currentRoute.value.query.token
      // let token = '01eb52b365474d47b65927c8791f9a33'
      const data = {
        id : router.currentRoute.value.query.id
      }
      request('/product-service/product/productInsured/bdt/api/queryInsuredDetailsFront?token='+token, {data}).then(res =>{
        state.detailObj = res.result
        state.okShow= ['0','2'].includes(res.result.paySataus) && ['0','1','4'].includes(res.result.underwritingStatus) && res.result.contStatus === '0'
        state.showData.policyInformation.push({
          prtNo: res.result.prtNo,
          contNo: res.result.contNo,
          cvaliDate: res.result.cvaliDate,
          transEndDate: res.result.transEndDate,
          premium: formatNumberToFix2(res.result.premium)
        })
        state.showData.applicant.push({
          appntName: res.result.applicant.appntName,
          appntIdType_tagName: res.result.applicant.appntIdType_tagName,
          appntIdNo: res.result.applicant.appntIdNo,
          appntBirthday: res.result.applicant.appntBirthday,
          appntSex_tagName: res.result.applicant.appntSex_tagName,
          mobile: res.result.applicant.mobile,
          email: res.result.applicant.email,
          postalAddress: res.result.applicant.postalAddress,
        })
        const keyList = Object.keys(detailData.insureds)
        const beneficiarysKey = Object.keys(detailData.beneficiarys)
        state.showData.insureds = new Array(res.result.insureds.length)
        state.showData.beneficiarys = new Array(res.result.beneficiarys.length)
        res.result.insureds.forEach((item,index) =>{
          state.showData.insureds[index] = {}
          keyList.forEach(key =>{
            if (key !== 'label') state.showData.insureds[index][key] = item[key] || ''
          })
        })
        res.result.beneficiarys.forEach((item,index) =>{
          state.showData.beneficiarys[index] = {}
          beneficiarysKey.forEach(key =>{
            if (key !== 'label') state.showData.beneficiarys[index][key] = item[key]
          })
        })
        state.showData.insuranceInfo = [{
          productName: res.result.productName,
          period: res.result.period
        }]
        state.premium= formatNumberToFix2(res.result.premium)
        state.supName= res.result.supName
      })
    }
    // 核保详情
    const seeDetail = () =>{
      let params = getUrlParams(window.location.href)
      params.id = state.detailObj.id
      router.push({
        path: '/app/underwriting',
        query: {
          ...params,
          underwritingStatus: router.currentRoute.value.query.underwritingStatus
        }
      })
    }
    // 根据订单状态跳转不同的页面
    const nextJump =  function () {
      const item = state.detailObj
      state.loadingShow = true
      //待支付中的继续
      if (item.payUrl) {
        window.location.href = item.payUrl
      } else {
        saveProductInfo(item)
      }
    }
    const saveProductInfo = (item) => {
      request(getExtendUri('getLinkData'), {
        data: {
          id: item.shareId
        }
      }).then(res => {
        if (res.code === 1 && res.result) {
          // 将详情信息存入
          store.dispatch('insured/changeInsureJSInfo', { productDetailData: JSON.stringify(res.result) })
          store.dispatch('insured/changeInsureJSInfo', { productId: res.result.productInfo.productId })
          let productDetailInfo = res.result
          state.productId = productDetailInfo ? productDetailInfo.productInfo.productId : ''
          getNodeState(item)
        }else if (res.code === 300) {
          window.location.assign(res.result)
        } else {
          state.loadingShow = false
          message['error'](res.message)
        }
      })
    }
    const getNodeState = (item) => {
      request(getExtendUri('getProcessByProductId'), {
        data: {
          productId: state.productId
        }
      }).then(res => {
        // 先将获取到的node节点存入缓存
        let nodeDetailData = res.result
        state.loadingShow = false
        // 遍历获取到的node节点
        nodeDetailData.forEach(nodeItem => {
          if (nodeItem.nextCode) {
            if (nodeItem.nextCode.indexOf('acknowledgementOrder') != -1) {
              nowPathShow(nodeItem.processId, nodeItem.sort, item.tradeNo)
              getRiskListFun(item.tradeNo)
              router.push({
                path: '/app/confirmation',
                query: {
                  tradeNo: item.tradeNo,
                  shareId: item.shareId,
                  processId: nodeItem.processId
                }
              })
            }
          }
          // }
        })
      })
    }
    // 根据接口获取riskLisk数据存入缓存
    const getRiskListFun = (tradeNo) => {
      request(getExtendUri('getCacheParameters'), {
        tradeNo: tradeNo
      }).then(res => {
        store.dispatch('insured/changeInsureJSInfo', { riskList: JSON.stringify(res.result.selectRisk) })
        store.dispatch('insured/changeInsureJSInfo', {submitDate: JSON.stringify(res.result.saveData)})
      })
    }
    onMounted(()=>{
      document.title = '订单详情'
      getDetailData()
    })

    return {
      nextJump,
      detailData,
      ...toRefs(state),
      getDetailData,
      seeDetail
    }
  }
})
</script>

<style scoped>
.detail_class{
  text-align: left;
  background: #f5f6fa;
}
.content{
  background: #f5f6fa;
}
.confirmBox {
  width: 100%;
  margin-top: 15px;
  background: #fff;
  padding: 10px 15px;
}
.confirmTitle{
  font-size: .91rem;
  border-bottom: 1px solid thistle;
  line-height: 2rem;
}
.confirmDiv{
  padding-top: 10px;
  line-height: 1.2rem;
  color: #8c8383;
}
.confirmP .title{
  display: inline-block;
  min-width: 8rem;
}
.detail_footer{
  width: 100%;
  height: 3.5rem;
  background-color: #fff;
  position: fixed;
  bottom: 0;
}
.prem{
  height: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -2px 5px 0 rgba(179, 179, 179);
}
.prem_color{
  color: #FF7D15;
  font-size: 1.2rem;
}
.look_class{
  background: #1677ff;
  color: #fff;
  font-size: .6rem;
  font-weight: bold;
  width: 5rem;
  text-align: center;
  line-height: 2rem;
  border-radius: 5px;

}
.ok-button{
  color: #FF7D15;
  height: 23px;
  width: 70px;
  font-size: 0.8rem;
  border: 1px solid #ff3333;
  text-align: center;
  border-radius: 10px;
  margin-left: 16px;
}
.tip_class{
  height: 7rem;
  background: rgb(245, 246, 250);
  text-align: center;
  line-height: 3rem;
  color: #8c8383;
  font-size: .5rem;
}
.product_class{
  width: 55%;
  margin: 30px auto 5px;
  text-align: center;
  color: #1677ff;
  font-size: .7rem;
}
.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}
</style>
