<template>
<!--  条款列表-->
<div
  :width="'65%'"
  :height="'100%'"
  placement="bottom"
  :visible="visible"
  :closable="false"
  :bodyStyle="{padding: 0}">

    <div class="drawer-title" v-show="name === defaultName">
      请仔细阅读以下文件，充分理解全部内容并确认。
    </div>
      <div>
        <table style="width: 100%;position: relative;border-collapse: collapse;">
          <template v-for="(item, index) in riskList"  :key="'openOne_' + index" >
            <tr style="height: 35px"  @click="openPdf(item)">
              <td>《{{ item.name }}》</td>
              <td style="text-align: right;padding-right: 20px;"><span :class="{'filestatus': item.status === '未读', 'filestatus2': item.status === '已读'}">{{ item.status }}</span></td>
            </tr>
          </template>
        </table>
      </div>
      <div id="tab-bar">
        <a-button class="tab-bar2" :style="{background:  yesNO ? '#92c3fb' : '#0078fc'}" :disabled="yesNO" @click="readAll" v-show="visible">确认已阅读并知晓全部内容</a-button>
      </div>
</div>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue'
import store from '../../../store'
import router from '../../../router'

export default {
  name: 'InsuredClausePdfView',
  setup() {
    const state = reactive({
      cashValueVisible:true,
      visible: false,
      tradeNo: store.getters['insured/getUuid'],
      source: '',
      name: '条款须知',
      defaultName: '条款须知',
      productInfo: [],
      // 去控制它是显示条框还是显示条款列表
      yuedufenshu: 0,
      // 条款列表
      riskList: [],
      // 条款
      lastReadItem: {},
      // 验证所有条款是否已阅读完毕的按钮是隐藏还是显示
      yesNO: true,
      hasSign : false,
      readCount: 0
    })
    onMounted(() =>{
      document.title = state.defaultName
      riskListData()
    })
    // 将传过来的值里面的所有条款计算出来
    const riskListData = function() {
      state.riskList = []
      // 如果有缓存先取缓存的值
      if (store.getters['insured/getClausePdfList'].length > 0){
        state.riskList = store.getters['insured/getClausePdfList']
      } else {
        // state.productInfo = JSON.parse(sessionStorage.getItem('productDetailData'))
        state.productInfo = JSON.parse(store.getters['insured/getProductDetailData'])
        state.productInfo.productInfo.riskList[0].coverageDataList.forEach(item => {
          if(!['6','7','12','99'].includes(item.fileDataType)){
            item.fileList.forEach(d => {
              if (d.uploadFile != null && d.uploadFileName != null) {
                state.riskList.push({
                  id: d.id,
                  uploadFile: d.uploadFile,
                  name: d.fileName,
                  fileName: d.uploadFileName,
                  type: d.uploadFile.substring(d.uploadFile.lastIndexOf('.') + 1),
                  status: '未读'
                })
              }
            })
          }
        })
      }
      if(state.hasSign){
        let obj = {}
        obj.uploadFile = ''
        obj.name = '现金价值表'
        obj.status = '未读'
        obj.type = 'xj'
        state.riskList.push(obj)
      }
    }
    // 阅读全部
    const readAll = () => {
      console.log('all read')
    }
    const openPdf = (item) => {
      router.push({
        path: '/app/InsuredClausePdfView',
        query: {
          id: item.id,
          url: item.uploadFile
        }
      })
    }
  return {
      ...toRefs(state),
    openPdf,
    readAll

  }
  }
}
</script>

<style scoped>

</style>
