/**
 * 瑞泰融e保互联网重大疾病保险
 *
 * 61-65 直接为趸交
 */

const rule = {
  '18-35': ['0', '3Y','5Y','10Y','15Y','20Y','30Y'],
  '36-45': ['0', '3Y','5Y','10Y','15Y','20Y'],
  '46-50': ['0', '3Y','5Y','10Y','15Y'],
  '51-55': ['0', '3Y','5Y','10Y'],
  '56-60': ['0', '3Y','5Y'],
}
let flag = '-'

const selectDetail = (calculateFactor, firstMainRiskInfoCopy) =>{
  const firstMainRiskInfoNew = JSON.parse(JSON.stringify(firstMainRiskInfoCopy))
  const age = calculateFactor.filter(item => item.factorType === 'TextAge')[0].factorValue
  const payTypeFacto = calculateFactor.filter(item => item.factorType === 'PayType')[0]
  const feeYearFacto = calculateFactor.filter(item => item.factorType === 'FeeYear')[0]
  const factorValueList = firstMainRiskInfoNew.calculateFactor.filter(item => item.factorType === 'PayType')[0].factorValueList
  let relation = []
  if(age <=65 && age >=61) {
    // 换成趸交
    payTypeFacto.factorValue = '0'
    payTypeFacto.factorValueName = '趸交'
    feeYearFacto.factorValue = '0'
    feeYearFacto.factorValueName = '趸交'
    const factorNewList = factorValueList.filter(item => item.value === payTypeFacto.factorValue)
    firstMainRiskInfoNew.calculateFactor.forEach(item =>{
      if(item.factorType === 'PayType') item.factorValueList = factorNewList
    })
    relation = factorNewList[0].valueRelationList
    relation[0].defaultShow = 'Y'
  }else{
    firstMainRiskInfoNew.calculateFactor.forEach(item =>{
      if(item.factorType === 'PayType') item.factorValueList = factorValueList
    })
    Object.keys(rule).forEach(key => {
      const list = key.split(flag)
      if(age >= list[0] && age <= list[1]) {

        relation = factorValueList.filter(item => item.value === payTypeFacto.factorValue)[0].valueRelationList
        for (let i = 0; i < relation.length; i++) {
          if(!rule[key].includes(relation[i].value)){
            relation.splice(i,1)
            i--
          }
        }
        if(!rule[key].includes(feeYearFacto.factorValue)){
          relation.forEach(item =>{
            item.defaultShow = 'N'
          })
          relation[0].defaultShow = 'Y'
          feeYearFacto.factorValue = relation[0].value
          feeYearFacto.factorValueName = relation[0].name
        }
      }
    })
  }
  if(relation && relation.length > 0){
    firstMainRiskInfoNew.calculateFactor.forEach(item =>{
      if(item.factorType === 'FeeYear') item.factorValueList = relation
    })
  }

  return firstMainRiskInfoNew


}

// window.entranceMethod = function (calculateFactor, firstMainRiskInfo){
//   selectDetail(calculateFactor, firstMainRiskInfo)
// }
export const entranceMethod = function (calculateFactor, firstMainRiskInfoCopy, firstMainRiskInfo){
  return selectDetail(calculateFactor, firstMainRiskInfoCopy, firstMainRiskInfo)
}
