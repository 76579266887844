<template>
<!--  详情阅读-->
<div>
<!--  <VuePdfEmbed v-if="['pdf','xls', 'xlsx'].includes(lastReadItem.type)"-->
<!--               :source="lastReadItem.uploadFile"-->
<!--              />-->
<!--  <img style="width:100%" v-if="['png','jpg', 'jpeg', 'gif', 'bmp'].includes(lastReadItem.type)" :src="lastReadItem.uploadFile" />-->
<!--  <product-cash-value v-if="lastReadItem.type==='xj'" v-model="tradeNo"  :visible="lastReadItem.type==='xj'" type="Slider"></product-cash-value>-->
<!--  <a-button class="tab-bar2" type="primary" @click="readPdf(lastReadItem)" v-show="visible">我已阅读并知晓同意</a-button>-->
</div>
</template>

<script>
import {reactive, toRefs} from 'vue'
import store from '../../../store'

export default {
  name: 'InsuredReadPdfView',
  setup() {
    const state = reactive({
      tradeNo: store.getters['insured/getUuid'],
      lastReadItem: {
        uploadFile: '',
        type: '',

      }
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>

</style>
