<template>
  <div class="order-page">
    <van-tabs type="card" class="tabCardClass" v-model:active="activeTab" @click-tab="onClickTabFirst" >
      <!--      online & offline-->
      <van-tab
        title="线上订单"
        name="online"
      >
        <div v-if="activeTab === 'online'">
          <!-- 在这里添加线上订单页面的内容 -->
          <van-tabs v-model:active="activeOnlineTab"
                    @click-tab="onClickOnlineTab">
            <van-tab v-for="(item, tabInx) in onLineTabs"
                     :key="tabInx"
                     :title="item.title"
                     :name="item.name"
                     >
              <div v-if="activeOnlineTab === item.name">
                <order-list-refresh :orders="orderList"></order-list-refresh>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </van-tab>
      <van-tab
        title="线下订单"
        name="offline">
        <div v-if="activeTab === 'offline'">
          <h2>这是线下订单页面</h2>
          <!-- 在这里添加线下订单页面的内容 -->
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {reactive, ref, toRefs} from 'vue'
import {List, Tab, Tabs, Icon} from 'vant'
import OrderListRefresh from './component/OrderListRefresh'

export default {
  components: {
    OrderListRefresh,
    [List.name]: List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
  },
  name: 'orderList',
  setup() {
    const state = reactive({
      radiusParam: '15px',
      // 选中背景和未选中文字
      tabSeletColor: '#318dff',
      // 未选中背景和选中文字
      tabNoSeletColor: '#ffffff',
      borderWidth: '1px',
      onLineTabs: [
        {title: '未完成', name: 'uncompleted'},
        {title: '待支付', name: 'unpaid'},
        {title: '已支付', name: 'paid'},
        {title: '全部订单', name: 'all'},
      ],
      orderList: []
    })
    const activeTab = ref('online')
    const activeOnlineTab = ref('unpaid')

    const onClickTabFirst = (item) => {
      console.log(item)
    }
    const onClickOnlineTab = (item) => {
      onlineOrders(item).then(data => {
        state.orderList = []
        state.orderList = data
        console.log(state.orderList)
      })
    }
    const onlineOrders = async (item) => {
      try {
        // 使用await关键字等待fetchOrders函数执行完成并返回数据
        const orderList = await fetchOrders(item)
        console.log('orderList', orderList)
        return orderList
        // 这里可以对orderList进行进一步的处理
      } catch (error) {
        console.error(error)
      }
    }

    // 模拟获取订单数据
    const fetchOrders = (offset) => {
      console.log(offset)
      return new Promise((resolve) => {
        // 模拟获取数据
        setTimeout(() => {
          if (offset.name === 'paid') {
            resolve([])
          } else {
            const data = [
              {
                name: '订单1',
                status: '待支付',
                orderNo: '123456789',
                applicant: {
                  name: '投保人1',
                  idCard: '111111111111111111',
                },
                insured: {
                  name: '被保人1',
                  idCard: '222222222222222222',
                },
                insuredTime: '2022-01-01',
                premium: 100.0,
              },
              {
                name: '订单2',
                status: '已完成',
                orderNo: '987654321',
                applicant: {
                  name: '投保人2',
                  idCard: '333333333333333333',
                },
                insured: {
                  name: '被保人2',
                  idCard: '444444444444444444',
                },
                insuredTime: '2022-02-02',
                premium: 200.0,
              },
              {
                name: '订单3',
                status: '待出单',
                orderNo: '567890123',
                applicant: {
                  name: '投保人3',
                  idCard: '555555555555555555',
                },
                insured: {
                  name: '被保人3',
                  idCard: '666666666666666666',
                },
                insuredTime: '2022-03-03',
                premium: 300.0,
              },
            ]
            resolve(data)
          }

        }, 1000)
      })
    }
    return {
      ...toRefs(state),
      activeTab,
      activeOnlineTab,
      onClickTabFirst,
      onClickOnlineTab,
      onlineOrders
    }
  }
}
</script>

<style scoped>
.order-page {
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 16px;
}
.tabCardClass {
  /*text-align: center;*/
  /*width: 300px;*/
  text-align: center;
  width: auto;
  margin: 0 auto;
}
:deep(.van-tabs__nav--card){
  border-radius: v-bind(radiusParam);
  color: v-bind(tabSeletColor);
  border: v-bind(borderWidth) solid v-bind(tabSeletColor);
}
:deep(.van-tab--card) {
  border-radius: v-bind(radiusParam) 0px 0px v-bind(radiusParam);
  color: v-bind(tabSeletColor);
  border-right: 0px;
}
:deep(.van-tab--card.active) {
  border-radius: v-bind(radiusParam) 0px 0px v-bind(radiusParam);
}
:deep(.van-tab--card:last-child) {
  border-radius: 0px v-bind(radiusParam) v-bind(radiusParam) 0px;
}
:deep(.van-tab--card:last-child.active) {
  border-radius: 0px v-bind(radiusParam) v-bind(radiusParam) 0px;
}
:deep(.van-tab--card.van-tab--active) {
  background-color: v-bind(tabSeletColor);
  color: v-bind(tabNoSeletColor);
}

</style>

