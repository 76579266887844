<template>
  <a-modal v-model:visible="visible" @ok="xvzhitiaozhan" width="80%" :centered="true">
    <p></p>
    <p>请您确认是否同意产品相关条款</p>
  </a-modal>
  <confirmationDrawer ref="confirmationDrawerRef" :riskList="riskList" :hasSign="hasSign"
                      @jumpFun="jumpFun"></confirmationDrawer>
</template>

<script>
import {defineComponent, reactive, ref, toRefs} from 'vue'
import confirmationDrawer from '../confirmationDrawer'
import {routerJump} from '../../../utils/product_process'
import {request} from '../../../utils/request'
import {getExtendUri} from '../../../utils/request_extend'

export default defineComponent({
  components: {
    confirmationDrawer
  },
  props: {
    checkedShow: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    }
  },
  setup(props) {
    const confirmationDrawerRef = ref()
    const state = reactive({
      name: '',
      visible: props.checkedShow,
      // 跳链状态
      status: '1',
      productInfo: {},
      statusList: [],
      riskList: [],
      callbackData: {},
      showData: [],
      hasSign: false,
      // 是否确认了同意条款
      // shifoutongyitiaokuan: false
    })
    const xvzhitiaozhan = function () {
      const n = routerJump()
      if (n) {
        n.then(() => {
          document.title = '条款须知'
          state.visible = false
        })
      }
    }
    const jumpFun = function () {
      state.visible = false
      confirmationDrawerRef.value.querenquanbuneirong()
    }
    const nextPage = function () {
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['page_style']}}).then(res => {
        state.statusList = res.result.page_style
      })
    }
    const lijitoubao = function (value) {
      document.title = '条款须知'
      //是否展示现金价值表
      state.hasSign = value
      if (state.status === '1') {
        state.riskList = []
        confirmationDrawerRef.value.showDrawer(state.riskList, value)
      } else if (state.status === state.statusList[1].value) {
        state.visible = true
      } else {
        window.location = 'https://static-1305332945.cos.ap-beijing.myqcloud.com/upload/common/product/2022/08/13/94df357a_e28a_424b_916c_27bef73031e8/%E4%BA%91%E6%99%BA%E8%83%BD%E4%BA%8B%E4%B8%9A%E9%83%A8-%E8%AF%B7%E5%81%87%E5%88%B6%E5%BA%A6%E8%A7%84%E8%8C%83.pdf'
      }
    }
    nextPage()
    return {
      ...toRefs(state),
      xvzhitiaozhan,
      lijitoubao,
      jumpFun,
      confirmationDrawerRef
    }
  }
})
</script>

<style scoped>

</style>
