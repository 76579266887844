<style scoped>
.confirmBox {
  color: #7e7e7e;
  width: 96%;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 7px;
  background: #fff;
}
.confirm {
  width: 96%;
  margin: 0 auto;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #fff;
}
.confirmTitle{
  display: flex;
  line-height: 50px;
  height: 50px;
  padding-left: 10px;
  font-size: 16px;
}
.confirmTitle span{
  display: inline-block;
}
.confirmTitle span:nth-child(1){
    flex: 8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.confirmTitle span:nth-child(2){
  font-size: 14px;
  text-align: right;
  padding-right: 10px;
  flex: 4;
}
.confirmList{
  width: 100%;
}
.confirmList li{
  list-style: none;
  line-height: 38px;
  height: 38px;
  padding-left: 20px;
  padding-right:20px;
  border-top:1px solid #ccc ;
  display: flex;
}
.confirmList li span{
  display: inline-block;
}
.confirmList li span:nth-child(1){
  flex: 4;
}
.confirmList li span:nth-child(2){
  text-align: right;
  padding-right: 10px;
  flex: 8;
}
.flexTop{
  position: fixed;
  top: 0;
  z-index:99;
}
.flexBox{
  width: 100%;
  height: 90px;
}
</style>

<template>
  <div class="boxApp">
  <div class="flexTop">
    <van-tabs v-model:active="LevelFirst" @click-tab="onClickTabFirst">
      <van-tab v-for="(item,index) in listFirst" :key="index" :title="item.label"></van-tab>
    </van-tabs>
    <van-tabs v-model:active="LevelNext" @click-tab="onClickTabNext">
      <van-tab v-for="(item,index) in listNext" :key="index" :title="item.label"></van-tab>
    </van-tabs>
  </div>
 <div class="flexBox"></div>
    <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="pullOnRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onRefresh"
      >
        <van-cell v-for="(item,index) in listShow" :key="item" :title="index">
          <div class="confirm">
            <div class="confirmTitle" >
              <span>{{item.productInfoName}}</span>
              <span @click="nextJump(item)">{{item.prtStatusName}}
                <van-icon name="arrow" />
              </span>
            </div>
            <div class="confirmList">
              <ul>
                <li>
                  <span>订单号</span>
                  <span>{{item.prtNo}}</span>
                </li>
                <li>
                  <span>投保人</span>
                  <span>{{item.applicantName}}</span>
                </li>
                <li>
                  <span>被保人</span>
                  <span>{{item.protectedmanName}}</span>
                </li>
                <li>
                  <span>订单日期</span>
                  <span>{{item.polApplyDate}}</span>
                </li>
                <li>
                  <span></span>
                  <span style="color: #DC6180;font-size: 18px">￥{{item.payMoney}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {defineComponent, ref, reactive} from 'vue'
import {useRouter} from 'vue-router'
import { request} from '../../utils/request'
import {getExtendUri, isOnlyPayUrl} from '../../utils/request_extend'
import { List, Tab, Tabs, Icon } from 'vant'
// import { message } from 'ant-design-vue'
import { PullRefresh } from 'vant'
import {getRouterJumpBean} from '../../utils/product_process'

export default defineComponent({
  components: {
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
  },
  setup() {
    const state = reactive({
      productId: '',
      payObj: {
        payStateResult: '',
        payUrl: '',
        payKey: '',
        payJson: {},
        payState: false,
        supId: ''
      },
    })
    const router = useRouter()
    const listFirst = ref([])
    const listNext = ref([])
    const listShow = ref([])
    const lock = ref(false)
    const loading = ref(false)
    const finished = ref(false)
    const refreshing = ref(false)
    const LevelFirst = ref(0)
    const LevelNext = ref(0)
    const pageNum = ref(1)
    const prtStatusType = ref('')
    const prtStatus = ref('')
    const total = ref(0)
    const onClickTabFirst = function(value){
      finished.value = false
      pageNum.value = 1
      listNext.value = listFirst.value[value.name].children
      prtStatusType.value = listFirst.value[value.name].value
      prtStatus.value = listNext.value[0].value
      listShow.value = []
      selectList()
    }
    const onClickTabNext = function(value){
      pageNum.value = 1
      finished.value = false
      listShow.value = []
      prtStatus.value = listNext.value[value.name].value
      selectList()
    }

    const onRefresh = () => {
      pageNum.value ++
      selectList()
    }

    const pullOnRefresh = () => {
      pageNum.value = 1
      selectList()
    }
    // 调接口查询订单列表
    const selectList = function() {
      if(!finished.value&&!lock.value){
        loading.value = true
        lock.value = true
        request(getExtendUri('postQueryListForPage'), {data: {agentCode: '',prtStatusType: prtStatusType.value, prtStatus:prtStatus.value}, pagination: {current: pageNum.value, pageSize: 10}}).then(res => {
          total.value = res.pagination.total
          lock.value = false
          if(listShow.value.length>=total.value){
            loading.value = false
            finished.value = true
            refreshing.value = false
          }else{
            finished.value = false
            res.result.forEach(item=>{
              listShow.value.push(item)
            })
            loading.value = false
            refreshing.value = false
          }
        })
      }
    }

    //  // 获取并保存productDetailData，需要用到productDetailData.productInfo.productId
    // const saveProductInfo = (item) => {
    //   request(getExtendUri('getLinkData'), {
    //     data: {
    //       id: item.shareId
    //     }
    //   }).then(res => {
    //     if (res.code === 1 && res.result) {
    //       let productDetailInfo = res.result
    //       state.productId = productDetailInfo ? productDetailInfo.productInfo.productId : ''
    //       getNodeState(item)
    //     }else if (res.code === 300) {
    //       window.location.assign(res.result)
    //     } else {
    //       message['error'](res.message)
    //     }
    //   })
    //
    // }
    // // 根据productId判断到哪个节点
    // const getNodeState = (item) => {
    //   request(getExtendUri('getProcessByProductId'), {
    //       data: {
    //         productId: state.productId
    //       }
    //   }).then(res => {
    //       // 先将获取到的node节点存入缓存
    //     let nodeDetailData = res.result
    //     // 遍历获取到的node节点
    //     nodeDetailData.forEach(nodeItem => {
    //       // for (const key in showArr) {
    //         // 'acknowledgementOrder'
    //         if (nodeItem.nextCode) {
    //           if (nodeItem.nextCode.indexOf('acknowledgementOrder') != -1) {
    //             console.log('匹配成功:', nodeItem.nextCode)
    //             console.log('sort:', nodeItem.sort)
    //             router.push({
    //               path: '/app/confirmation',
    //               query: {
    //                   tradeNo: item.tradeNo,
    //                   shareId: item.shareId,
    //                   sort:nodeItem.sort,
    //                   id: nodeItem.processId
    //                 }
    //             })
    //           }
    //         }
    //         // }
    //     })
    //   })
    // }

    // 根据订单状态prtStatus跳转不同的页面
    const nextJump =  async function (item) {
      if (item) {
        // saveProductInfo(item)
       const bean = await getRouterJumpBean(item.shareId,item.tradeNo)
        console.info('goJump --- 999', bean)
        if (bean) {
          router.push({
            path: bean.path,
            query: {
              tradeNo: bean.tradeNo,
              shareId: bean.shareId,
              sort:bean.sort,
              id: bean.id
            }
          })
        }
      } else {
        saveTopay(item.tradeNo,item.shareId)
      }

    //   if (item.prtStatus == '1') {  // todo
    //     saveProductInfo(item)
    //   } else if(item.prtStatus == '0') {
    //     saveTopay(item.tradeNo,item.shareId)
    //   } else {
    //     router.push({
    //       path: '/app/confirmation',
    //       query: {
    //         tradeNo: item.tradeNo,
    //         shareId: item.shareId
    //     }
    //   })
    // }
    }
    // 跳转支付页面
     const saveTopay = (tradeNo,shareId) => {
      request(getExtendUri('confirmCharge'), {
        baseInfo: {
          tradeNo: tradeNo,
          shareId: shareId
          }
        }).then(res => {
          if (res.code === 1) {
            payAction(res.result)
          }
        })
    }

    const payAction = (obj) => {
      console.log('supplierCode', obj.supplierCode)
      console.log('supplierName', obj.supplierName)
      state.payObj.supId = obj.supplierCode
      // 平安
      // if (obj.supplierCode === '1004') {
      if (isOnlyPayUrl(obj.supplierCode)) {
        state.payObj.payUrl = obj.payUrl
        state.payObj.payJson = ''
        state.payObj.payKey = ''
      } else {
        // 安盛
        const xmlData = state.payObj.payStateResult = obj.value || ''
        state.payObj.payJson = xmlData
        state.payObj.payUrl = obj.url
        state.payObj.payKey = obj.key
      }
    }

    const selectTabs = function() {
      request(getExtendUri('dictionary_query_byType'), {data: {types:['insuranceSlipStatus']}}).then(res=> {
        listFirst.value = res.result.insuranceSlipStatus
        listNext.value = listFirst.value[0].children
        prtStatusType.value = listFirst.value[0].value
        prtStatus.value = listNext.value[0].value
        selectList()
      })
    }
    selectList()
    selectTabs()
    return {
      listShow,
      loading,
      finished,
      onRefresh,
      pullOnRefresh,
      refreshing,
      LevelNext,
      LevelFirst,
      listFirst,
      listNext,
      onClickTabFirst,
      onClickTabNext,
      nextJump
    }
  }
})
</script>
