/**
 * 准备一个数组工具类
 * 慢慢整理吧
 */

/**
 * 单数组操作：
 * 根据某元素属性删除对象
 * @param arr 数组
 * @param attr 数组属性key
 * @param value 数组属性value
 */
export const removeByValue = (arr, attr, value) => {
  var index=0
  for(var i in arr){
    if(arr[i][attr]==value){
      index=i
      break
    }
  }
  arr.splice(index,1)
}

/**
 * 给数组对象添加属性及默认值
 * @param arr 数组
 * @param attr key
 * @param value value
 */
export const addPropToDefaultItem = (arr, attr, value) => {
  arr.forEach(item => {
    item[attr] = value
  })
}
/**
 * 根据特定属性获取对象修改值
 * @param arr 数组
 * @param attr key
 * @param value value
 */
export const addPropToItem = (arr, attr, value, attr2, value2) => {
  for(var i in arr){
    if(arr[i][attr2]===value2){
      arr[i][attr] = value
      break
    }
  }
}
/**
 * 根据某属性获取对象数组
 * @param arr
 * @param attr
 * @param value
 */
export const selectItemByProp = (arr, attr, value) => {
  let nArr = arr.filter(item => {
    if (value === item[attr]) {
      return item
    }
  })
  return nArr
}

export const isArray = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Array]'
}

// const keyMap = {
//   'suppierId':'id',
//   'suppierCode': 'value',
//   'suppierName': 'label',
// }
/**
 * 将嵌套层数组平铺出来
 * @param arr 数据源
 * @param keyMap 映射键值对 key-value, key标识目标参数名，value标识源数据参数名
 * @param prop 每个对象需要额外加的属性
 * @returns {*[]}
 */
export const flattenTree = (arr, keyMap, prop) => {
  // 保存结果的数组
  let res = []
  for (let i = 0; i < arr.length; i++) {
    let node = arr[i]
    // 将节点的 item 作为一个对象添加到结果数组中
    let item = {}
    Object.keys(keyMap).forEach((key) => {
      // 根据 keyMap 映射表来修改节点属性的键名，并添加到 item 对象中
      item[keyMap[key]] = node[key]
    })
    let mergedObj = {...item, ...prop}
    res.push(mergedObj)
    if (Array.isArray(node.children)) {
      // 如果该节点有子节点，递归调用自身，将子节点的数据添加到结果数组中
      res = res.concat(flattenTree(node.children, keyMap, prop))
    }
  }
  return res
}

