export const detailTabData = {
  //  待双录列表
  doubleRecordList: [
    {value: 1, label: '待双录'},
    {value: 2, label: '待审核'},
    {value: 3, label: '已驳回'},
    {value: 4, label: '已通过'},
    {value: 5, label: '全部订单'},
  ],
  //  待回访列表
  followUpList: [
    {value: 1, label: '待回访'},
    {value: 2, label: '已回访'},
  ],
  // 待回执
  receiptList: [
    {value: 1, label: '待签收'},
    {value: 2, label: '已签收'},
  ],
}
export const orderData = {
  '1': {
    productInfoName: '产品名称',
    tradeNo: '订单号',
    polApplyDate: '投保日期',
    drSystemName: '双录系统',
    drAccount : '双录账号',
    doubleRecordMode: '双录方式',
    doubleRecordRejectTypeName:'驳回类型',
    doubleRecordFeedbackTime:'质检时间'
  },
  '2':{
    contNo: '保单号',
    polApplyDate: '投保日期',
    productInfoName: '产品名称'
  },
  '3':{
    contNo: '保单号',
    polApplyDate: '投保日期',
    productInfoName: '产品名称',
    customGetPolDate:'回执日期'
  }
}

