<template>
  <a-drawer
    :width="'65%'"
    :height="'100%'"
    placement="bottom"
    :visible="visible"
    :closable="false"
    :bodyStyle="{padding: 0}"
  >
  <IOSbackBar :title="'信息确认页'" ></IOSbackBar>
    <div class="drawer-title" v-show="name === defaultName">
      请仔细阅读以下文件，充分理解全部内容并确认。
    </div>
    <div v-if="yuedufenshu === 0">
      <div>
        <table style="width: 100%;position: relative;border-collapse: collapse;">
          <template v-for="(item, index) in riskList" :key="'openOne_' + index">
            <tr style="height: 35px" @click="openOne(item)">
              <td>《{{ item.name }}》</td>
              <td style="text-align: right;padding-right: 20px;"><span
                :class="{'filestatus': item.status === '未读', 'filestatus2': item.status === '已读'}">{{
                  item.status
                }}</span></td>
            </tr>
          </template>
        </table>
      </div>
      <div id="tab-bar">
        <div style="height: 4rem"></div>
        <a-button class="tab-bar2" :style="{background:  yesNO ? '#92c3fb' : '#0078fc'}" :disabled="yesNO"
                  @click="readAll" v-show="visible">确认已阅读并知晓全部内容
        </a-button>
      </div>
    </div>
    <div v-if="yuedufenshu === 1">
<!--      <div class="div_wrap">-->
<!--          <VuePdfEmbed-->
<!--            v-if="['pdf','xls', 'xlsx'].includes(lastReadItem.type)"-->
<!--            :source="lastReadItem.uploadFile"-->
<!--            @progress="showProgress"-->
<!--            @loaded="loaded"-->
<!--            @loading-failed="loadingFailed"-->
<!--            @rendering-failed="renderingFailed"-->
<!--            @rendered="rendered"/>-->
<!--        <div class="box1" v-if="loadingShow">-->
<!--          <a-spin size="large"/>-->
<!--        </div>-->
<!--      </div>-->

<!--      <img style="width:100%" v-if="['png','jpg', 'jpeg', 'gif', 'bmp'].includes(lastReadItem.type)"-->
<!--           :src="lastReadItem.uploadFile"/>-->
<div style="width: 100vw;overflow-x: scroll;">
            <div :style="scaleFirst ? {} : {width: `${imageWidth}px`}" 
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd">
        <a-spin style="width: 100%;height: 100%;
            position: fixed;
            top: 50%;
            left: 0;" :spinning="pdfList.length< 1 && yuedufenshu === 1" :delay="50" tip="加载中..." size="large">
          <div v-for="(pdf, index) in pdfList" :key="index">
<!--            <img alt="" style="width:100%" :src="pdf"/>-->
            <img
              alt="" style="width:100%" :src="pdf"
            />
          </div>
        </a-spin>
            </div>
</div>
      <product-cash-value v-if="lastReadItem.type==='xj'" v-model="tradeNo" :visible="lastReadItem.type==='xj'"
                          type="Slider"></product-cash-value>
      <a-button class="tab-bar2" type="primary" @click="readOne(lastReadItem)" v-show="visible">我已阅读并知晓同意</a-button>
    </div>

  </a-drawer>
</template>

<script>
// import {createLoadingTask} from 'vue3-pdfjs'
import {defineComponent, nextTick, onBeforeMount, onMounted, reactive, ref, toRefs, watch} from 'vue'
// import VuePdfEmbed from 'vue-pdf-embed'
import ProductCashValue from './../product_cashvalue'
import store from '../../../store'
import {gePInfo} from '../../../utils/product_process'
import {sorttnew} from '../../../utils/utlis'
import {request} from '@/utils/request'
import {getExtendUri} from '@/utils/request_extend'
import router from '@/router'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'
export default defineComponent({
  components: {
    // VuePdfEmbed,
    ProductCashValue,
    IOSbackBar
  },
  setup(props, {emit}) {
    let initialDistance = 100
    let baseWidth = 100 // 初始宽度
    const state = reactive({
      scaleFirst: true,
      baseWidth: 0,
      cashValueVisible: true,
      visible: false,
      // tradeNo: localStorage.getItem('uuid'),
      tradeNo: store.getters['insured/getUuid'],
      source: '',
      name: '',
      defaultName: '',
      productInfo: [],
      // 去控制它是显示条框还是显示条款列表
      yuedufenshu: 0,
      // 条款列表
      riskList: [],
      // 条款
      lastReadItem: {},
      // 验证所有条款是否已阅读完毕的按钮是隐藏还是显示
      yesNO: false,
      hasSign: false,
      readCount: 0,
      loadingShow: true,
      signature: ''
    })
    onBeforeMount(()=>{
      document.title = ''
      state.signature = router.currentRoute.value.query?.signature
      nextTick(()=>{
        state.yesNO = !store.getters['insured/getClausePdfAllReadState']
      })
    })
    onMounted(() => {})
    watch(() => store.getters['insured/getClausePdfAllReadState'], (newVal)=>{
      state.yesNO = !newVal
    },{immediate: true})
    // 获取条款缓存
    const getPdfCacheParametersData = () =>{
      request(getExtendUri('getCacheParameters'), {
        tradeNo: state.tradeNo+'PDF'
      }).then(res => {
        if (res.result){
          store.dispatch('insured/changeInsureJSInfo', {
            clausePdfList: res.result.saveData.clausePdfList
          })
        }
      })
    }
    // 将传过来的值里面的所有条款计算出来
    const riskListData = async () => {
      state.riskList = []
      // 如果有缓存先取缓存的值
      if (state.signature === '1'){
        await getPdfCacheParametersData()
      }
      if (store.getters['insured/getClausePdfList']&&store.getters['insured/getClausePdfList'].length > 0) {
        state.riskList = store.getters['insured/getClausePdfList']
      } else {
        await gePInfo()
        state.productInfo = JSON.parse(store.getters['insured/getProductDetailData'])

       // 百万住院医疗保险2022升级版不展示服务手册文件
        if(state.productInfo.productInfo.riskList[0].riskCode === '100000000927') {
          state.productInfo.productInfo.riskList[0].coverageDataList.forEach(item => {
            if (!['6', '7','12'].includes(item.fileDataType)) {
              item.fileList.forEach(d => {
                if (d.uploadFile != null && d.uploadFileName != null) {
                  state.riskList.push({
                    fileOrder: d.fileOrder,
                    mustReadOrder: d.mustReadOrder,
                    id: d.id,
                    uploadFile: d.uploadFile,
                    name: d.uploadFileName,
                    fileName: d.uploadFileName,
                    type: d.uploadFile.substring(d.uploadFile.lastIndexOf('.') + 1),
                    status: '未读'
                  })
                }
              })
            }
          })
        }else{
          // 先获取到选择的主险/附加险
          const riskList = []
          JSON.parse(store.getters['insured/getRiskList']).riskList.forEach(risk =>{
            riskList.push(risk.riskId)
          })
          state.productInfo.productInfo.riskList.forEach(item =>{
            if(riskList.includes(item.riskId)){
              item.coverageDataList.forEach(item => {
                if (!['6', '7', '12'].includes(item.fileDataType)) {
                  item.fileList.forEach(d => {
                    if (d.uploadFile != null && d.uploadFileName != null) {
                      state.riskList.push({
                        fileOrder: d.fileOrder,
                        mustReadOrder: d.mustReadOrder,
                        id: d.id,
                        uploadFile: d.uploadFile,
                        name: d.uploadFileName,
                        fileName: d.uploadFileName,
                        type: d.uploadFile.substring(d.uploadFile.lastIndexOf('.') + 1),
                        status: '未读'
                      })
                    }
                  })
                }
              })
            }
          })

        }
        state.riskList = sorttnew(state.riskList)
        store.dispatch('insured/changeInsureJSInfo', {
          clausePdfList: state.riskList
        })
      }
      if (state.hasSign) {
        let obj = {}
        obj.uploadFile = ''
        obj.name = '现金价值表'
        obj.status = '未读'
        obj.type = 'xj'
        state.riskList.push(obj)

      }
    }
    const readOne = function (node) {

      try {
        // node.status = '已读'
        store.dispatch('insured/changeInsureReadState', {
          id: node.id,
          status: '已读'
        })
        state.imageWidth = baseWidth
        state.scaleFirst = true
        if (store.getters['insured/getClausePdfList'].length > 0) {
          state.riskList = store.getters['insured/getClausePdfList']
        }
        const unReadArr = state.riskList.filter(item => item.status === '未读')
        if (unReadArr.length > 0) {
          openOne(unReadArr[0])
        } else {
          state.yuedufenshu = 0
          state.lastReadItem = {}
          state.yesNO = false
          state.name = state.defaultName
          document.title = state.defaultName
        }
      } catch (e) {
        state.loadingShow = false
        console.error('err1:' + JSON.stringify(e))
      }
    }
    const readAll = function () {
      state.loadingShow = false
      state.visible = false
      emit('readAll')
    }
    const reading = function (sign, subFlag) {
      state.hasSign = sign
      state.visible = true
      if (subFlag === 'sub' && state.lastReadItem && state.lastReadItem.type) {
        openOne(state.lastReadItem)
        return
      }
      state.yuedufenshu = 0
      state.name = state.defaultName
      document.title = state.defaultName
    }
    const readCancel = function () {
      state.visible = false
    }

    const pdfList = ref([])
    // 点击获取对应的pdf文件
    const getPdfList = (item) =>{
      let data = {
        pdfUrl: item.uploadFile,
        fileName: item.fileName,
        productCode: JSON.parse(store.getters['insured/getProductDetailData']).productInfo.productCode
      }
      request(getExtendUri('getPdfDecompose'), data).then(res =>{
        if(res.code === 1){
          nextTick(()=>{
            pdfList.value = res.result
            // if (pdfList.value.length > 0) {
            //   for (let i = 0; i < pdfList.value.length; i++) {
            //     let url = pdfList.value[i];
            //     const img = new Image();
            //     img.crossOrigin = "Anonymous"; // 设置跨域属性，确保能正常加载外部图片
            //     img.src = url;
            //     img.onload = () => {
            //       const canvas = document.createElement('canvas');
            //       canvas.width = img.width;
            //       canvas.height = img.height;
            //       const context = canvas.getContext('2d');
            //       context.drawImage(img, 0, 0, img.width, img.height);
            //       const base64Data = canvas.toDataURL('image/jpeg', 0.8); // 根据需求选择输出格式（如'image/png'）
            //       pdfList.value[i] = base64Data;
            //       console.log("base64", base64Data);
            //     };
            //     console.log(pdfList.value[i]);
            //   }
            // }
          })
        }
      })
    }
    const openOne = function (item) {
      pdfList.value = []
      getPdfList(item)
      try {
        state.loadingShow = true
        state.lastReadItem = item
        state.name = item.name
        document.title = item.name
        state.yuedufenshu = 1
        nextTick(() => {
          const drawer_body_arr = document.getElementsByClassName('ant-drawer-body')
          if (drawer_body_arr.length > 0) {
            drawer_body_arr[0].scrollTo(0, 0)
          }
        })
        const url = new URL(window.location)
        if (url.searchParams.get('openDialog') !== 'okSub') {
          url.searchParams.set('openDialog', 'okSub')
          window.history.pushState({}, '', url)
        }
      } catch (e) {
        state.loadingShow = false
        console.error('err2:' + JSON.stringify(e))
      }

    }
    const showProgress = (progressParams) => {
      console.debug('progressParams--------')
      console.debug(JSON.stringify(progressParams))
    }
    const loaded = (document) => {
      console.debug('loaded--------')
      console.debug(document)
      // this.$emit('loaded', this.document)
    }
    const loadingFailed = (e) => {
      console.debug('loadingFailed--------')
      console.debug(e)
      state.loadingShow = false

    }
    const rendered = () => {
      // this.$emit('rendered')
      console.debug('renered---doing')
      state.loadingShow = false

    }
    const renderingFailed = (e) => {
      console.debug('rendering-failed--------')
      console.debug(e)
      state.loadingShow = false
      // this.$emit('rendering-failed', e)
    }
    const image = ref(null)
const imageWidth = ref(baseWidth)
 
const onTouchStart = (event) => {
  if (state.scaleFirst) {
    state.scaleFirst = false
    var element = document.getElementsByClassName('ant-spin-container')[0]
    baseWidth = element.offsetWidth - 20
    imageWidth.value = baseWidth
    initialDistance = baseWidth
  }
  console.log(event, 'event444')
  if (event.touches.length === 2) {
    initialDistance = Math.hypot(
      event.touches[0].pageX - event.touches[1].pageX,
      event.touches[0].pageY - event.touches[1].pageY
    )
  }
}
 
const onTouchMove = (event) => {
  if (event.touches.length === 2) {
    const currentDistance = Math.hypot(
      event.touches[0].pageX - event.touches[1].pageX,
      event.touches[0].pageY - event.touches[1].pageY
    )
      console.log(currentDistance, 'currentDistance')
    if (currentDistance > initialDistance) {
      // 放大图片
      imageWidth.value += 10
    } else if (currentDistance < initialDistance) {
      // 缩小图片，可添加边界条件判断防止过小
      if (imageWidth.value <= baseWidth) {
        return false
      }
      imageWidth.value -= 10
    }
 
    initialDistance = currentDistance
  }
}
 
const onTouchEnd = () => {
  initialDistance = 0
}
    return {
      ...toRefs(state),
      imageWidth,
      image,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      riskListData,
      readOne,
      readAll,
      reading,
      openOne,
      readCancel,
      showProgress,
      loaded,
      loadingFailed,
      rendered,
      renderingFailed,
      pdfList,
      getPdfList
    }
  }
})
</script>

<style scoped>
.drawer-title {
  background: #dcf0ff;
  color: #1890ff;
  text-indent: 15px;
  margin-bottom: 30px;
  padding: 5px 0;
}

.filestatus {
  color: #00a0e9;
  padding: 2px 8px;
  background: #f3f3f3;
}

.filestatus2 {
  color: #9d9d9d;
  padding: 2px 8px;
  background: #f3f3f3;
}

.tab-bar2 {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 49px;
  z-index: 2;
  font-size: .95rem;
  background: #0079FE;
  color: #fff;
}

table td {
  text-align: left;
  padding-left: 8px;
  width: 80%;
}

.grid_file {
  display: grid;
  grid-template-columns: 1fr 55px;
  justify-content: space-between;
  align-items: center
}

/* table tr:nth-child(2n) {
  background-color: #eeeeee;
} */

.div_wrap {
  width: 100%;
  height: 100%;
  background-color: #f6f8fb;
  overflow-x: hidden;
  text-align: left;
  overflow-y: auto;
}
.vue-pdf-embed :deep(canvas){
  height: 100% !important;
  opacity: 0;
}
.vue-pdf-embed{
  height: 100% !important;

}
.vue-pdf-embed :deep(.textLayer){
  opacity: 1;
}
.vue-pdf-embed :deep(.textLayer span){
  color: #000;
  opacity: 1;
}
.vue-pdf-embed div:nth-child(1){
  height: 100%;
}
.box1 {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.box1 div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}

</style>
