<template>
  <div class="detail_class">
    <IOSbackBar :title="'智能核保'" ></IOSbackBar>
    <div>
      <FileSearchOutlined style="font-size: 56px;color: #1677ff;margin-bottom: 20px;"/>
      <h3>核保失败</h3>
    </div>
    <h5>{{ message }}</h5>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from 'vue'
import router from '@/router'
import {Toast} from 'vant'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'
import {
  FileSearchOutlined
} from '@ant-design/icons-vue'
import {request} from '@/utils/request'

export default defineComponent({
  components: {
    FileSearchOutlined,
    [Toast.name]: Toast,
    IOSbackBar
  },
  setup() {
    const state = reactive({
      message: ''
    })
    onMounted(() => {
      document.title = '核保结果'
      getDetailData()
    })
    // 获取详情数据
    const getDetailData = () => {
      const token = router.currentRoute.value.query.token
      // const token = '60c3c4f0550f4a898502bc46883384a1'
      const data = {
        id: router.currentRoute.value.query.id
      }
      request('/product-service/product/productInsured/bdt/api/queryInsuredDetailsFront?token=' + token, {data}).then(res => {
        if (res.code === 1) {
          getDetailType(res.result.supCode, res.result.tradeNo)
        } else {
          Toast(res.message)
        }
      })
    }
    const getDetailType = (supCode, tradeNo) => {
      const token = router.currentRoute.value.query.token
      // const token = '60c3c4f0550f4a898502bc46883384a1'
      request('/edt-service/edt/ExcLog/bdt/api/getErrLogMsg?token=' + token, {
        supCode,
        tradeNo
      }).then(res => {
        state.message = res.result
      })
    }
    return {
      ...toRefs(state),
      getDetailType
    }
  }
})
</script>

<style scoped>
.detail_class {
  padding: 50px 0;
}
</style>
