<template>
  <a-drawer
    :width="'65%'"
    :height="'100%'"
    placement="bottom"
    :visible="showReConfirm"
    :closable="false"
    :footerStyle="{border: 'none'}"
    :bodyStyle="{padding: 0}"
  >
  <IOSbackBar :title="'信息确认'" :backMethod="back" ></IOSbackBar>
  <div class="risk-container">
    <template v-for="item in confirmInfo.selectRisk.riskList" :key="item.riskId">
      <p class="confirmP produc-name">
      <span><span class="zhuxian">主险</span>{{ item.riskName }}</span>
      </p>
      <p class="confirmP">
      <span>保费：</span>
      <span style="color: #1890ff;">{{ confirmInfo.premium }}</span>&nbsp;元
      </p>
      <p class="confirmP">
      <span>保额：</span>
      <span style="color: #1890ff;">{{ findValue(item.calculateFactor, 'Amnt') ? parseFloat(Number(findValue(item.calculateFactor, 'Amnt'))).toFixed(2) : '' }}</span>&nbsp;元
      </p>
      <p class="confirmP">
      <span>保险期间：</span>
      <span>{{ findValue(item.calculateFactor, 'Period') }}</span>
      </p>
      <p class="confirmP">
      <span>缴费方式：</span>
      <span>{{ findValue(item.calculateFactor, 'PayType') }}</span>
      </p>
      <p class="confirmP">
      <span>缴费期间：</span>
      <span>{{ findValue(item.calculateFactor, 'FeeYear') }}</span>
      </p>
    </template>
  </div>
  <div class="checked">
    <a-checkbox v-model:checked="checked">本人作为本次投保的被保险人，同意本次投保并确认保险金额。</a-checkbox>
  </div>
  <template #footer>
    <div class="confirm-footer">
      <div class="premium">
        <div class="money">￥{{ confirmInfo.premium }}</div>
        <div class="premium-text">方案总保费/元</div>
      </div>
      <div class="btn-row">
        <div class="btn" @click="confirm">确定</div>
      </div>
    </div>
  </template>
  <a-modal style="width: 80%;" centered :closable="false" v-model:visible="showMessage" @ok="close">
      <div style="text-align: center;">{{ message }}</div>
      <template #footer>
        <a-button type="link"
          style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="close">确定
        </a-button>
      </template>
    </a-modal>
  </a-drawer>
</template>

<script>
// import {createLoadingTask} from 'vue3-pdfjs'
import {
  defineComponent, 
  // nextTick, 
  onBeforeMount, 
  onMounted, 
  reactive, 
  // ref,
  // watch,
  toRefs, 
  // watch
} from 'vue'
import { useRouter } from 'vue-router'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'
// import router from '@/router'
export default defineComponent({
  props: {
    showReConfirm: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    confirmInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    IOSbackBar
  },
  setup(props,{emit}) {
    const router = useRouter()
    const state = reactive({
      showHealthConfirm: false,
      showData: [],
      tradeNo: '',
      visible: false,
      checked: false,
      showMessage: false,
      message: ''
    })
    onBeforeMount(()=>{
      document.title = ''
    })
    onMounted(() => {})
    const findValue = (list, type) => {
      if (type === 'Amnt') {
        return list.find(item => {
        return item.factorType === type
      })?.factorValue
      }
      return list.find(item => {
        return item.factorType === type
      })?.factorValueName
    }
    const confirm = () => {
      if (!state.checked) {
        state.message = '请确认是否同意本次投保并确认保险金额'
        state.showMessage = true
        return false
      }
      const signatureType = router.currentRoute.value.query?.signatureType
      // const customCode = router.currentRoute.value.query?.customCode
      const attributive = router.currentRoute.value.query?.attributive
      const leavel = router.currentRoute.value.query?.leavel
      if (signatureType && signatureType === '1' && leavel && leavel !== '4' && attributive !== '4') {
        emit('updateShowHealth', true)
      } else {
        emit('goSign')
      }
    }
    const close = () => {
      state.message = ''
      state.showMessage = false
    }
    const back = () => {
      emit('closeConfrim')
    }
    return {
      ...toRefs(state),
      findValue,
      confirm,
      close,
      back
    }
}
})
</script>

<style scoped>
.drawer-title {
  background: #dcf0ff;
  color: #1890ff;
  text-indent: 15px;
  margin-bottom: 30px;
  padding: 5px 0;
}
.risk-container {
  padding: 15px 15px 0 15px;
}
.produc-name {
  font-size: 16px;
  font-weight: bold;
}
.checked {
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 15px 0 15px 0;
  border-top: 1px solid #ebedf0;
}
.confirm-footer {
  width: 100%;
  height: 60px;
  background: #f8f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
background: #1890ff;
color: #fff;
width: 150px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
height: 100%;
font-size: 16px;
margin-left: 15px;
}
.btn1 {
color: #1890ff;
border: 1px solid #1890ff;
border-radius: 13px;
width: 80px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
height: 40px;
}
.premium{
  font-size: 18px;
  color: #1890ff;
  font-weight: 500;
  margin-left: 15px;
}
.btn-row {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.premium-text {
  font-size: 14px;
  color: #abacad;
}
.zhuxian {
  display: inline-block;
  padding: 3px 3px;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  margin-right: 5px;
  background: linear-gradient(90deg, #5292db 0%, #a4cde9 100%, rgba(247, 246, 246, 0) 100%);
}
.intelligent {
  padding: 10;
}
</style>
