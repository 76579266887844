<template>
  <div class="inTitle">
    <span>{{title}} </span>
  </div>
  <template v-for="(item2,index2) in showData2" :key="`temp--${index2}`">
    <div class="inSection"  >
      <div class="inRow">
        <label :class="{itemLabel: true, top:titlePos === '0', left:titlePos !== '0'}" :title="item2.label">{{ item2.label }}</label>
        <div style="text-align: right;color: #222">
          <span >{{item2.value}}</span>
        </div>
      </div>
    </div>
  </template>

</template>

<script>
export default {
  name: 'ShowInfoItem',
  props: {
    title: {
      type: String,
      default: ()=> '保险信息'
    },
    showData2: {
      type: Array,
      default: ()=> []
    },
    titlePos: {
      type: Number,
      default: ()=> 1
    }
  }
}
</script>

<style scoped>
.inRow{
  position: relative;
}
.itemLabel {}
.itemLabel.left {
  position: absolute;
  white-space: nowrap;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.itemLabel.top {
  display: block;
  color: #999;
  border-bottom: thin solid #cecece ;
}
.inTitle {
  height: auto;
  padding: 0.4rem 0.7rem 0;
  font-size: .98rem;
  margin: 10px;
}
.inSection {
  font-size: .94rem;
  line-height: 2rem;
  padding: 6px 20px;
  color: #666666;
  border-radius: 6px;
  background-color: #ffffff;
  border-bottom: 1px solid #f3f5f5;
}
</style>
