<style>
body{
  background-color: #f1f1f1;
}
</style>
<style scoped>
.boxApp{
  background-color: #fff;
  padding: 20px;
}
.boxApp p{
  font-size: 12px;
}
.boxApp p span{
  display: inline-block;
  width: 100px;
  text-align: right;
  font-size: 12px;
}
.titleP{
  width: 100%;
  text-align: center;
  font-size: 15px!important;
  color: #4f4f4f;
  font-weight: bold;
  margin-bottom: 0!important;
  line-height: 50px;
}
.confirmBottom{
  width: 100%;
  height: 50px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.confirmBottom div{
  background-color: cornflowerblue;
  color: #fff;
  height: 50px;
  line-height: 50px;
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
}
</style>

<template>
  <div class="boxApp">
    <p class="titleP">华贵大麦安居保定期寿险（互联网专属）</p>
    <p>保险是一个汉语词语，拼音是bǎo xiǎn，英文是Insurance或insuraunce，本意是稳妥可靠保障；后延伸成一种保障机制，是用来规划人生财务的一种工具，是市场经济条件下风险管理的基本手段，是金融体系和社会保障体系的重要的支柱。保险，是指投保人根据合同约定，向保险人支付保险费，保险人对于合同约定的可能发生的事故因其发生所造成的财产损失承担赔偿保险金责任，或者被保险人死亡、伤残、疾病或者达到合同约定的年龄、期限等条件时承担给付保险金责任的商业保险行为</p>
    <p><span>你的保单号：</span>09876543211234567</p>
    <p><span>生效日期：</span>2022-08-08</p>
    <p><span>保险期限：</span>10年</p>
    <p><span>保额：</span>202208元</p>
    <p><span>保费：</span>125元</p>
    <p><span>交费年期：</span>10年交</p>
    <p><span>联系地址：</span>北京水徜徉区呼家楼街道下雨大厦</p>
    <p><span>联系电话：</span>18311221200</p>
  </div>
  <div class="confirmBottom">
    <div @click="showModal">以上均已清楚</div>
  </div>
  <a-modal v-model:visible="visible" title="" @ok="nextPage" width="80%" :centered="true">
    <p></p>
    <p>您已完成在线回访，我们将在6个工作日内给您致电进行满意度调查，请您注意接听我司的官方客服电话。</p>
  </a-modal>

</template>

<script>
import {defineComponent, reactive, ref, toRefs} from 'vue'
import {useRouter} from 'vue-router'

export default defineComponent({
  setup() {
    const visible = ref(false)
    const showModal = () => {
      visible.value = true
    }
    const router = useRouter()
    const userDrawerRef = ref()
    console.log(userDrawerRef)
    const state = reactive({
      name:'',
      visible:false
    })
    const nextPage = function () {
      visible.value  = false
      router.push({
        path: '/policyList'
      })
    }
    return {
      ...toRefs(state),
      nextPage,
      visible,
      showModal
    }
  },
})
</script>
