<style scoped>
.vue-pdf-embed :deep(canvas){
  height: 100% !important;
  opacity: 0;
}
.vue-pdf-embed{
  height: 100% !important;

}
.vue-pdf-embed :deep(.textLayer){
  opacity: 1;
}
.vue-pdf-embed :deep(.textLayer span){
  color: #000;
  opacity: 1;
}
.vue-pdf-embed div:nth-child(1){
  height: 100%;
}
</style>

<template>
  <div>
        <VuePdfEmbed :source="uploadFile"
                     class="vue-pdf-embed" />
        <VuePdfEmbed  :source="pdfFile"
                     class="vue-pdf-embed" />
        <img v-if="!isPdf" style="width: 100%;height: auto;margin-bottom: .5rem;"
               :src="uploadFile" :title="fileName">
  </div>
</template>

<script>
import {
    reactive,
    toRefs,
    defineComponent
} from 'vue'

import { useRouter } from 'vue-router'
export default defineComponent({
    components: {
        VuePdfEmbed
    },
    setup() {
        const router = useRouter()
        console.log(router.currentRoute.value.query['uploadFile'])
        const state = reactive({
            uploadFile: router.currentRoute.value.query['uploadFile'] ?router.currentRoute.value.query['uploadFile'] : '',
            isPdf: router.currentRoute.value.query['isPdf'] ? router.currentRoute.value.query['isPdf'] :'',
            fileName: router.currentRoute.value.query['fileName'] ? router.currentRoute.value.query['fileName'] : '',
            pdfFile: router.currentRoute.value.query['pdfFile'] ? router.currentRoute.value.query['pdfFile'] : ''
        })

         console.log(router)
        return {
            ...toRefs(state),
        }


  }
})
import VuePdfEmbed from 'vue-pdf-embed'
</script>
