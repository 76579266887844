<template>
  <div v-if="visible">
  <a-drawer
    :width="'65%'"
    :height="'100%'"
    placement="bottom"
    :visible="visible"
    :closable="false"
    :footerStyle="{border: 'none'}"
    :bodyStyle="{padding: 0}"
  >
  <div id="healthContainer">
  <IOSbackBar :title="'信息确认'" :backMethod="back" ></IOSbackBar>
  <div v-show="showItem === 'health'" class="health" id="health">
    <div style="padding: 10px 20px 0 20px;">
    <label style="border: 0px !important; font-size: 0.94rem; line-height: 1.5rem;"><b>投保人及被保险人应对被保险人健康、职业及历史投保记录等情况充分了解的基础上履行如实告知义务。若被保险人实际情况与告知内容不符：<br/>
        1.保险公司有权解除保险合同；<br>
        2.保险合同解除前发生的保险事故，保险公司不承担赔偿或给付保险金责任，并有权不退还保险费。<br></b></label>
    </div>
    <template v-for="(item,index) in showData" :key="'healthTold_' + index">
      <PreviewPop class="previewPop" ref="previewPopRef" id="previewPop" :previewData="item" :BIM="BIM"/>
    </template>
    <div class="file-list" v-if="remarkDetail?.healthUploadList?.length > 0">
      <a-collapse v-model:activeKey="activeKey" :expandIconPosition="'right'" ghost>
        <a-collapse-panel key="1" header="健康资料">
        <div class="health-img">
          <a-image-preview-group>
          <div class="img-container">
            <template v-for="item in remarkDetail.healthUploadList" :key="item.value">
              <a-image :src="item.playUrl" />
            </template>
          </div>
            </a-image-preview-group>
        </div>
        </a-collapse-panel>
  </a-collapse>
    </div>
  </div>
  <div v-show="showItem === 'intelligent'" class="intelligent">
    <div class="intelligent-title">已选择疾病</div>
    <template v-for="item in intelligentQuestList" :key="item.diseaseName">
      <div class="question">
        <div class="question-title">{{ item.diseaseName }}</div>
        <template v-for="ite in item.questionArr" :key="ite.questionDesc">
          <div class="child-ques">
            <div class="child-ques-title">{{ ite.questionDesc }}</div>
            <div class="radio-row">
              <a-radio-group disabled v-model:value="ite.checkOption">
                <template v-for="opt in ite.rquestionOptionDtos" :key="opt.questionId">
                  <a-radio-button :value="opt.optionId">{{ opt.optionText }}</a-radio-button>
                </template>
              </a-radio-group>
            </div>
            <!-- {{ ite }} ------ite -->
          </div>
        </template>
      </div>
    </template>
  </div>
</div>
  <template #footer>
    <div class="confirm-footer">
      <div class="btn-row">
        <a-button class="btn" @click="confirm">已确定下一步</a-button>
      </div>
    </div>
  </template>
  <a-modal style="width: 80%;" centered :closable="false" v-model:visible="showMessage" @ok="close">
      <div style="text-align: center;">{{ message }}</div>
      <template #footer>
        <a-button type="link"
          style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="close">确定
        </a-button>
      </template>
    </a-modal>
  </a-drawer>
</div>
</template>

<script>
// import {createLoadingTask} from 'vue3-pdfjs'
import {
  defineComponent, 
  nextTick, 
  onBeforeMount, 
  onMounted, 
  reactive, 
  // ref,
  watch,
  toRefs, 
  // watch
} from 'vue'
import {request} from '../../../utils/request.js'
import {getExtendUri} from '../../../utils/request_extend'
import { useRouter } from 'vue-router'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'
// import router from '@/router'
export default defineComponent({
  props: {
    showHealthConfirm: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    confirmInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    IOSbackBar
  },
  setup(props,{emit}) {
    const router = useRouter()
    const state = reactive({
      activeKey: '1',
      noViewed: true,
      showItem: 'health',
      showData: [],
      BIM: '',
      tradeNo: '',
      visible: false,
      checked: false,
      showMessage: false,
      message: '',
      isShowIntelligent: false,
      intelligentQuestList: [],
      remarkDetail: {}
    })
    onBeforeMount(()=>{
      document.title = ''
    })
    onMounted(() => {})
    const findValue = (list, type) => {
      if (type === 'Amnt') {
        return list.find(item => {
        return item.factorType === type
      })?.factorValue
      }
      return list.find(item => {
        return item.factorType === type
      })?.factorValueName
    }
    const confirm = () => {
      if (state.showItem === 'health') {
        if (state.intelligentQuestList?.length > 0) {
          state.showItem = 'intelligent'
          return false
        } else {
          emit('goSign')
        }
      } else if (state.showItem === 'intelligent') {
        emit('goSign')
      }
    }
    const close = () => {
      state.message = ''
      state.showMessage = false
    }
    const back = () => {
      if (state.showItem === 'intelligent') {
        state.showItem = 'health'
      } else {
        emit('updateShowHealth', false)
        state.visible = false
      }
    }
    function isNumber(value) {
      return Number.isFinite(value)
    }
    watch(() => props.showHealthConfirm, newVal => {
      state.visible = newVal
      nextTick(() => {
        let contentContainer = document.getElementById('health') // 获取id为content-container的元素，作为滚动容器
        let clientHeight = contentContainer?.clientHeight // 获取滚动容器的可见高度（视口高度）
        // var previewPopHeight = document.getElementById('previewPop').clientHeight

        contentContainer.addEventListener('scroll', function() { // 为滚动容器添加滚动事件监听器
        let scrollHeight = contentContainer?.scrollHeight // 获取滚动容器的总高度（包括溢出隐藏的内容）
        let scrollTop = contentContainer?.scrollTop // 获取滚动容器已经滚动的高度
        clientHeight = contentContainer?.clientHeight // 获取滚动容器的可见高度（视口高度）
        let threshold = 40 // 设置一个阈值，当接近底部一定距离时触发回调，这里设为20px

      // 判断当前滚动位置加上可见高度是否大于等于总高度减去阈值，如果是则认为接近底部
        if (scrollTop + clientHeight + threshold >= scrollHeight) {
          state.noViewed = false
            // 当接近底部时触发的回调事件，可以在这里执行加载更多数据的逻辑
            console.log('页面已接近底部，可以加载更多数据')
        }
        })
      })
      const {tradeNo} = router.currentRoute.value.query
      state.tradeNo = tradeNo
      if (newVal) {
        request(getExtendUri('getCacheParameters'), {
          tradeNo: state.tradeNo + 'healthTold'
        }).then(res => {
          if (res.code === 1) {
            state.showData = res.result.selectRisk.showData
            state.remarkDetail = res.result.selectRisk.remarkDetail
            let height = 0
            let weight = 0
            state.showData.forEach(item => {
              if (item.moduleType === 'healthNotification') {
                item.productElementList[0].forEach(ite => {
                  ite.disabledType = true
                  if (ite.firstValue == 1) {
                    state.isShowIntelligent = true
                  }
                  if (ite.elementOperatingMode?.mode_health?.conSpanList?.length) {
                    ite.elementOperatingMode.mode_health?.conSpanList?.forEach(ele => {
                      if (ele.elementList?.length) {
                        ele.elementList.forEach(elem => {
                          elem.disabledType = true
                        })
                      }
                    })
                  }
                  if (ite.elementCode === '185332815012008') {
                    height = ite.firstValue?.[0]?.value
                  }
                  if (ite.elementCode === '3274804072001') {
                    weight = ite.firstValue?.[0]?.value
                  }
                })
              }
            })
            if (height && weight) {
              let BIM = (Number(weight) /((Number(height) / 100) * (Number(height)/100))).toFixed(1)
        if (isNumber(Number(BIM))&& BIM > 0) {
          state.BIM = BIM
        } else {
          state.BIM = ''
        }
            }
            nextTick(() => {
          let contentContainer = document.getElementById('health') // 获取id为content-container的元素，作为滚动容器
          let clientHeight = contentContainer?.clientHeight // 获取滚动容器的可见高度（视口高度）
          let previewPopHeight = document.getElementById('previewPop')?.clientHeight

        if (previewPopHeight <  clientHeight) {
        console.log(previewPopHeight, clientHeight)
        state.noViewed = false
      }
        })
            if (state.isShowIntelligent) {
              request(getExtendUri('getCacheParameters'), {
                tradeNo: state.tradeNo + 'intelligentUnderwriting'
              }).then(res => {
                if (res.code === 1) {
                  state.intelligentQuestList = res.result?.selectRisk?.intelligentUnderwriting ?? []
                  // console.log(state.intelligentQuestList, 'state.intelligentQuestList')
                }
              })
            }
          }
          console.log(state.showData, 'state.showData-health')
        })
      }
    })
    return {
      ...toRefs(state),
      findValue,
      confirm,
      close,
      back
    }
}
})
</script>

<style scoped>
.drawer-title {
  background: #dcf0ff;
  color: #1890ff;
  text-indent: 15px;
  margin-bottom: 30px;
  padding: 5px 0;
}
.img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.health-img {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.health-img img {
  width: 150px;
  height: 100px;
  padding: 10px;
}
.risk-container {
  padding: 15px 15px 0 15px;
}
.produc-name {
  font-size: 16px;
  font-weight: bold;
}
.checked {
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 15px 0 15px 0;
  border-top: 1px solid #ebedf0;
}
.confirm-footer {
  width: 100%;
  height: 60px;
  background: #f8f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
background: #1890ff;
color: #fff;
width: 100%;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
height: 100%;
font-size: 16px;
}
.btn1 {
color: #1890ff;
border: 1px solid #1890ff;
border-radius: 13px;
width: 80px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
height: 40px;
}
.premium{
  font-size: 18px;
  color: #1890ff;
  font-weight: 500;
  margin-left: 15px;
}
.btn-row {
  font-size: 14px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.premium-text {
  font-size: 14px;
  color: #abacad;
}
.zhuxian {
  display: inline-block;
  padding: 3px 3px;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  margin-right: 5px;
  background: linear-gradient(90deg, #5292db 0%, #a4cde9 100%, rgba(247, 246, 246, 0) 100%);
}
.intelligent {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.child-ques {
  margin-top: 5px;
}
.intelligent-title {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #abacad;
}
.question {
  width: calc(100% - 30px);
  padding: 10px 15px;
  border: 1px solid #1890ff;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 0.94rem;
}
.question-title {
  line-height: 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #1890ff;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebedf0;
}
.radio-row {
  width: 100%;
  /* min-height: 45px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
:deep(.ant-radio-button-wrapper){
  border-radius: 20px !important;
  background: #ebebeb;
  min-width: 4rem;
  height: 30px;
  text-align: center;
  border: 0;
}
:deep(.ant-radio-button-wrapper){
  margin-left: 6px;
  margin-top: 5px;
}
:deep(.ant-radio-button-wrapper::before){
    display: none;
  }
.ant-radio-button-wrapper-checked{
  z-index: 0 !important;
  background: #1890ff !important;
  color: #fff !important;
}
:deep(.ant-image) {
  width: 150px !important;
  height: 100px !important;
  overflow: hidden !important;
}
</style>
