<style scoped>
.header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  background-color: #e0e0e0;
  color: #00a0e9;
  font-size: 16px;
  font-weight: bold;
  /*padding-top: 10px;*/

}

.div {
  top: 0;
  position: fixed;
  z-index: 999;
}
.vue-pdf-embed :deep(canvas){
  height: 100% !important;
  opacity: 0;
}
.vue-pdf-embed{
  height: 100% !important;
  text-align: center;

}
.vue-pdf-embed :deep(.textLayer){
  opacity: 1;
}
.vue-pdf-embed :deep(.textLayer span){
  color: #000;
  opacity: 1;
}
.vue-pdf-embed div:nth-child(1){
  height: 100%;
}
.tab-bar2 {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 49px;
  z-index: 100;
}

table td {
  text-align: left;
  padding-left: 8px;
  width: 50%;
}

table tr:nth-child(2n) {
  background-color: #eeeeee;
}
</style>

<template>
  <div v-if="yuedufenshu === 0">
    <div class="header">
      请仔细阅读一下文件,充分理解全部内容并确认。
    </div>
    <div>
      <table style="width: 100%;position: relative;border-collapse: collapse;">
        <tr style="height: 35px" v-for="(item, index) in tiankuan" :key="index" @click="querenyvyuedu(item)">
          <td>{{ item.name }}</td>
          <td style="text-align: right;padding-right: 20px;color: #00a0e9"><a>{{ item.status }}</a></td>
        </tr>
      </table>
    </div>
    <div id="tab-bar">
      <a-button class="tab-bar2" type="primary" :disabled="yesNO" @click="querenquanbuneirong">确认已阅读并知晓全部内容</a-button>
    </div>
  </div>
  <div v-if="yuedufenshu === 1">
  <div v-for="(item, index) in tiankuanList" :key="index">
    <VuePdfEmbed :source="source"
                   class="vue-pdf-embed"/>
      <a-button class="tab-bar2" type="primary" @click="querenquanbuneirong1(item)">确认已阅读{{item.slot}}</a-button>
    </div>
  </div>
</template>

<script>
import {routerJump,nowPathShow} from '../../utils/product_process.js'
import {defineComponent, reactive, ref, toRefs, onBeforeMount} from 'vue'
import {useRouter} from 'vue-router'
import VuePdfEmbed from 'vue-pdf-embed'
import store from '../../store'
// import { createLoadingTask } from 'vue3-pdfjs'

export default defineComponent({
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VuePdfEmbed
  },
  setup() {
    const state = reactive({
      name: '',
      source: 'https://static-1305332945.cos.ap-beijing.myqcloud.com/upload/common/product/2022/08/13/94df357a_e28a_424b_916c_27bef73031e8/%E4%BA%91%E6%99%BA%E8%83%BD%E4%BA%8B%E4%B8%9A%E9%83%A8-%E8%AF%B7%E5%81%87%E5%88%B6%E5%BA%A6%E8%A7%84%E8%8C%83.pdf', //预览pdf文件地址
      pageNum: 1, //当前页面
      scale: 1, // 缩放比例
      numPages: 0, // 总页数
      yesNO: true,
      yuedufenshu: 0,
      // 用于条款展示页面
      tiankuanList: [],
      tiankuan: [],
      tradeNo: ''
    })
    onBeforeMount(()=> {
      state.tradeNo = router.currentRoute.value.query.tradeNo
    } )
    nowPathShow()
    const routerArr = JSON.parse(store.getters['insured/getRouterJump'])
    console.log(routerArr)
    // const nowIndex = localStorage.getItem(`nowIndex${state.tradeNo}`)
    // console.log(nowIndex)
    const router = useRouter()
    const userDrawerRef = ref()
    console.log(userDrawerRef)

    const nextPage = function () {
      router.push({
        path: '/app/informationInput',
        tradeNo: state.tradeNo
      })
    }
    let j = 0
    const querenquanbuneirong1 = function (value) {
      j++
      if (j === 4) {
        state.yuedufenshu = 0
        state.yesNO = false
        j = 0
      }
      state.tiankuanList = []
      for (let i = 0; i < state.tiankuan.length; i++) {
        // 判断当前页面是否是列表里的，如果是就加载下一个页面，如果是第最后一个就加载第一个页面，与此同时判断列表是否全都是已读，如果是就按钮显示跳到主页面
        if (state.tiankuan[i].name === value.name) {
          // 判断他是不是也最后显示的条款
          if (i === state.tiankuan.length-1 && state.tiankuan[i].status !== '') {
            //再判断他前面是不是有没读到的
            state.tiankuan[i].status = '已读'
            console.log(state.tiankuan[0])
            state.tiankuanList.push(state.tiankuan[0])
            console.log(state.tiankuanList)
            return
          }
          // 就让他显示下一个页面
          console.log(state.tiankuan)
          if (state.tiankuan[i+1].status === '') {
            state.tiankuan[i].status = '已读'
            state.tiankuanList.push(state.tiankuan[i+2])
            // querenquanbuneirong1(state.tiankuan[i+1])
          } else {
            state.tiankuanList.push(state.tiankuan[i+1])
            // 因为已经确认所以当前页面也成了已读
            if (state.tiankuan[i].status !== '') {
              state.tiankuan[i].status = '已读'
            }
          }
          console.log(state.tiankuanList)
        }
        }
      console.log(state.yuedufenshu)
    }
    const querenquanbuneirong = function () {
      routerJump(state.tradeNo)
      // let i = 1
      // router.push({
      //   path: '/confirmation',
      //   query: {i}
      // })
    }
    const querenyvyuedu = function (item) {
      state.yuedufenshu = 1
      state.tiankuanList = []
      state.tiankuanList.push(item)
    }
  const prev = function() {
      if (state.pageNum > 1) {
        state.pageNum -= 1
      }
    }
    const next = function() {
      if (state.pageNum < state.numPages) {
        state.pageNum += 1
      }
    }
    return {
      ...toRefs(state),
      nextPage,
      querenyvyuedu,
      querenquanbuneirong,
      querenquanbuneirong1,
      prev,
      next
      // getNumPages,
    }
  }
})
</script>
