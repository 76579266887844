<style scoped>
.boxApp2{
  background: #f9f6f6;
}
.order-list-item {
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}


.orderName {
  font-weight: bold;
  font-size: 14px;
}

.order-status {
  color: #ff3333;
  font-weight: bold;
  font-size: 16px;
}

.order-details {
  margin-bottom: 8px;
}

.order-info {
  display: flex;
  margin-bottom: 4px;
}

.order-label {
  color: #222;
}

.order-value {
  text-align: right;
  flex: 1;
}

.order-actions {
  display: flex;
  justify-content: flex-end;
}

.cancel-button {
  margin-right: 16px;
}
.flexTop{
  background: white;
  /*position: fixed;*/
  /*top: 0;*/
  z-index:99;
  width: 100%;
}
.flexBox{
  margin-top: 10px
}
.tabCardClass {
  text-align: center;
  width: auto;
  margin: 0 auto;
}
.listContent {
  margin: 8px 0;
  padding: 8px 20px;
  background-color: #fff;
}
.order-bottom{
  display: flex;
  justify-content: space-between;
}
.tabSpan {
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  vertical-align: center;
  border: 1px solid #e2d8d8;
}
:deep(.van-tabs--card>.van-tabs__wrap){
  margin: 8px;
}
:deep(.van-tabs__nav--card){
  border-radius: v-bind(radiusParam);
  color: v-bind(tabSeletColor);
  border: v-bind(borderWidth) solid v-bind(tabSeletColor);
}
:deep(.van-tab--card) {
  border-radius: v-bind(radiusParam) 0px 0px v-bind(radiusParam);
  color: v-bind(tabSeletColor);
  border-right: 0px;
}
:deep(.van-tab--card.active) {
  border-radius: v-bind(radiusParam) 0px 0px v-bind(radiusParam);
}
:deep(.van-tab--card:last-child) {
  border-radius: 0px v-bind(radiusParam) v-bind(radiusParam) 0px;
}
:deep(.van-tab--card:last-child.active) {
  border-radius: 0px v-bind(radiusParam) v-bind(radiusParam) 0px;
}
:deep(.van-tab--card.van-tab--active) {
  background-color: v-bind(tabSeletColor);
  color: v-bind(tabNoSeletColor);
}
.activeBlue{
  color: #1989fa;
  border: 1px solid #1989fa;
}
:deep(.van-dropdown-menu__title--active) {
  color: #1989fa;
}
.confirm-button{
  border-color: #5b5b5b;
  color: #646566;
  height: 23px;
  width: 70px;
  font-size: 0.8rem;
  border-radius: 10px;
  text-decoration:underline;
}
.orderHeader{
  text-align: left;
  margin-bottom: 8px;
}
:deep(.van-divider){
  background: #cbcbcb;
}
</style>

<template>
  <div class="boxApp2">
    <div class="flexTop">
      <van-tabs v-model:active="LevelTop" type="card" color="#318dff" class="tabCardClass">
        <van-tab title="线上订单" ></van-tab>
        <van-tab title="线下订单" ></van-tab>
      </van-tabs>
      <van-tabs v-model:active="LevelFirst" @click-tab="onClickTabFirst">
        <van-tab v-for="(item,index) in listFirst" :key="index" :title="item.label" ></van-tab>
      </van-tabs>
      <div class="pullTabs" v-if="listNext.length">
        <van-dropdown-menu :lazy-render="false" >
          <van-dropdown-item  title="全部订单" :lazy-render="false" ref="listItemRef">
            <!-- 需要见第二层tab放入 待完成 -->
            <van-grid :column-num="3" :gutter="-18" :border="false">
              <van-grid-item v-for="(item,index) in listNext" :key="index" >
                <span :ref="tabSpanRef" :class="{activeBlue: isShow === index}" :value="item.value" :selectedValue="selectedValue" class="tabSpan" @click="secondSpanClick(item,index)">{{ item.label }}</span>
              </van-grid-item>
            </van-grid>

            <div style="padding-top: 16px;">
              <!-- 待完成，事件编写 -->
              <van-button type="default" @click="()=>listItemRef.toggle()" style="width:50%">取消</van-button>
              <van-button type="primary" @click="onConfirm(listItemRef)" style="width:50%">确认</van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item :title="timeTabTitle" ref="timeRef">
            <van-grid :column-num="3" :gutter="-18" :border="false">
              <van-grid-item v-for="(item,index) in 2" :key="index" >
                <span :ref="tabSpanRef"
                      :class="{activeBlue: isShow === index}"
                      :value="item"
                      class="tabSpan"
                      @click="secondSpanClick(item,index)">{{ item===1?'全部时间':'自定义时间' }}</span>
              </van-grid-item>
            </van-grid>

            <div style="padding-top: 16px;">
              <!-- 待完成，事件编写 -->
              <van-button type="default" @click="()=>timeRef.toggle()" style="width:50%">取消</van-button>
              <van-button type="primary" @click="onConfirm(timeRef)" style="width:50%">确认</van-button>
            </div>
          </van-dropdown-item>

        </van-dropdown-menu>
      </div>

    </div>
    <div class="flexBox">
      <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="pullOnRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onRefresh"
      >
        <div v-for="(item,index) in listShow" :key="item" :title="index" >
          <div class="listContent">
            <div class="orderHeader">
              <span class="orderName">{{ item.productInfoName }}</span>
              <span class="order-status">{{ item.status }}</span>
            </div>
            <div class="order-details">
              <div class="order-info">
                <span class="order-label">订单号：</span>
                <span class="order-value">{{ item.prtNo }}</span>
              </div>
              <div class="order-info">
                <span class="order-label">投保人：</span>
                <span class="order-value">{{ item.applicantName }}</span>
              </div>
              <div class="order-info">
                <span class="order-label">被保人：</span>
                <span class="order-value">{{ item.protectedmanName }}</span>
              </div>
              <div class="order-info">
                <span class="order-label">投保时间：</span>
                <span class="order-value">{{ item.polApplyDate }}</span>
              </div>
            </div>
            <van-divider />
            <div class="order-bottom">
              <div class="order-left">
                <span class="order-label">保费：</span>
                <span style="color: red;font-size: 18px">￥{{item.payMoney}}</span>
              </div>
              <div class="order-actions">
                <van-button
                  class="confirm-button cancel-button"
                  type="default"
                  size="small"
                >
                  取消
                </van-button>
                <van-button
                  class="confirm-button"
                  size="small"
                  plain
                  @click="nextJump(item)"
                >
                  继续
                </van-button>
              </div>
            </div>

          </div>

        </div>
      </van-list>
    </van-pull-refresh>

  </div>
  </div>
</template>

<script>
import {defineComponent, ref, reactive, toRefs} from 'vue'
import {useRouter} from 'vue-router'
import { request} from '../../utils/request'
import {getExtendUri, isOnlyPayUrl} from '../../utils/request_extend'
import {  Cell,Grid, GridItem, Divider, List, Tab, Tabs, Icon, PullRefresh, DropdownMenu, DropdownItem, Button, Space, Tabbar, TabbarItem, DatetimePicker,Popup } from 'vant'
import { message } from 'ant-design-vue'
import store from '../../store'
import { nowPathShow } from '../../utils/product_process'
// import OrderListRefresh from '../../components/extended/OrderListRefresh.vue'

export default defineComponent({
  components: {
    // OrderListRefresh,
    [Cell.name]: Cell,
    [Divider.name]: Divider,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Space.name]: Space,
    [Button.name]: Button,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
  },
  setup() {
    const state = reactive({
      isShow: undefined,
      productId: '',
      payObj: {
        payStateResult: '',
        payUrl: '',
        payKey: '',
        payJson: {},
        payState: false,
        supId: '',
        temp: ''
      },
      radiusParam: '18px',
      // 选中背景和未选中文字
      tabSeletColor: '#318dff',
      // 未选中背景和选中文字
      tabNoSeletColor: '#ffffff',
      borderWidth: '1px',
      onLineTabs: [
        {title: '未完成', name: 'uncompleted'},
        {title: '待支付', name: 'unpaid'},
        {title: '已支付', name: 'paid'},
        {title: '全部订单', name: 'all'},
      ],
      orderList: []
    })

    const listItemRef = ref(null)
    const timeRef = ref(null)
    const selectedValue = ref(0)
    const underwritingStatus = ref('')
    const payStataus = ref('')
    const contStatus = ref('')
    const contOKStatus = ref('')
    const showPopover = ref(false)
    const timerPickerShow = ref(false)
    const timeTabTitle = ref('全部时间')  // 全部时间/自定义时间
    const buttonType = ref('default')  // 按钮样式
    const activeTabBar = ref('')
    const orderOption = ref([])
    const orderValue = ref('')
    const router = useRouter()
    const listFirst = ref([])
    const listNext = ref([])
    const listShow = ref([])
    const lock = ref(false)
    const loading = ref(false)
    const finished = ref(false)
    const refreshing = ref(false)
    const LevelFirst = ref(0)
    const LevelNext = ref(0)
    const pageNum = ref(1)
    const prtStatusType = ref('')
    const prtStatus = ref('')
    const total = ref(0)

    const showPopup = event => {
      event
      timerPickerShow.value = true
    }
    // 通过 actions 属性来定义菜单选项
    const actions = [
      { text: '选项一' },
      { text: '选项二' },
      { text: '选项三' },
    ]
    const tabData1 = [
      {
        label: '待完成',
        value: '1',
        children: [
          { label: '全部订单', value: '0101000',show: false  },
          { label: '待核保', value: '010101', show: false  },
          { label: '核保中', value: '010102',show: false  },
          { label: '核保未通过', value: '010103', show: false },
        ]
      },
      {
        label: '待支付',
        value: '2',
        children: [
          { label: '全部订单', value: '0101000',show: false  },
          { label: '支付中', value: '010201', show: false },
          { label: '待支付', value: '010202', show: false  },
        ]
      },
      {
        label: '待出单',
        value: '3',
        children: []
      },
      {
        label: '已出单',
        value: '4',
        children: [
          { label: '待生效', value: '010401', show: false },
          { label: '保障中', value: '010402' ,show: false },
          { label: '已过期', value: '010403' ,show: false },

        ]
      },
      {
        label: '全部订单',
        value: '5', children:[
          { label: '全部订单', value: '0101000',show: false  },
          { label: '待支付', value: '01020211', show: false  },
          { label: '支付中', value: '0102019', show: false },
          { label: '待出单', value: '0102018', show: false },
          { label: '待生效', value: '0104017', show: false },
          { label: '保障中', value: '0104026' ,show: false },
          { label: '已过期', value: '0104035' ,show: false },
          { label: '核保中', value: '0101024',show: false  },
          { label: '核保未通过', value: '0101033', show: false },
          { label: '未核保', value: '0101012', show: false  },
          { label: '已退保', value: '0101011', show: false  },
        ]},
    ]
    const restData=()=>{
      underwritingStatus.value=''
      payStataus.value=''
      contStatus.value=''
      contOKStatus.value=''

    }
    const tabSpanRef = ref(null)  // 标签的ref
    // 第二层标签点击后的事件 todo
    const secondSpanClick = function (data, index) {
      console.log(LevelFirst.value)
      state.isShow = index
      state.temp = data.value
      // console.log('event:', event.target.attributes['value'].value)
      // prtStatus.value = event.target.attributes['value'].value
      // nextTick(() => {
      //   this.$refs.tabSpanRef.color = 'blue'
      //   selectedValue.value = 1
      // })

    }
    const onSelect = (action) => console.log(action.text)
    const onClickTabFirst = function(event){
      restData()
      finished.value = false
      pageNum.value = 1
      listNext.value = listFirst.value[event.name].children
      console.log('listNext:', listNext.value)
      prtStatusType.value = listFirst.value[event.name].value
      if(listNext.value[0]){
        prtStatus.value = listNext.value[0].value
      }
      listShow.value = []
      selectList()
    }
    // 点击事件
    const onConfirm = function(data){
      pageNum.value = 1
      finished.value = false
      listShow.value = []
      prtStatus.value = state.temp
      selectList()
      data.toggle()
    }

    const onRefresh = () => {
      pageNum.value ++
      selectList()
    }

    const pullOnRefresh = () => {
      pageNum.value = 1
      selectList()
    }
    // 调接口查询订单列表
    const selectList = function() {
      if(!finished.value&&!lock.value){
        loading.value = true
        lock.value = true
        payStataus.value = ''
        contStatus.value = ''
        underwritingStatus.value = ''
        console.log('prtStatusType:', prtStatusType.value)
        if (prtStatusType.value === '1') {
          if (prtStatus.value === '010101') {
            underwritingStatus.value = '0'
          } else if(prtStatus.value === '010102'){
            underwritingStatus.value = '7'
          } else {
            underwritingStatus.value = '2'
          }
        }else if (prtStatusType.value === '2') {
          if (prtStatus.value === '010201') {
            underwritingStatus.value = '9'
            payStataus.value = '9'
          } else{
            underwritingStatus.value = '1'
            payStataus.value = '0'
          }
        } else if (prtStatusType.value === '3') {
          payStataus.value = '1'
          contStatus.value = '0'
        } else if(prtStatusType.value==='4'){
          contStatus.value='1'
          if(prtStatus.value==='010401'){
            contOKStatus.value='1'
          }else if(prtStatus.value==='010402'){
            contOKStatus.value='2'
          }else{
            contOKStatus.value='3'
          }
        }

        request(getExtendUri('postQueryListForPage'), {
          data: {
              payStataus: payStataus.value ,
              underwritingStatus: underwritingStatus.value,
              contStatus: contStatus.value,
              needDoubleRecord: '',
              contOKStatus:contOKStatus.value
              // agentCode: '',
              // prtStatusType: prtStatusType.value,
              // prtStatus: prtStatus.value
          }, pagination: { current: pageNum.value, pageSize: 5 }
        }).then(res => {
          console.log('postQueryListForPage:', res.result)
          total.value = res.pagination.total
          lock.value = false
          if(listShow.value.length>=total.value){
            loading.value = false
            finished.value = true
            refreshing.value = false
          }else{
            finished.value = false
            res.result.forEach(item=>{
              listShow.value.push(item)
            })
            loading.value = false
            refreshing.value = false
          }
        })
      }
    }

     // 获取并保存productDetailData，需要用到productDetailData.productInfo.productId
    const saveProductInfo = (item) => {
      request(getExtendUri('getLinkData'), {
        data: {
          id: item.shareId
        }
      }).then(res => {
        if (res.code === 1 && res.result) {
          console.log('productDetailData:', res.result)
          // 将详情信息存入
          store.dispatch('insured/changeInsureJSInfo', { productDetailData: JSON.stringify(res.result) })
          store.dispatch('insured/changeInsureJSInfo', { productId: res.result.productInfo.productId })
          // console.log('risklist', res.result.productInfo.riskList)
          let productDetailInfo = res.result
          state.productId = productDetailInfo ? productDetailInfo.productInfo.productId : ''

          getNodeState(item)
        }else if (res.code === 300) {
          window.location.assign(res.result)
        } else {
          message['error'](res.message)
        }
      })

    }
    // 根据productId判断到哪个节点
    const getNodeState = (item) => {
      request(getExtendUri('getProcessByProductId'), {
          data: {
            productId: state.productId
          }
      }).then(res => {
          // 先将获取到的node节点存入缓存
        let nodeDetailData = res.result
        // 遍历获取到的node节点
        nodeDetailData.forEach(nodeItem => {
          // for (const key in showArr) {
            // 'acknowledgementOrder'
            if (nodeItem.nextCode) {
              if (nodeItem.nextCode.indexOf('acknowledgementOrder') != -1) {
                console.log('匹配成功:', nodeItem.nextCode)
                console.log('sort:', nodeItem.sort)
                nowPathShow(nodeItem.processId, nodeItem.sort, state.tradeNo)  // todo
                // testRiskListFun(item.tradeNo)
                getRiskListFun(item.tradeNo)
                router.push({
                  path: '/app/confirmation',
                  query: {
                      tradeNo: item.tradeNo,
                      shareId: item.shareId,
                      processId: nodeItem.processId
                    }
                })
              }
            }
            // }
        })
      })
    }

    // const testRiskListFun = (tradeNo) => {
    //   request(getExtendUri('cacheParameters'), {
    //       tradeNo: tradeNo,
    //       saveData: {
    //         'a': '123'
    //       },
    //       selectRisk: {
    //         'b': '123'
    //       }

    //   }).then(res => {
    //     console.log('cacheParameters:', res.result)
    //   })
    // }
    // 根据接口获取riskLisk数据存入缓存
    const getRiskListFun = (tradeNo) => {
      request(getExtendUri('getCacheParameters'), {
          tradeNo: tradeNo
      }).then(res => {
        console.log('getCacheParameters:', res.result)
        store.dispatch('insured/changeInsureJSInfo', { riskList: JSON.stringify(res.result.selectRisk) })
        store.dispatch('insured/changeInsureJSInfo', {submitDate: JSON.stringify(res.result.saveData)})
      })
    }
    // 根据订单状态跳转不同的页面
    const nextJump =  function (item) {
      // console.log('item:', item)
      // sessionStorage.setItem('shareId',item.shareId)
      if (item.orderStatus == '1') {  // todo
        saveProductInfo(item)

      } else if(item.orderStatus == '0') {
        saveTopay(item.tradeNo,item.shareId)
      } else {
        saveProductInfo(item)
    }
    }
    // 跳转支付页面
     const saveTopay = (tradeNo,shareId) => {
      request(getExtendUri('confirmCharge'), {
        baseInfo: {
          tradeNo: tradeNo,
          shareId: shareId
          }
        }).then(res => {
          if (res.code === 1) {
            payAction(res.result)
          }
        })
    }

    const payAction = (obj) => {
      console.log('supplierCode', obj.supplierCode)
      console.log('supplierName', obj.supplierName)
      state.payObj.supId = obj.supplierCode
      // 平安
      // if (obj.supplierCode === '1004') {
      if (isOnlyPayUrl(obj.supplierCode)) {
        state.payObj.payUrl = obj.payUrl
        state.payObj.payJson = ''
        state.payObj.payKey = ''
      } else {
        // 安盛
        const xmlData = state.payObj.payStateResult = obj.value || ''
        state.payObj.payJson = xmlData
        state.payObj.payUrl = obj.url
        state.payObj.payKey = obj.key
      }
    }

    const selectTabs = function () {
      listFirst.value = tabData1
        listNext.value = listFirst.value[0].children
        prtStatusType.value = listFirst.value[0].value  // 第一层标签值
        prtStatus.value = listNext.value[0].value  // 第二层标签值
        selectList()
    //   request(getExtendUri('dictionary_query_byType'), { data: { types: ['insuranceSlipStatus'] } }).then(res => {
    //     listFirst.value = res.result.insuranceSlipStatus
    //     listNext.value = listFirst.value[0].children
    //     prtStatusType.value = listFirst.value[0].value  // 第一层标签值
    //     prtStatus.value = listNext.value[0].value  // 第二层标签值
    //     selectList()
    //     console.log('listFirst:', listFirst.value)
    // })
    }
    selectList()
    selectTabs()
    return {
      listItemRef,
      timeRef,
      tabSpanRef,
      selectedValue,
      payStataus,
      contStatus,
      contOKStatus,
      tabData1,
      ...toRefs(state),
      actions,
      onSelect,
      showPopover,
      showPopup,
      timerPickerShow,
      timeTabTitle,
      buttonType,
      activeTabBar,
      orderOption,
      orderValue,
      listShow,
      loading,
      finished,
      onRefresh,
      pullOnRefresh,
      refreshing,
      LevelNext,
      LevelFirst,
      listFirst,
      listNext,
      onClickTabFirst,
      secondSpanClick,
      onConfirm,
      nextJump,
      restData
    }
  }
})
</script>
