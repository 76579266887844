<template>
    <!-- height="500px" -->
<transition appear>
  <div class="header" v-show="show">
    <div class="mask" ref="mask">
      <div class="mask-close" @click="onClose">X</div>
<!--      <div class="mask-header-title">-->
<!--        <p class="mask-head">平台提供方</p>-->
<!--&lt;!&ndash;        <p class="mask-p">北京恒荣汇彬保险代理有限责任公司</p>&ndash;&gt;-->
<!--        <p class="mask-p">瑞泰人寿保险有限公司</p>-->
<!--        <p class="mask-head">承保保险公司</p>-->
<!--        <p class="mask-p">{{supplierName}}</p>-->
<!--      &lt;!&ndash; <hr> &ndash;&gt;-->
<!--      </div>-->
      <div class="mask-header-title" style="padding-top: 15px">
        <div class="mask_title">瑞泰人寿</div>
        <div class="mask_text_title">尊敬的客户您好，您即将进入投保流程：</div>
        <p class="mask-content">(1)    为保障您的合法权益，请您在投保过程中仔细阅读保险条款，免责说明，如实填写健康告知，并根据您及家人的家庭保险保障需求自主选择及购买本公司保险产品。</p>
        <p class="mask-content">(2)    根据国家金融监督管理总局要求，您在投保页面中填写的内容和操作行为将被记录以及存储管理，敬请知悉了解。</p>
<!--        <p class="mask-content">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您将进入投保流程，根据监管要求，为了保障您的权益，在投保流程中，您在页面的操作将被记录，请您知悉。</p>-->
<!--        <p class="mask-content">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请您在投保过程中，仔细阅读保险条款、投保须知、健康告知等内容</p>-->
<!--        <p class="mask-content">进入投保之前，请先仔细阅读 <span class="mask-txt" @click="handelOpen">《客户告知书》</span></p>-->
      </div>
      <!-- <hr style="margin: 1rem 0;color: #ccc"> -->
<!--      <van-checkbox v-model="checked" style="padding-top:10px">-->
<!--        <span>已阅读并知晓同意上述内容</span>-->
<!--        &lt;!&ndash; <span class="mask-txt" @click="handelOpen">《客户告知书》</span> &ndash;&gt;-->
<!--      </van-checkbox>-->
      <div class="mask-but" :class="{active:checked}" @click="handelOk">已阅读，确认进入投保</div>
    </div>
  </div>
</transition>
  <customerNotification v-model:checked="checked" ref="customerRef"/>
</template>

<script>
import {defineComponent, reactive, ref, toRefs} from 'vue'
import { Popup,Checkbox, CheckboxGroup } from 'vant'
import CustomerNotification from './customerNotification.vue'
import {routerJump} from '../../../utils/product_process'
import store from '../../../store'
import router from '@/router'
import {request} from '@/utils/request'
import {getExtendUri} from '@/utils/request_extend'
import {message} from 'ant-design-vue'
export default defineComponent({
  components:{
    [Popup.name] : Popup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    CustomerNotification
  },
  props: {
    supplierName: {
      type: String
    }
  },
  setup(props, {emit}){
    const mask = ref()
    const state = reactive({
      routerFlag: false,
      show: false,
      checked:true,
      type: '',
      tradeNo: '',
      height: 0
    })
    const showPage = (uuid) =>{
      state.show = true
      state.tradeNo = uuid
    }
    const customerRef = ref()
    const handelOpen = () =>{
      state.height = mask.value.clientHeight
      customerRef.value.showPage(state.height)
    }
    const handelOk =() =>{
      let health1 = store.getters['insured/getHealthRouterJump']
      // const productInfoSkip = JSON.parse(store.getters['insured/getProductDetailData']).productInfoSkip || {}
      // const typeCode = router.currentRoute.value.query?.typeCode
      let str = health1 === '{}' ? '' : 'health'
      if(!state.checked) return
        request(getExtendUri('getH5LinkData'), {
          data: {
            id: router.currentRoute.value.query['shareId'],
            pageType: '2'
          }
        }).then(res =>{
          if(res.code === 1 && res.result.jumpUrl){
            window.location = res.result.jumpUrl
          }else if(res.code === 1 && !res.result.jumpUrl){
            state.show = false
            // state.checked =false
            state.type = '成功'
            state.routerFlag = true
            emit('changeEouterFlag', state.routerFlag)
            routerJump(state.tradeNo,str)
          }else{
            message['error'](res.message)

          }
        })

    }
    const onClose = () =>{
      state.show = false
      // state.checked =false
      state.type = ''
    }
    return {
      ...toRefs(state),
      mask,
      onClose,
      customerRef,
      handelOk,
      handelOpen,
      showPage,
    }

  },
})

</script>

<style scoped>
  .header{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    z-index: 100;
    transition: 1s;
  }
  .mask{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    font-size: 1rem;
    padding: 1rem;
    text-align: left;
    box-sizing: border-box;
  }
  .mask-head{
    color: black;
    font-weight: bold;
    margin-bottom: .1rem;
  }
  .mask-p{
    margin-bottom: 0.5rem;
  }
  .mask_title{
    font-size: 1.3rem;
    color: #333333;
    text-align: center;
    margin-bottom: 18px;
    font-weight: bold;
  }
  .mask_text_title{
    position: relative;
    padding-left: 10px; /* 左侧空间供加粗小竖线显示 */
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .mask_text_title::before{
    content: '';
    position: absolute;
    left: 0;
    top: 45%; /* 竖线垂直居中 */
    transform: translateY(-50%);
    height: 55%; /* 小竖线的高度 */
    width: 5px; /* 小竖线的宽度 */
    background: #95beac; /* 小竖线的颜色 */
    font-weight: bold; /* 小竖线的样式，加粗 */
  }
  .mask-content{
    padding: .5rem .5rem 0 .5rem;
    color: #8c8686;
    margin-bottom: 1.3rem;
  }
  .mask-txt{
    cursor: pointer;
    color: #1677FF;
  }
  .mask-but{
    margin-top: 0.8rem;
    background: #A2C9FF;
    border-radius: 4px;
    line-height: 2.5rem;
    text-align: center;
    color: white;
    z-index: 99999;
  }
  .active{
    background: #1677FF;
  }
  .mask-close{
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: 999;
    font-size: 20px;
    top: 0.1rem;
    right: 0.1rem;
  }
  .mask-header-title{
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  /* 进入：始位置 */
.v-enter {
  transform: translateY(-100%);
}
/* 进入：末位置 */
.v-enter-to {
    transform: translateY(0);
}
/* 进入动画 */
.header-enter-active {
    animation: move 1s;
}

/* 离开：始位置 */
.v-leave {
    transform: translateY(0);
}
/* 离开：末位置 */
.v-leave-to {
    transform: translateY(100%);
}
</style>
