<template>
  <div class="order-list">
<!--    <van-pull-refresh v-model:show="isRefreshing" @refresh="onRefresh">-->
<!--      <van-list v-model:finished="isFinished" :finished-text="finishedText" :offset="offset" :loading="isLoading" :finished="isFinished" @load="onLoad">-->
<!--        <van-cell-group>-->
<!--          <template v-for="(order, index) in orders">-->
<!--            <van-cell :title="order.name" :label="order.status"></van-cell>-->
<!--            <van-cell :value="order.orderNo"></van-cell>-->
<!--            <van-cell :title="order.applicant.name" :label="order.applicant.idCard"></van-cell>-->
<!--            <van-cell :title="order.insured.name" :label="order.insured.idCard"></van-cell>-->
<!--            <van-cell :value="order.insuredTime"></van-cell>-->
<!--            <van-cell>-->
<!--              <template #title>-->
<!--                <div class="order-action">-->
<!--                  <van-button size="small" type="danger" v-if="order.status === '待支付'" @click="cancelOrder(index)">取消</van-button>-->
<!--                  <van-button size="small" type="primary" v-if="order.status === '待出单'" @click="confirmOrder(index)">确认</van-button>-->
<!--                </div>-->
<!--              </template>-->
<!--              <template #value>-->
<!--                <div class="order-fee">{{ order.fee }}</div>-->
<!--              </template>-->
<!--            </van-cell>-->
<!--          </template>-->
<!--        </van-cell-group>-->
<!--      </van-list>-->
<!--    </van-pull-refresh>-->
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { PullRefresh, List, Cell, CellGroup, Button } from 'vant'

export default {
  components: {
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
  },
  name: 'orderListComponent',
  setup(){
    const state = reactive({
      orders: [], // 订单列表
      isLoading: false, // 是否正在加载数据
      isFinished: false, // 是否已经加载完所有数据
      finishedText: '', // 加载完所有数据后的文本
      offset: 0, // 列表偏移量
      isRefreshing: false, // 是否正在下拉刷新
    })
    // // 模拟获取订单数据
    // const fetchOrders = (offset) => {
    //   return new Promise((resolve, reject) => {
    //     // 模拟获取数据
    //     setTimeout(() => {
    //       const data = [
    //         {
    //           name: '订单1',
    //           status: '待支付',
    //           orderNo: '123456789',
    //           applicant: {
    //             name: '投保人1',
    //             idCard: '111111111111111111',
    //           },
    //           insured: {
    //             name: '被保人1',
    //             idCard: '222222222222222222',
    //           },
    //           insuredTime: '2022-01-01',
    //         }
    //       ]
    //       resolve(data)
    //     })
    //   })
    // }
    const cancelOrder = (item) => {
      console.log(item)
    }
    const confirmOrder = (item) => {
      console.log(item)
    }
    return {
      ...toRefs(state),
      cancelOrder,
      confirmOrder
    }
  }
}
</script>

<style scoped>

</style>
