<template>
  <IOSbackBar :title="titleName" :backMethod="backMethod"></IOSbackBar>
  <div class="page">
    <div class="example" v-if="loadingShow">
      <a-spin size="large"/>
    </div>
    <div v-if="offlineUnderwritingResult === '1'">
      <img class="status-img" :src="SuccessIcon" />
      <div class="offlineUnderwritingResult">您提交的保单已经进入保司人工核保，请关注后续人工核保进展，完成保单投保</div>
    </div>
  <div v-if="isShow && offlineUnderwritingResult !== '1'">
    <div style="padding-top:120px">
      <img :src="status === true ? SuccessIcon: ErrorIcon" />
      <div class="status-style" :style="{color: status === false ? 'red': '#4ca539'}">{{piloyState}}</div>
    </div>
    <div class="status-context-style"></div>
  </div>
  <div v-if="!isShow">
    <div style="padding-top:120px" v-if="underwritingResult !== '99'">
      <div v-if="underwritingResult === '1'">
        <img class="status-img" :src="SuccessIcon" />
        <div class="status-style">支付成功</div>
        <div class="tips">请您点击下方“去回执”按钮进入官方微信公众号中->其他服务->回执签收进行保单回执及回访（一年期以上保险产品），完成投保全流程。</div>
      </div>
      <div v-if="underwritingResult === '0'">
        <img class="status-img" :src="ErrorIcon" />
      </div>

      <div v-if="!underwritingResult && underwritingResult !== '0'">
        <div class="w_class">该保单正在申请承保中,请以短信通知或稍候刷新页面结果为准......</div>
        <a-button type="primary" style="background-color: #0079FE" @click="getContStatus">刷新</a-button>
      </div>

    </div>
  </div>
  <div class="footer">
    <div v-if="underwritingResult === '1'" class="success">
      <div class="btn1" @click="goDetailPage">确定</div>
      <div class="btn2" @click="huifang">去回执</div>
    </div>
    <div v-if="isShow" class="error">
      <div class="btn3" @click="goDetailPage">返回首页</div>
    </div>
  </div>
  </div>
</template>

<script>
import {routerJump,nowPathShow} from '../../utils/product_process'
import {defineComponent, onMounted, reactive, toRefs} from 'vue'
import {request} from '../../utils/request'
import {getExtendUri} from '../../utils/request_extend'
import {useRouter} from 'vue-router'
import SuccessIcon from '../../assets/img/success1.png'
import ErrorIcon from '../../assets/img/error.png'
// import readClick from './elSign/componts/readClickBtn'
import IOSbackBar from '@/components/extended/IOSbackBar.vue'


export default defineComponent({
  components: {
    IOSbackBar,
    // readClick
  },
  setup() {
    const router = useRouter()
    nowPathShow()
    // const routerData = JSON.parse(localStorage.getItem('routerJump'))
    // console.log('showDataJson', routerData)
    // const showDataJson = JSON.parse(routerArr[nowIndex].pageJson)
    // console.log('showDataJson', showDataJson)
    const state = reactive({
      loadingShow: false,
      mTradeNo: '',
      piloy: '',
      status: false,
      piloyState: '支付检查中',
      shareId: undefined,
      underwritingResult: '99',
      processType: '',
      tradeNo: '',
      isShow: true,
      rdSeq: '',
      titleName: router.currentRoute.value?.query?.offlineUnderwritingResult === '1' ?'人核' : '支付',
      offlineUnderwritingResult: router.currentRoute.value?.query?.offlineUnderwritingResult
    })
    const nextPage = function() {
      routerJump(router.currentRoute.value.query.tradeNo)
    }
    const queryInsurancepolicyByTradeNo = (v) => {
      request(getExtendUri('selectByInsuredId'), {data: {tradeNo: v}}).then(res => {
        if (res.code === 1) {
          state.loadingShow = false
          state.status = true
          if( res.result.contInfo.contract.paySataus === '1'){
            state.piloyState = '支付成功'
          }else if(res.result.contInfo.contract.paySataus === '0'){
            state.piloyState = '待支付'
          }else if(res.result.contInfo.contract.paySataus === '9'){
            state.piloyState = '支付中'
          }else if(res.result.contInfo.contract.paySataus === '2'){
            state.status = false
            state.piloyState = '支付失败'
          }

        } else {
          state.piloyState = res.message
        }
      })
    }
    const queryShareIdByTradeNo = (v) => {
      request(getExtendUri('getShareIdByTradeNo'), {data: {tradeNo: v}}).then(res => {
        state.shareId = res.result
      })
    }
    const huifang = () => {
      window.location.href= 'https://testweixin.oldmutual-chnenergy.com/online/page/policyService.jsp'
    }
    onMounted(() => {
      document.title = router.currentRoute.value?.query?.offlineUnderwritingResult === '1' ?'人核' : '支付'
      let tradeNo = router.currentRoute.value.query['tradeNo']
      state.shareId = router.currentRoute.value.query['shareId']
      state.underwritingResult = router.currentRoute.value.query?.underwritingResult
      state.processType = router.currentRoute.value.query?.processType
      state.tradeNo = router.currentRoute.value.query['tradeNo']
      // 国富的
      state.rdSeq = router.currentRoute.value.query['RdSeq']
      if(state.processType && state.processType === 'generatePolicy'){
        state.isShow = false
      }else if(state.rdSeq){
        state.isShow = true
        state.loadingShow = true

        getTradeNo()
      }else if (tradeNo && !state.processType) {
        state.isShow = true
        if (!state.shareId) {
          queryShareIdByTradeNo(tradeNo)
        }
        // 获取保单号或流水号
        queryInsurancepolicyByTradeNo(tradeNo)
      } else {
        state.status = false
        state.piloyState = '支付异常'
      }
    })
    // 获取tradeNo
    const getTradeNo = () =>{
      request(getExtendUri('getPaySel'), {payNum: state.rdSeq}).then(res => {
        if(res.code === 1){
          state.tradeNo = res.result.tradeNo
          state.shareId = res.result.shareId
          queryInsurancepolicyByTradeNo(state.tradeNo)
        }
      })

    }
    // 获取承保状态
    const getContStatus = () =>{
      request(getExtendUri('getSelectContStatus'), {data: {tradeNo: state.tradeNo}}).then(res => {
        if(res.code === 1){
          res.result.contStatus === '1' ? state.underwritingResult = '1' : state.underwritingResult = ''
        }
      })
    }
    const backMethod = () => {
      goDetailPage()
    }
    const goDetailPage = () => {
      window.location.href= process.env.VUE_APP_APPSITE_URL
      // if (state.status) {
      //   router.push({
      //     path: '/app/detail',
      //     query: {
      //       shareId: state.shareId
      //     }
      //   })
      // }
    }
    return {
      backMethod,
      ...toRefs(state),
      huifang,
      SuccessIcon,
      ErrorIcon,
      getContStatus,
      nextPage,
      goDetailPage
    }
  }
})
</script>

<style scoped>
.page {
  height: calc(100vh - 50px);
  background: #fff;
}
.status-img {
  width: 50%;
}
.tips {
  width: calc(100% - 30px);
  margin: 0 auto;
  background: #F8F8F8;
  color: #0079FE;
  padding: 5px 10px;
  border-radius: 8px;
  text-align: justify;
}
.status-style {
  font-size: 20px;
  margin-bottom: 18px;
  font-weight: bold;
  color: #0079FE;
}
img {
  margin-bottom: 10px;
}
.status-context-style {
  letter-spacing: 1.8px;
  padding-bottom: 5px;
  margin-bottom: 13px;
}
button {
  width: 255px;
  height: 40px;
  border-radius: 50px;
  background-color: #8b8b89;
  border:1px solid #8b8b89;
}
.w_class{
  margin-bottom: 8px;
}
.example {
  text-align: center;
  background: rgba(165, 165, 165, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.example div {
  position: fixed;
  left: 50%;
  bottom: 50%;
  margin-left: -16px;
}
.footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding:  0 15px;
}
.success {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;

}
.btn1 {
  width: 35%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #0079FE;
  border: 1px solid #0079FE;
  /* border-radius: 10px; */
}
.btn2 {
  width: 50%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  border: 1px solid #0079FE;
  background: #0079FE;
  /* border-radius: 10px; */
}
.error {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;

}
.error {
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #fff;
  border: 1px solid #0079FE;
  background: #0079FE;
  border-radius: 10px;
  text-align: center;
}
.btn3{
  width: 100%;
}
.offlineUnderwritingResult{
  margin: 20px 32px;

}
</style>
