<template>
  <div class="order-list-item">
    <div class="order-header">
      <span class="order-name">{{ order.name }}</span>
      <span class="order-status">{{ order.status }}</span>
    </div>
    <div class="order-details">
      <div class="order-info">
        <span class="order-label">订单号：</span>
        <span class="order-value">{{ order.orderNo }}</span>
      </div>
      <div class="order-info">
        <span class="order-label">投保人：</span>
        <span class="order-value">{{ order.applicant.name }}</span>
      </div>
      <div class="order-info">
        <span class="order-label">被保人：</span>
        <span class="order-value">{{ order.insured.name }}</span>
      </div>
      <div class="order-info">
        <span class="order-label">投保时间：</span>
        <span class="order-value">{{ order.insuredTime }}</span>
      </div>
    </div>
    <div class="order-actions">
      <van-button
        v-if="order.status === '待支付'"
        class="cancel-button"
        type="default"
        size="small"
        @click="cancelOrder"
      >
        取消订单
      </van-button>
      <van-button
        class="confirm-button"
        type="primary"
        size="small"
        @click="confirmOrder"
      >
        确认订单
      </van-button>
    </div>
  </div>
</template>

<script>
// import {  } from 'vue'
import {  Button } from 'vant'

export default {
  components: {
    [Button.name]: Button,
  },
  name: 'OrderListItem',
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  setup(props, {emit}){
    const cancelOrder = () => {
      emit('cancel', this.order)
    }
    const confirmOrder = () => {
      emit('confirm', this.order)
    }
    return {
      cancelOrder,
      confirmOrder
    }
  }
}
</script>

<style scoped>
.order-list-item {
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.order-name {
  font-weight: bold;
  font-size: 18px;
}

.order-status {
  color: #ff3333;
  font-weight: bold;
  font-size: 16px;
}

.order-details {
  margin-bottom: 8px;
}

.order-info {
  display: flex;
  margin-bottom: 4px;
}

.order-label {
  color: #999999;
}

.order-value {
  flex: 1;
}

.order-actions {
  display: flex;
  justify-content: flex-end;
}

.cancel-button {
  margin-right: 16px;
}
</style>
